<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-text-field
              v-model="formData.celular"
              label="Teléfono"
              placeholder="Requerido"
              color="#00a7e4"
              outlined
              v-mask="'##########'"
              :rules="[requiredRule, celularRule]"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="4" xl="4">
            <common-email-autocomplete
              :emailEdit="formData.correoElectronico"
              @emailUpdate="(e) => (formData.correoElectronico = e)"
            >
            </common-email-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  components: {
    CommondForm,
    CommonEmailAutocomplete,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
      ],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      formData: {
        celular: null,
        correoElectronico: null,
      },
      run: false,
      celularRule: (value) => {
        // Verifica que el número tenga exactamente 10 dígitos
        const formato = /^\d{10}$/;
        if (!formato.test(value)) {
          return "El teléfono debe tener 10 dígitos";
        }
        // Evita secuencias numéricas ascendentes o descendentes como "1234567890" o "9876543210"
        const secuenciaAscendente = /^(\d)(\d)\d{8}$/;
        if (secuenciaAscendente.test(value)) {
          const [, a, b] = secuenciaAscendente.exec(value);
          if (+b === +a + 1) {
            return "Teléfono no válido (secuencia numérica)";
          }
        }
        // Verifica que no tenga todos los mismos dígitos, como "1111111111" o "0000000000"
        const soloRepetidos = /^(\d)\1{9}$/;
        if (soloRepetidos.test(value)) {
          return "Teléfono no válido (número repetitivo)";
        }

        // Si ninguna de las reglas anteriores se cumple, el número es válido
        return true;
      }
    };
  },
  props: {
    datosSolicitud: Object,
  },
  computed: {
    canEdit() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "DIRECTOR",
        "FINANZAS",
        "MANAGERCC",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "OPERADORINBOUND",
        "OPERACIONESINBOUND",
        "MESACONTROLINBOUND",
        "BACKOFFICEEMAILS"

      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
  },
  methods: {
    deshabilitarInputs() {
      if (
        (this.datosSolicitud.status == "En tramite" &&
          this.datosSolicitud.procesoCotizacion == "Emision") ||
        this.datosSolicitud.status == "Cerrada" ||
        this.datosSolicitud.status == "Cancelada"
      ) {
        return true;
      } else return false;
    },
    fillData() {
      this.formData.celular = this.datosSolicitud.celular ?? null;
      this.formData.correoElectronico =
        this.datosSolicitud.correoElectronico ?? null;
    },
  },

  mounted() {
    this.deshabilitarInputs();
    this.fillData();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
