<template>
  <v-row>
    <v-col cols="12">
      <CommonExpansionPanel>
        <template v-slot:title-panel> Búsqueda detallada </template>
        <v-expansion-panel-content>
          <v-row no-gutters style="gap: 41px">
            <v-col >
              <v-text-field
                label="Busqueda por nombre(s)"
                v-model="alianzaName"
                @blur="onBlur"
                hint="Buscar un Agente por nombre o correo"
                outlined
                dense
                primary
                color="#00a7e4"
                @keyup.enter="getAsesores"
              ></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn class="common-botton-alianza" dark @click="getAlianzas">
                <span>Buscar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </CommonExpansionPanel>
    </v-col>
  </v-row>
</template>

<script>
import CommonExpansionPanel from "../../../../../../components/commonComponents/CommonExpansionPanel.vue";
export default {
  components: { CommonExpansionPanel },
  name: "CardDashboard",
  props: {
    title: String,
  },
  data() {
    return {
      alianzaName: "",
    };
  },
  methods: {
    getAsesores() {
      this.$emit("update:filter", this.alianzaName);
    },
    onBlur() {
      this.$emit("update:filter", this.alianzaName);
    },
  },
};
</script>

<style scoped></style>
