<template>
  <div class="pt-5">
    <v-data-table
      :items="solicitudes"
      :headers="headers"
      :loading="loading"
      no-data-text="Sin solicitudes para mostrar"
      no-results-text="Sin resultados para mostrar"
      loading-text="Cargando..."
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
      :server-items-length="total"
      @update:page="pagina = $event"
      @update:items-per-page="elementosPagina = $event"
    >
      <template v-slot:top>
        <v-row style="padding: 15px;">
          <v-col cols="12" md="8">
            <h2>Resultados de la búsqueda</h2>
          </v-col>
        </v-row>
      </template>
      <template #item.fechaCreacion="{ item }">
        <span> 
          {{ getFormatDate(item.fechaCreacion) }}
        </span>
      </template>
      <template #item.operadorId="{ item }">
        <span> 
          {{ getNombre(item.operadorId) }}
        </span>
      </template>
      <template #item.cumpleSla="{ item }">
        <span>{{ item.cumpleSla == 1 ? "Sí cumple" : "No cumple" }}</span>
      </template>
      <template #item.ver="{ item }">
        <v-btn icon x-small @click="verSolicitud(item.id)">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import { getUsersList } from "@/services/user/user.service";
import moment from "moment";

export default {
  components: {
  },
  props: {
    solicitudes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    restartPaginacion:{
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      headers: [
        {
          text: "Número",
          value: "id",
        },
        {
          text: "Estado",
          value: "status",
        },
        {
          text: "Nombre de la Solicitud",
          value: "titulo",
        },
        {
          text: "Operador BO",
          value: "operadorId",
        },
        {
          text: "Fecha de Creación",
          value: "fechaCreacion",
        },
        {
          text: "SLA",
          value: "cumpleSla",
        },
        {
          text: "Ver",
          value: "ver",
        },
      ],
      solicitudSeeleccionada: "",
      dialog: false,
      usuarios:[],
      elementosPagina: 10,
      pagina: 1,
    };
  },
  watch: {
    pagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: v,
          elementosPagina:
            this.elementosPagina > 0
              ? this.elementosPagina
              : this.total,
        });
    },

    elementosPagina(v) {
      if (v)
        this.$emit("paginacionModificada", {
          pagina: this.pagina,
          elementosPagina: v > 0 ? v : this.total,
        });
    },
    restartPaginacion(v){
      if(v){
        this.pagina = 1
        this.elementosPagina = 10
        this.$emit('restartFlag')
      }
    }
  },

  methods: {
    verSolicitud(id) {
      this.$router.push(`/detalles-solicitud/${id}`);
    },
    seleccionarItem(id) {
      this.solicitudSeeleccionada = id;
      this.dialog = true;
    },
    getNombre(id) {
      let usuario = this.usuarios.find((e) => e.id == id)
      return usuario ? usuario.nombreCompleto : 'No disponible'

    },
    async getUsersList(){
      this.usuarios = await getUsersList()
    },
    getFormatDate(date) {
      return moment(date)
        .subtract(6, "hours")
        .format("DD/MM/YYYY hh:mm:ss");
    },
  },
  mounted() {
    this.getUsersList()
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
