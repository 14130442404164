<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn v-if="rol == 'ADMINAGENT'" rounded dark class="common-botton" @click="$emit('changeComponent')">
          <span style="padding-right: 11%;">
            Editar
          </span>
          <v-icon rigth>
            mdi-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Codigo postal
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.codigoPostal
              ? formData.codigoPostal
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Calle
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.calle ? formData.calle : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Exterior
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.numExterior
              ? formData.numExterior
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          No. Interior
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.numExterior
              ? formData.numExterior
              : "Sin llenar"
          }}
        </span>
      </v-col>

      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Colonia
        </span>
        <br />
        <span class="text-data-details">
          {{ formData.colonia ? formData.colonia : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Ciudad
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.estadoRepublica
              ? formData.estadoRepublica
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Municipio
        </span>
        <br />
        <span class="text-data-details">
          {{
            formData.municipio
              ? formData.municipio
              : "Sin llenar"
          }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data ">
          Pais
        </span>
        <br />
        <span class="text-data-details">
          México
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      formData: {
        codigoPostal: null,
        calle: null,
      }
    };
  },
  props: {
    datosSolicitud: Object,
  },
  methods: {
    fillData() {
      this.formData.codigoPostal = this.datosSolicitud.codigoPostal ?? null
      this.formData.estadoRepublica = this.datosSolicitud.estadoRepublica ?? null

      var jsonData = JSON.parse(this.datosSolicitud.body)
      this.formData.calle = jsonData.calle ? jsonData.calle : null
      this.formData.numExterior = jsonData.numExterior ?? null
      this.formData.numInterior = jsonData.numInterior ?? null
      this.formData.colonia = jsonData.colonia ?? null
      this.formData.municipio = jsonData.municipio ?? null

    },
  },
  mounted() {
    this.fillData()
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}

.v-expansion-panels {
  z-index: auto !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
