<template>
  <v-row>
    <v-col cols="12">
      <common-card class="py-4">
        <v-row>
          <v-col cols="3" md="1" class="text-center">
            <img :src="dashboardJson.iconCar" alt="" height="50" />
          </v-col>
          <v-col cols="9">
            <h2>Cotiza Seguro de auto</h2>
            <span>
              Encuentra tu seguro ideal
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <formulario-marcas-vehiculo
              :carBrand="carBrand"
              :token="token"
              :insurers="insurers"
              @setInsurers="setInsurers"
              @agenteIdUpdated="agenteId = $event"
              @alianzaIdUpdated="alianzaId = $event"
              @showLoading="showLoading"
              @hideLoading="hideLoading"
            />
          </v-col>
        </v-row>
      </common-card>
    </v-col>
    <v-col cols="12" v-show="insurers.length > 0">
      <lista-aseguradoras
        :insurers="insurers"
        :insurerSelected="insurerSelected"
        @setInsurerSelected="setInsurerSelected"
      />
    </v-col>
    <v-col cols="12">
      <formulario-datos-personales :personalData="personalData" />
    </v-col>

    <v-col cols="12" class="d-flex justify-center justify-md-end">
      <v-col cols="6" md="2">
        <v-btn
          color="#00A7E4"
          @click="goEmision"
          block
          dark
          :disabled="!allFieldsValidated()"
          class="rounded-xl"
        >
          Cotizar
        </v-btn>
      </v-col>
    </v-col>

    <commond-loading
      :show="loading"
      :loadingImage="dataImage.iconCar"
      loadingText="Cargando..."
    />
  </v-row>
</template>
<script>
import CommonCard from "../../../../components/commonComponents/CommonCard.vue";
import FormularioMarcasVehiculo from "./FormularioMarcasVehiculo.vue";
import { mainAxios } from "@/mainAxios.js";
import FormularioDatosPersonales from "./FormularioDatosPersonales.vue";
import ListaAseguradoras from "./ListaAseguradoras.vue";
import CommondLoading from "../../../../components/commonComponents/CommondLoading.vue";
import dataMascota from "../../../../helpers/data/data-loading-gif.json";
import imageAseguradoras from "../../../../helpers/data/data-image-aseguradoras.json";
import dashboardJson from "../../../../helpers/data/data-dashboard-template.json";
export default {
  components: {
    CommonCard,
    FormularioMarcasVehiculo,
    FormularioDatosPersonales,
    ListaAseguradoras,
    CommondLoading,
  },
  data() {
    return {
      token: "",
      trackingId: "",
      carBrand: {
        brand: "",
        year: 0,
        model: {
          assembler: "",
          description: "",
          model: "",
          vehicle_id: "",
        },
      },
      agenteId: null,
      personalData: {
        cp: "",
        genero: "",
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaDeNacimiento: "",
        telefono: "",
        correo: "",
      },
      insurers: [],
      insurerSelected: [],
      loading: false,
      dataImage: dataMascota,
      images: imageAseguradoras,
      dashboardJson: dashboardJson,
      alianzaId: null
    };
  },
  methods: {
    showLoading() {
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },
    getToken() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      return mainAxios.get("/v1/cotizacion-ws/auto/init", config).then(
        ({
          data: {
            data: { accessToken, trackingId },
          },
        }) => {
          this.token = accessToken;
          this.trackingId = trackingId;
          this.loading = false;
        }
      );
    },
    setInsurers(array) {
      this.insurers = array;
    },
    goEmision() {
      const carPrice = {
        carBrand: this.carBrand,
        personalData: this.personalData,
        insurerSelected: this.insurerSelected,
        token: this.token,
        trackingId: this.trackingId,
        insurers: this.insurers,
        tipoPago: this.tipoPago,
        plan: this.plan,
      };
      sessionStorage.setItem("agenteIdCotizacion", this.agenteId);
      sessionStorage.setItem('alianzaIdCotizacion', this.alianzaId)
      this.$router.push({
        name: "emitir-vehiculo",
        params: { carPrice: carPrice },
      });
      const contractorData = {
        emission: {
          contractorData: {
            phone: this._data.personalData.telefono,
            email: this._data.personalData.correo,
          }
        }
      }
      sessionStorage.setItem("contractorData", JSON.stringify(contractorData))
      window.dataLayer.push({
          event: 'ITFY_auto_cotizar',
          items: this.insurerSelected.map(insurer => ({
            aseguradora: insurer,
          })),
          marca: this.carBrand.brand,
          modelo: this.carBrand.model.description,
          anio: this.carBrand.year,
          tipo_vehiculo: this.carBrand.model.type,
          userID: localStorage.getItem('agenteMail'),
          fecha_nacimiento: this.personalData.fechaDeNacimiento.split("-").reverse().join("/"),
          sexo: this.personalData.genero == 'M' ? "Masculino" : "Femenino",
          cp: this.personalData.cp,
          correo: this._data.personalData.correo,
          telefono: this._data.personalData.telefono,
      })
    },

    setInsurerSelected(insurerSelected) {
      this.insurerSelected = insurerSelected;
    },
    allFieldsValidated() {
      return [
        this.carBrand.brand,
        this.carBrand.year,
        this.carBrand.model.vehicle_id,
        this.carBrand.model.description,
        this.personalData.nombre,
        this.personalData.apellidoPaterno,
        this.personalData.cp,
        this.personalData.correo,
        this.personalData.fechaDeNacimiento,
        this.personalData.telefono,
        this.personalData.genero,
        this.insurerSelected,
        this.agenteId,
      ].every((field) => !!field);
    },
  },
  props: {
    request: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  async created() {
    await this.getToken();
    if (sessionStorage.getItem("carPrice")) {
      const data = JSON.parse(sessionStorage.getItem("carPrice"));
      this.personalData = data.personalData;
      this.setInsurerSelected(data.insurerSelected);
      this.insurers = data.insurers;
      this.carBrand = data.carBrand;
      this.plan = data.plan ?? '';
      this.tipoPago = data.tipoPago ?? '';
    }
  },
  mounted(){
    window.dataLayer.push({
      event: 'ITFY_auto_cotizar_v',
      no_cotizacion: undefined,
      userID: localStorage.agenteMail,
      correo: undefined,
      telefono: undefined,
    });
  }
};
</script>
<style></style>
