<template>
  <div>
    <v-row v-for="(item, index) in items" :key="index">
      <v-col cols="12" md="12">
        <div class="shadow-card my-3">
          <v-row>
            <v-col
              cols="12"
              sm="2"
              class="text-center d-flex align-center px-5"
            >
              <v-img
                :src="item.logo"
                contain
                :alt="item.name"
                width="100"
                height="80"
                class="br-black"
              ></v-img>
            </v-col>
            <v-col cols="12" sm="2" class="text-center d-flex align-center">
              <v-row>
                <v-col cols="12" v-if="item.planName">
                  <span
                    class="text-subtitle-1 font-weight-bold mt-1 mr-2"
                    style="color: #4D5358"
                    >{{ item.planName }}</span
                  >
                </v-col>
                <v-col cols="12">
                  <span>Total:</span>
                </v-col>
                <v-col cols="12">
                  <h2>{{ formatPrice(item.price) }}</h2>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="5" style="background: aliceblue">
              <ul>
                <li
                  v-for="(benefit, bIndex) in item.benefits"
                  :key="bIndex"
                  class="my-2"
                >
                  {{ benefit }}
                </li>
              </ul>
            </v-col>

            <v-col
              cols="12"
              sm="3"
              class="text-right d-flex align-center"
              style="background: aliceblue"
            >
              <v-btn
                color="#00A7E4"
                block
                dark
                class="rounded-xl"
                @click="iwant(item)"
                :disabled="!isValid || isAdmin()"
              >
                ¡LO QUIERO!
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <!-- <v-col cols="12" md="2" class="hidden-sm-and-down" >
        <v-btn
          style="height: 100%; background: #FFD100; border-radius:12px"
          class="col-md-11"
        >
          <v-img
            :src="require('@/assets/megaphone.svg')"
            max-width="60px"
            aspect-ratio="1"
          ></v-img>
        </v-btn>
      </v-col> -->
    </v-row>
    <CommonActionModal
      :dialog="existsModal"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="choiceSolicitud"
    />
    <CommonActionModal
      :dialog="toPayEmisionModal"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="choiceToPayModal"
    />
    <CommonActionModal
      :dialog="recotizarModal"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="choiceToRecotizar"
    />
    <CommonActionModal
      :dialog="toEmisionModal"
      :title="titleModal"
      :subtitle="subtitleModal"
      :bottonText="bottonText"
      @userChoice="choiceToEmisionPagada"
    />
  </div>
</template>

<script>
import { mainAxios } from "@/mainAxios.js";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import validateVin from "@/services/vinValidator.service";
import { validarPagoEmision } from "@/services/emision.service";

export default {
  name: "CommonListaAseguradoras",
  components: {
    CommonActionModal,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    nameFunction: {
      type: String,
      required: false,
    },
    tipoCotizacion: {
      type: String,
      required: true,
      default: "Coche",
    },
    cotizacionGeneral: {
      type: String,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: true,
      required: false,
    },
    tipoVehiculo: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      existsModal: false,
      titleModal: "",
      subtitleModal: "",
      bottonText: "",
      emisionExistente: {},
      solicitudExistente: {},
      toPayEmisionModal: false,
      recotizarModal: false,
      toEmisionModal: false,
      currentItem: {},
    };
  },
  methods: {
    //Regresa true si el usuario es un agente
    isAdmin() {
      return localStorage.getItem("rolNotAdmin") === "ADMINAGENT" || localStorage.getItem("rolNotAdmin") === "AGENTCORP";
    },
    formatPrice(price) {
      return new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      }).format(price);
    },
    iwant(item) {
      const emissionData = JSON.parse(sessionStorage.getItem('contractorData'))
      const mascotaRequestData = JSON.parse(sessionStorage.getItem("mascotaRequest"))
      this.currentItem = item;
      switch (this.tipoCotizacion) {
        case "Coche":
        window.dataLayer.push({
            event: "ITFY_auto_seleccionar",
            plan: item.plan,
            userID: localStorage.getItem('agenteMail'),
            tipo_vehiculo: this.tipoVehiculo,
            // index: '',
            aseguradora: item.insurer,
            total_pago: item.price,
            boton_contacto: 'Lo quiero',
            no_cotizacion: this.cotizacionGeneral,
            modalidad_pago: item.tipoPago,
            correo: emissionData?.emission?.contractorData?.email,
            telefono: emissionData?.emission?.contractorData?.phone,
          })
          this.checkCotizacionGeneral(item);
          break;
        case "Mascota":
          window.dataLayer.push({
              event: "ITFY_mascota_seleccionar",
              plan: item.planName,
              userID: localStorage.getItem('agenteMail'),
              // index: '',
              total_pago: item.price,
              boton_contacto: 'Lo quiero',
              no_cotizacion: mascotaRequestData?.cotizacionGeneral,
              correo: mascotaRequestData?.requestInfo?.correo,
              telefono: mascotaRequestData?.requestInfo?.telefono,
            })
          this.checkCotizacionGeneral(item);
          break;
        case "Medico":
          this.checkCotizacionGeneralSolicitud(item);
          break;
      }
    },
    checkCotizacionGeneral(item) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          `/v1/emision/check/cotizacion-general/` + this.cotizacionGeneral,
          config
        )
        .then((response) => {
          if (response.status == 200) {
            this.$emit(this.nameFunction, item);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            this.emisionExistente = error.response.data;
            if(this.tipoCotizacion == 'Coche')
            this.checkIfIsTheSameInsurance(item);
            else if(this.tipoCotizacion == 'Mascota'){
              this.checkIfEmisionPagada(item)
            }
          } else {
            console.error("Error de solicitud: ", error);
          }
        });
    },
    checkCotizacionGeneralSolicitud(item) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(
          `/v1/solicitud/check/cotizacion-general/` + this.cotizacionGeneral,
          config
        )
        .then((response) => {
          if (response.status == 200) this.$emit(this.nameFunction, item);
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            this.existsModal = true;
            (this.subtitleModal =
              "Parece que ya existe una solicitud para la cotizacion general: " +
              this.cotizacionGeneral),
              (this.bottonText = "Ir a solicitud");
            this.titleModal = "Solicitud existente";
            this.solicitudExistente = error.response.data;
          } else {
            console.error("Error de solicitud: ", error);
          }
        });
    },
    choiceSolicitud(val) {
      if (val && this.tipoCotizacion != "Coche")
        this.$router.push("/detalles-solicitud/" + this.solicitudExistente.id);
      else if (val && this.tipoCotizacion == "Coche")
        this.$router.push("/detalles-emision/" + this.emisionExistente.id);

      this.existsModal = false;
    },
    async checkIfIsTheSameInsurance(item) {
      var requestInter = JSON.parse(this.emisionExistente.request);
      var vin = this.emisionExistente.numSerie ?? null;
      var insurance = requestInter ? requestInter.insurance : null;

      var pagada = await validarPagoEmision({
        polizaId: this.emisionExistente.id,
      });
      if (!pagada.data.policy_paid) {
        if (vin != null && insurance != null) {
          var response = await validateVin(vin, insurance);
          if (item.insurer == requestInter.insurance) {
            this.currentItem.vin = vin;
            this.toPayEmisionModal = true;
            (this.subtitleModal =
              "Emision con numero " +
              response.data.data.policy_id +
              " existente para esta cotización y con la aseguradora que seleccionaste y esta lista para ser pagada"),
              (this.bottonText = "Pagar");
            this.titleModal = "Emision existente y lista para ser pagada";
          } else {
            this.recotizarModal = true;
            this.subtitleModal =
              "Parece que ya existe una emisión relacionada para esta cotización con la aseguradora " +
              requestInter.insurance +
              " puedes volver a cotizar para poder emitir con la aseguradora de tu elección, ten en cuenta que la emisión: " +
              response.data.data.policy_id +
              " asociada a la cotización con el código de cotización general: " +
              this.cotizacionGeneral +
              " quedara cancelada.";
            this.bottonText = "Recotizar";
            this.titleModal =
              "Emision existente con la aseguradora: " + requestInter.insurance;
          }
        }
      } else {
        this.titleModal = "Emisión pagada";
        this.subtitleModal =
          "!Al parecer ya pagaste esta cotizacion! Puedes checar los detalles de la emision en el siguiente link";
        this.bottonText = "Ir a mi emisión";
        this.toEmisionModal = true;
      }
    },
    choiceToPayModal(val) {
      if (val) {
        this.$emit(this.nameFunction, this.currentItem);
      }
      this.toPayEmisionModal = false;
    },
    choiceToRecotizar(val) {
      if (val) this.cancelarEmision();
      this.recotizarModal = false;
    },
    cancelarEmision() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let request = {
        id: this.emisionExistente.id,
        status: 6,
      };
      mainAxios
        .post("/v1/emision/manual/actualizar", request, config)
        .then(() => {
          this.$emit("recotizar", true);
        });
    },
    choiceToEmisionPagada(val) {
      if (val)
        this.$router.push(`/detalles-emision/${this.emisionExistente.id}`);
      this.toEmisionModal = false;
    },
    checkIfEmisionPagada(item){
      if(this.emisionExistente.status == 3 && this.emisionExistente.poliza != null){
        this.titleModal = "Emisión pagada";
        this.subtitleModal =
          "!Al parecer ya pagaste esta cotizacion! Puedes checar los detalles de la emision en el siguiente link";
        this.bottonText = "Ir a mi emisión";
        this.toEmisionModal = true;
      }else{
        this.$emit(this.nameFunction, item);
      }
    }
  },
};
</script>
<style scoped>
.br-black {
  border-right: 1px dotted black;
}
@media (max-width: 600px) {
  .br-black {
    border-right: none;
  }
}
.shadow-card {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}
</style>
