<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <span class="titulo-header">Pólizas</span>
      </v-col>
      <v-col cols="12" md="2" v-if="rol != 'AGENTCORP' && rol != 'ADMINAGENT' && rol != 'AGENT' ">
        <v-btn class="common-botton" dark block @click="toCreatePoliza">
          <v-icon start> mdi-plus-circle </v-icon>
          Agregar
        </v-btn>  
      </v-col>
    </v-row>
    <div style="padding-top: 30px;" v-if="globalConfig.showMainHubGraphs">
        <stats-poliza></stats-poliza>
    </div>
    <div style="padding-top: 30px;">
      <filters-polizas-index></filters-polizas-index>
    </div>
  </div>
</template>


<script>

import FiltersPolizasIndex from './FiltersPolizasIndex.vue';
import StatsPoliza from './StatsPoliza.vue';

export default {
  components: {
    FiltersPolizasIndex,
    StatsPoliza
  },

  data() {
    return {
      globalConfig:JSON.parse(localStorage.getItem('globalConfig')),
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,

    };
  },
  methods: {
    toCreatePoliza(){
      this.$router.push('/poliza-crear')
    }
  },
};
</script>