<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Medio de contacto"
              outlined
              v-model="formData.tipo_llamada"
              primary
              disabled
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
              aria-readonly="true"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.producto"
              label="Producto"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              item-value="producto"
              item-text="producto"
              outlined
              :items="currentProducts"
              :rules="requiredRule"
              :disabled="!canEdit"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.empresa"
              label="Empresa"
              placeholder="Requerido"
              color="#00a7e4"
              item-value="empresa"
              item-text="empresa"
              :items="currentEmpresas"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.aseguradora"
              label="Aseguradora"
              placeholder="Requerido"
              color="#00a7e4"
              item-value="id"
              item-text="name"
              :items="currentAseguradoras"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.tipificacion"
              label="Tipificacion"
              placeholder="Requerido"
              color="#00a7e4"
              item-value="tipificacion"
              item-text="tipificacion"
              :items="currentTipificaciones"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
           <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.motivo"
              label="Motivo"
              placeholder="Requerido"
              color="#00a7e4"
              item-value="motivo"
              item-text="motivo"
              :items="currentMotivos"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <!--<v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              v-model="formData.plantilla"
              label="Plantilla"
              placeholder="Requerido"
              color="#00a7e4"
              item-value="plantilla"
              item-text="plantilla"
              :items="currentPlantillas"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col> -->
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              label="Poliza"
              outlined
              v-model="formData.poliza"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3" lg="3" xl="3">
            <span class="text-header-data whitout-padding-bottom ">
              ¿Es queja?
            </span>
            <v-radio-group v-model="formData.isQueja" row>
              <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
              <v-radio label="No" value="false" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="12" xl="12">
            <v-textarea
              v-model="formData.documentos_solicitados"
              label="Documentos solicitados"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRule"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { getCampaniasEmailsList } from "@/services/campañasEmails/campañasEmails.js";
export default {
  components: {
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      run: false,
      regimentList: ["Física", "Moral"],
      generoList: ["Masculino", "Femenino"],
      formData: {
        tipo_llamada: "Correo Electronico",
        producto: null,
        empresa: null,
        aseguradora: null,
        tipificacion: null,
        motivo: null,
        //plantilla: null,
        documentos_solicitados: null,
        isQueja:null,
        poliza:null,
        agenteId:null
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      roles: JSON.parse(localStorage.getItem("roles")),
      campaniasEmails: [],
      currentEmpresas: [],
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA Seguros" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero Seguros" },
        { id: 5, name: "Quálitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "SURA RSA Seguros" },
        { id: 8, name: "AIG Seguros México" },
        { id: 9, name: "HDI Seguros" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "BANORTE" },
        { id: 13, name: "ATLAS" },
        { id: 14, name: "CHUBB" },
        { id: 15, name: "GMX" },
        { id: 16, name: "ALLIANZ" },
        { id: 17, name: "ARGOS" },
        { id: 18, name: "ASERTA" },
        { id: 19, name: "BX+" },
        { id: 20, name: "MUTUUS" },
        { id: 21, name: "PLAN SEGURO" },
        { id: 22, name: "PREVEM" },
        { id: 23, name: "SISNOVA" },
        { id: 24, name: "BUPA" },
        { id: 25, name: "METLIFE" },
        { id: 26, name: "INSIGNIA" },
        { id: 27, name: "MAPFRE" },
        { id: 28, name: "EL POTOSI" },
        { id: 29, name: "Quálitas" },
        { id: 30, name: "Gnp" },
        { id: 31, name: "AIG Seguros México" },
        { id: 32, name: "Inter.mx" },
        { id: 33, name: "General de Seguros" },
      ],
      currentAseguradoras: [],
      currentTipificaciones: [],
      currentMotivos: [],
      currentPlantillas: [],
      currentDatosSolicitados: [],
    };
  },
  props: {
    datosSolicitud: Object,
  },
  computed: {
    canEdit() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "DIRECTOR",
        "FINANZAS",
        "MANAGERCC",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "OPERADORINBOUND",
        "OPERACIONESINBOUND",
        "MESACONTROLINBOUND",
        "BACKOFFICEEMAILS",
        "MANAGEREMAILS",
        "MESACONTROLEMAIL",
        "OPERACIONESEMAILS",
        "OPERADOREMAILS",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentProducts() {
      var uniqueProducts = [];
      var filteredCampaigns = this.campaniasEmails;

      uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.producto))
      ).map((producto) => {
        return filteredCampaigns.find((a) => a.producto === producto);
      });

      uniqueProducts.push({
        producto: "Seguimiento",
      });

      return uniqueProducts;
    },
  },
  watch: {
    datosSolicitud() {
      this.fillData();
      this.getCampaniasEmailsList();
    },
    "formData.producto"() {
      this.calculateEmpresas();
    },
    "formData.empresa"() {
      this.calculateAseguradoras();
    },
    "formData.aseguradora"() {
      this.calculateTipificaciones();
    },
    "formData.tipificacion"() {
      this.calculateMotivos();
    },
    "formData.motivo"() {
      this.calculateAgenteID();
      this.calculatePlantillas();
      this.calculateDatosSolicitados();
    },
    "formData.plantilla"() {
    },
  },
  methods: {
    fillData() {
      const jsonData = this.datosSolicitud.solicitudBody || "{}";
      Object.keys(this.formData).forEach((key) => {
        if (jsonData[key] !== undefined) {
          this.formData[key] = jsonData[key];
        }
      });
      this.formData.status = this.datosSolicitud.status ?? null;
    },
    async getCampaniasEmailsList() {
      this.campaniasEmails = await getCampaniasEmailsList();
    },
    calculateEmpresas() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) => e.producto == this.formData.producto
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.empresa))
      ).map((empresa) => {
        return filteredCampaigns.find((a) => a.empresa === empresa);
      });
      this.currentEmpresas = uniqueProducts;
    },
    calculateAseguradoras() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.aseguradora_id))
      ).map((aseguradora_id) => {
        return filteredCampaigns.find(
          (a) => a.aseguradora_id === aseguradora_id
        );
      });

      const idsToFilter = uniqueProducts.map((item) => item.aseguradora_id);
      const filteredAseguradoras = this.aseguradorasList.filter((aseguradora) =>
        idsToFilter.includes(aseguradora.id)
      );

      this.currentAseguradoras = filteredAseguradoras;
    },
    calculateTipificaciones() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa &&
          e.aseguradora_id == this.formData.aseguradora
      );

      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.tipificacion))
      ).map((tipificacion) => {
        return filteredCampaigns.find((a) => a.tipificacion === tipificacion);
      });
      this.currentTipificaciones = uniqueProducts;
    },
    calculateMotivos() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa &&
          e.aseguradora_id == this.formData.aseguradora &&
          e.tipificacion == this.formData.tipificacion
      );

      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.motivo))
      ).map((motivo) => {
        return filteredCampaigns.find((a) => a.motivo === motivo);
      });
      this.currentMotivos = uniqueProducts;
    },
    calculatePlantillas() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa &&
          e.aseguradora_id == this.formData.aseguradora &&
          e.tipificacion == this.formData.tipificacion &&
          e.motivo == this.formData.motivo
      );

      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.plantilla))
      ).map((plantilla) => {
        return filteredCampaigns.find((a) => a.plantilla === plantilla);
      });
      this.currentPlantillas = uniqueProducts;
    },
    calculateDatosSolicitados() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa &&
          e.aseguradora_id == this.formData.aseguradora &&
          e.tipificacion == this.formData.tipificacion &&
          e.motivo == this.formData.motivo
      );

      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.documentos_solicitados))
      ).map((documentos_solicitados) => {
        return filteredCampaigns.find(
          (a) => a.documentos_solicitados === documentos_solicitados
        );
      });
      this.formData.documentos_solicitados = uniqueProducts ? uniqueProducts[0].documentos_solicitados : '';
    },
    calculateAgenteID() {
      let filteredCampaigns = this.campaniasEmails.filter(
        (e) =>
          e.producto == this.formData.producto &&
          e.empresa == this.formData.empresa &&
          e.aseguradora_id == this.formData.aseguradora &&
          e.tipificacion == this.formData.tipificacion &&
          e.motivo == this.formData.motivo
      );

      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.agente_id))
      ).map((agente_id) => {
        return filteredCampaigns.find(
          (a) => a.agente_id === agente_id
        );
      });
      console.log({uniqueProducts})
      this.formData.agenteId = uniqueProducts[0] ? uniqueProducts[0].agente_id : null;
    },
  },
  async mounted() {
    await this.getCampaniasEmailsList();
    this.fillData();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
