<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <span class="titulo-header">Crear Póliza</span>
      </v-col>
      <v-col cols="12" md="2" style="text-align: end;">
        <v-btn
          rounded
          dark
          outlined
          color="#00a7e4"
          @click="toDashboard"
          style="margin-right: 11px;"
        >
          Cancelar
        </v-btn>
      </v-col>
    </v-row>
    <div class="pt-5">
      <commond-form
        :request="request"
        :route="'/v1/emision/manual'"
        :run="run"
        :action="'POST'"
        @cancel="run = false"
        @success="changeComponent"
        @afterError="changeComponent"
      >
        <template v-slot:data>
          <CreatePolizaDataForm
            :currentStep="currentStep"
            @ChangeOnlyStep="ChangeOnlyStep"
          />
          <CreatePolizaClienteForm
            :lead="lead"
            :currentStep="currentStep"
            @ChangeOnlyStep="ChangeOnlyStep"
          />
          <CreatePolizaGeneralForm
            :currentStep="currentStep"
            :ramo="firstStepData.categoriaSelected"
            :anios="firstStepData.anios"
            @ChangeOnlyStep="ChangeOnlyStep"
            @goToSave="goToSave"
          />
        </template>
      </commond-form>
    </div>
  </div>
</template>

<script>
import CreatePolizaDataForm from "./CreatePolizaData/CreatePolizaDataForm.vue";
import CreatePolizaClienteForm from "./CreatePolizaData/CreatePolizaClienteForm.vue";
import CreatePolizaGeneralForm from "./CreatePolizaData/CreatePolizaGeneralForm.vue";
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import { getProductoList } from "../../../../../services/productos/product.service";

export default {
  components: {
    CreatePolizaDataForm,
    CreatePolizaClienteForm,
    CreatePolizaGeneralForm,
    CommondForm,
  },
  data() {
    return {
      request: {
        leadId: null,
        lead_id: null,
      },
      firstStepData: {
        ramo: null,
      },
      secondStepData: {},
      thirdStepData: {},
      currentStep: 1,
      run: false,
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    ChangeOnlyStep(toStep, polizaData) {
      this.currentStep = toStep;
      if (polizaData != null)
        switch (toStep) {
          case 2:
            this.firstStepData = polizaData;
            break;
          case 3:
            this.secondStepData = polizaData;
            break;
        }
    },
    toDashboard() {
      if (!this.isModal) this.$router.push("main-hub/2");
      else this.$emit("closeModal");
    },
    goToSave(data) {
      this.request = {
        ...this.firstStepData,
        ...this.secondStepData,
        ...data,
      };
      if (this.firstStepData.categoriaSelected == "AHORRO") {
        this.request.prima_total = data.valorPlan;
        this.request.prima_neta = data.montoInversion;
        this.request.primer_pago = data.montoInversion;
        this.request.subsecuente_pago = data.montoInversion;
      }
      if (this.lead != {}) this.request.leadId = this.lead.id;

      this.run = true;
    },
    changeComponent(response) {
      if (!window.location.pathname.includes("/segurify/leads-segurify/")) {
        if (this.checkIfNeedBeneficiary()) {
          this.$router.push({
            name: "detalles-emision",
            params: {
              id: response.data.id,
            },
            query: {
              beneficiarios: "true",
            },
          });
        } else {
          this.$router.push("/detalles-emision/" + response.data.id);
        }
      } else this.$emit("getPolizas");
    },
    async checkIfNeedBeneficiary() {
      const products = await getProductoList();
      const currentProduct = products.find(
        (e) => e.id == this.request.producto_id
      );
      return currentProduct ? currentProduct.beneficiario : false;
    },
  },
};
</script>
