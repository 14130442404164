<template>
  <div>
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de la solicitud
      </template>
      <v-expansion-panel-content>
        <UpdateSolicitudDataForm v-if="showEditForm" :solicitud="datosSolicitud" @cancel="showEditForm = false"
          @getData="$emit('getData')"></UpdateSolicitudDataForm>
        <div v-else>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn v-if="canEdit" rounded dark class="common-botton" @click="showEditForm = true">
                <span style="padding-right: 11%;">
                  Editar
                </span>
                <v-icon rigth>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Estatus
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.status ? datosSolicitud.status : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Origen de la solicitud
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.origen ? datosSolicitud.origen : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4" v-if="datosSolicitud.polizaRelacionada != null">
              <span class="text-header-data ">
                Póliza relacionada
              </span>
              <br />
              <a class="text-data-details" @click="goPoliza">
                {{
                  datosSolicitud.polizaRelacionada ? datosSolicitud.polizaRelacionada : "Sin llenar"
                }}
              </a>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Agente
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.nombreAgente
                    ? datosSolicitud.nombreAgente
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Tipo
              </span>
              <br />
              <span class="text-data-details">
                {{ datosSolicitud.tipo ? datosSolicitud.tipo : "Sin llenar" }}
              </span>
            </v-col>
            <!-- <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                ID de Origen Lead
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.idOrigenLead
                    ? datosSolicitud.idOrigenLead
                    : "Sin llenar"
                }}
              </span>
            </v-col> -->
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Ramo
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.categoria
                    ? datosSolicitud.categoria
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Campaña
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.campania
                    ? datosSolicitud.campania
                    : "Sin llenar"
                }}
              </span>
            </v-col>
            <v-col cols="6" sm="4" md="4" lg="4" xl="4">
              <span class="text-header-data ">
                Productos
              </span>
              <br />
              <span class="text-data-details">
                {{
                  datosSolicitud.campania
                    ? datosSolicitud.campania
                    : "Sin llenar"
                }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-expansion-panels elevation="0" style="padding-top: 20px; padding-bottom: 20px; border-radius: 20px">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="text-header-data ">
                    Detalle de solicitud
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <span class="text-data-details">
                      {{ datosSolicitud.detalle }}
                    </span>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </div>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";

import UpdateSolicitudDataForm from "../Update/CreateSolicitudData/UpdateSolicitudDataForm.vue";

export default {
  components: {
    CommonExpansionPanel,
    UpdateSolicitudDataForm,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      showEditForm: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  props: {
    datosSolicitud: Object,
  },
  methods: {
    goPoliza() {
      this.$router.push('/detalles-emision/' + this.datosSolicitud.polizaRelacionada)
    }
  },
  computed: {
    canEdit() {
      switch (this.rol) {
        case 'AGENT':
        case 'ADMINAGENT':
        case 'AGENTCORP':
          return false
        default:
          return true
      }
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}

.v-expansion-panels {
  z-index: auto !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-expansion-panel-header {
  padding-left: 10px !important;
}
</style>
