var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"items":_vm.solicitudes,"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"Sin solicitudes para mostrar","no-results-text":"Sin resultados para mostrar","loading-text":"Cargando...","footer-props":{ 'items-per-page-options': [10, 20, 30, 40, 50] },"server-items-length":_vm.total},on:{"update:page":function($event){_vm.pagina = $event},"update:items-per-page":function($event){_vm.elementosPagina = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Resultados de la búsqueda")])])],1)]},proxy:true},{key:"item.fechaCreacion",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getFormatDate(item.fechaCreacion))+" ")])]}},{key:"item.operadorId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getNombre(item.operadorId))+" ")])]}},{key:"item.cumpleSla",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.cumpleSla == 1 ? "Sí cumple" : "No cumple"))])]}},{key:"item.ver",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.verSolicitud(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"footer.page-text",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }