<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="text-align: end; display: flex; gap: 30px; align-items: center; justify-content: flex-end;"
      >
        <v-progress-circular
          v-if="uploadingData"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <span
          v-if="editable"
          @click="editable = false"
          style="cursor: pointer;"
        >
          Cancelar
        </span>
        <v-btn
          v-if="canEdit"
          rounded
          dark
          class="common-botton"
          @click="changeComponent"
          :style="
            editable
              ? 'min-width: 64px; padding: 10px;'
              : 'border-radius: 50%; width: 36px; height: 36px; min-width: 0px;'
          "
        >
          <span v-if="editable" @click="saveData">
            {{ uploadingData ? "Guardando..." : null }}
            Guardar
          </span>
          <v-icon small v-if="!editable && isAdminAgent" style="margin:4px">
            mdi-pencil
          </v-icon>
          <v-icon small v-if="editable" style="margin:4px">
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Nombre de la alianza</span>
        <br />
        <v-text-field
          name="nombreAlianza"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.nameAlliance ? '' : 'font-style: italic;'"
          :value="
            editableData.nameAlliance
              ? editableData.nameAlliance
              : 'sin información'
          "
          :disabled="!editable"
          @change="editableData.nameAlliance = $event"
        />
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4"> </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Régimen </span>
        <br />
        <v-autocomplete
          item-text="label"
          item-value="id"
          @change="editableData.cat_regime_id = $event"
          :value="
            editableData.cat_regime_id
              ? editableData.cat_regime_id
              : 'sin información'
          "
          :items="regimen"
          placeholder="Seleccionar Género"
          outlined
          primary
          color="#00a7e4"
          :disabled="!editable"
          style="max-height: 40px;"
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Nombre </span>
        <br />
        <v-text-field
          name="nombre"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.nombre ? '' : 'font-style: italic;'"
          :value="editableData.nombre ? editableData.nombre : 'sin información'"
          :disabled="!editable"
          @change="editableData.nombre = $event"
        />
      </v-col>

      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Apellido Paterno </span>
        <br />
        <v-text-field
          name="apellidoPaterno"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.apellidoPaterno ? '' : 'font-style: italic;'"
          :value="
            editableData.apellidoPaterno
              ? editableData.apellidoPaterno
              : 'sin información'
          "
          :disabled="!editable"
          @change="editableData.apellidoPaterno = $event"
        />
      </v-col>

      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Apellido Materno </span>
        <br />
        <v-text-field
          name="apellidoMaterno"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.apellidoMaterno ? '' : 'font-style: italic;'"
          :value="
            editableData.apellidoMaterno
              ? editableData.apellidoMaterno
              : 'sin información'
          "
          :disabled="!editable"
          @change="editableData.apellidoMaterno = $event"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Género </span>
        <br />
        <v-autocomplete
          item-text="sexo"
          item-value="id"
          :value="
            editableData.cat_gender_id
              ? editableData.cat_gender_id
              : 'sin información'
          "
          :items="sexoList"
          placeholder="Seleccionar Género"
          outlined
          primary
          color="#00a7e4"
          :disabled="!editable"
          style="max-height: 40px;"
          dense
          @change="editableData.cat_gender_id = $event"
        />
      </v-col>

      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Fecha de nacimiento </span>
        <br />
        <v-text-field
          name="fechaNacimiento"
          outlined
          primary
          dense
          color="#00a7e4"
          type="date"
          :style="editableData.fechaNacimiento ? '' : 'font-style: italic;'"
          :value="
            editableData.fechaNacimiento
              ? editableData.fechaNacimiento
              : 'sin información'
          "
          :disabled="!editable"
          @change="editableData.fechaNacimiento = $event"
        />
      </v-col>

      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data"> Estado de nacimiento</span>
        <br />
        <v-autocomplete
          item-text="estadoRepublica"
          item-value="id"
          :value="
            editableData.cat_state_id
              ? editableData.cat_state_id
              : 'sin información'
          "
          :items="estados"
          placeholder="Seleccionar Estado de nacimiento"
          outlined
          primary
          color="#00a7e4"
          :disabled="!editable"
          style="max-height: 40px;"
          dense
          @change="editableData.cat_state_id = $event"
        />
      </v-col>
    </v-row>

    <v-row class="d-flex" style="gap: 16px; margin-top: 16px;">
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Correo electrónico</span>
        <br />
        <v-text-field
          name="correo"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.correo ? '' : 'font-style: italic;'"
          :value="editableData.correo ? editableData.correo : 'sin información'"
          :disabled="!editable"
          @change="editableData.correo = $event"
        />
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">RFC</span>
        <br />
        <v-text-field
          name="rfc"
          outlined
          primary
          dense
          color="#00a7e4"
          :style="editableData.rfc ? '' : 'font-style: italic;'"
          :value="editableData.rfc ? editableData.rfc : 'sin información'"
          :disabled="!editable"
          :rules="rulesRfc"
          @change="editableData.rfc = $event"
        />
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4" @click="openMenuMethod">
        <span class="text-header-data">Ramo</span>
        <br />
        <!-- <div
          v-if="!editable"
          class="chips-container"
          style="display: flex; flex-wrap: wrap; gap: 8px;"
        >
          <v-chip
            v-for="branchId in editableData.cat_branch_ids.filter(
              (id) => typeof id === 'number'
            )"
            :key="branchId"
          >
            {{ getBranchName(branchId) }}
          </v-chip>
        </div> -->
        <div style="position: relative;">
          <v-menu v-model="openMenu" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" style="visibility: hidden; position: absolute;"
                >Open Menu</v-btn
              >
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in ramoList" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    v-model="editableData.cat_branch_ids"
                    :value="item.branchId"
                    :input-value="
                      editableData.cat_branch_ids.includes(item.branchId)
                    "
                    :disabled="!editable"
                  ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-autocomplete
            v-model="editableData.cat_branch_ids"
            :items="ramoList"
            item-text="label"
            item-value="branchId"
            label="Ramo"
            multiple
            outlined
            primary
            color="#00a7e4"
            dense
            @change="handleBranchSelectionChange"
            :readonly="!editable"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                style="padding: 1px; border-radius: 5px; height: 20px"
                color="primary"
                v-if="index < 3"
              >
                <span style="padding: 3px; margin: 0">{{
                  ramoList[index].label
                }}</span>
              </v-chip>
              <span v-if="index === 3" class="grey--text text-caption">
                (+{{ editableData.cat_branch_ids.length - 3 }} más)
              </span>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Canal</span>
        <br />
        <div v-if="!editable">
          {{
            editableData.cat_channel_id
              ? getChannelName(editableData.cat_channel_id)
              : "sin información"
          }}
        </div>
        <v-autocomplete
          v-else
          item-text="label"
          item-value="channelId"
          :value="
            editableData.cat_channel_id
              ? editableData.cat_channel_id
              : 'sin información'
          "
          :items="canalList"
          placeholder="Seleccionar Canal"
          outlined
          primary
          color="#00a7e4"
          :disabled="!editable"
          style="max-height: 40px;"
          dense
          @change="editableData.cat_channel_id = $event"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="success" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >La alianza se modificó con éxito</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="success = false"
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mainAxios } from "../../../../../../mainAxios";

export default {
  components: {},
  props: {
    personalData: {
      type: Object,
      default: () => {},
    },
    stateList: Array,
    routesConfig: Object,
    listings: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // loading: false,
      success: false,
      isAdminAgent: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      uploadingData: false,
      editableData: {
        nameAlliance: "",
        cat_regime_id: null,
        nombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        fechaNacimiento: "",
        cat_state_id: null,
        telefono: "",
        celular: "",
        rfc: "",
        correo: "",
        cat_channel_id: null,
        cat_gender_id: null,
        cat_branch_ids: [],
      },
      editable: false,
      sexoList: [
        { id: 1, sexo: "Masculino" },
        { id: 2, sexo: "Femenino" },
      ],
      emailRules: [
        (v) => !!v || "E-mail es necesario",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail no es valido",
      ],
      rulesRfc: [
        (v) => !!v || "Este campo es requerido",
        (v) => {
          if (v == null || v.trim() == "") {
            return true;
          }
          let value = v.toUpperCase();
          const regex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?(([A-Z\d]{2})([A\d])|)$/;
          let correcto = value.match(regex);

          return !!correcto || "La RFC no es valida";
        },
      ],
      regimen: [
        { id: 1, label: "Persona Moral" },
        { id: 2, label: "Persona Física" },
      ],
      ramoList: [],
      canalList: [],
      estados: [
        { id: 1, estadoRepublica: "Aguascalientes" },
        { id: 2, estadoRepublica: "Baja California" },
        { id: 3, estadoRepublica: "Baja California Sur" },
        { id: 4, estadoRepublica: "Campeche" },
        { id: 5, estadoRepublica: "Coahuila de Zaragoza" },
        { id: 6, estadoRepublica: "Colima" },
        { id: 7, estadoRepublica: "Chiapas" },
        { id: 8, estadoRepublica: "Chihuahua" },
        { id: 9, estadoRepublica: "Ciudad de México" },
        { id: 10, estadoRepublica: "Durango" },
        { id: 11, estadoRepublica: "Guanajuato" },
        { id: 12, estadoRepublica: "Guerrero" },
        { id: 13, estadoRepublica: "Hidalgo" },
        { id: 14, estadoRepublica: "Jalisco" },
        { id: 15, estadoRepublica: "Estado de México" },
        { id: 16, estadoRepublica: "Michoacán de Ocampo" },
        { id: 17, estadoRepublica: "Morelos" },
        { id: 18, estadoRepublica: "Nayarit" },
        { id: 19, estadoRepublica: "Nuevo León" },
        { id: 20, estadoRepublica: "Oaxaca" },
        { id: 21, estadoRepublica: "Puebla" },
        { id: 22, estadoRepublica: "Querétaro" },
        { id: 23, estadoRepublica: "Quintana Roo" },
        { id: 24, estadoRepublica: "San Luis Potosí" },
        { id: 25, estadoRepublica: "Sinaloa" },
        { id: 26, estadoRepublica: "Sonora" },
        { id: 27, estadoRepublica: "Tabasco" },
        { id: 28, estadoRepublica: "Tamaulipas" },
        { id: 29, estadoRepublica: "Tlaxcala" },
        { id: 30, estadoRepublica: "Veracruz" },
        { id: 31, estadoRepublica: "Yucatán" },
        { id: 32, estadoRepublica: "Zacatecas" },
        { id: 33, estadoRepublica: "Desconocio" },
      ],
      branchMap: {
        1: "Auto",
        2: "Salud",
        3: "Daños",
        4: "Fianza",
        5: "Vida",
        6: "Ahorro",
      },
      labelToIdMap: {
        Auto: 1,
        Salud: 2,
        Daños: 3,
        Danios: 3,
        Fianza: 4,
        Vida: 5,
        Ahorro: 6,
      },
      filteredLabels: [],
      openMenu: false,
    };
  },
  watch: {
    editable() {
      console.log("editable");
      this.listas();
      // this.editableData = this.personalData;
      this.adminagent();
      console.log("editableData edit change", this.editableData);
      this.openMenu = false;
    },
    editableData() {
      console.log("editableData", this.editableData.cat_branch_ids);
    },
    success() {
      console.log("success", this.success);
    },
  },
  mounted() {
    this.listas();
    // this.editableData = this.personalData;
    this.editableData = {
    ...this.editableData, // mantiene lo ya definido en data
    ...this.personalData, // sobrescribe con personalData
  };
  if (!Array.isArray(this.editableData.cat_branch_ids)) {
    this.editableData.cat_branch_ids = [];
  }
    this.adminagent();
  },

  methods: {
    openMenuMethod() {
      console.log("openMenuMethod");
      this.openMenu = !this.openMenu;
      if (this.editable) {
        this.openMenu = false;
      }
    },
    changed(event) {
      console.log("changed", event);
    },
    getChannelName(id) {
      return (
        this.canalList.find((e) => e.channelId == id)?.label ?? "Desconocido"
      );
    },
    handleBranchSelectionChange(newVal) {
      this.filteredLabels = newVal.filter((item) => typeof item === "string");
      console.log("newVal", newVal);
      this.editableData.cat_branch_ids = newVal;
    },
    getBranchName(id) {
      let branchName = this.branchMap[id] || "Desconocido";
      if (branchName === "Danios") {
        branchName = "Daños";
      }
      return branchName;
    },
    adminagent() {
      this.roles.forEach((element) => {
        if (element.rol == "ADMINAGENT") {
          this.isAdminAgent = true;
        }
      });
    },

    listas() {
      // this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/catalogo/alliance`, config)
        .then((response) => {
          function corregirLabels(branchs) {
            return branchs.map((branch) => {
              if (branch.label === "Danios") {
                branch.label = "Daños";
              }
              return branch;
            });
          }
          corregirLabels(response.data.branchs);

          console.log("ramoList", response.data);
          this.ramoList = response.data.branchs;
          this.canalList = response.data.channels;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    saveData() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
          "Content-Type": "application/json",
        },
      };

      // let newBranchIds = [
      //   ...new Set(
      //     this.filteredLabels
      //       .map((branch) => {
      //         if (typeof branch === "object" && branch !== null) {
      //           return branch.id;
      //         } else if (typeof branch === "string") {
      //           return this.labelToIdMap[branch] || null;
      //         } else {
      //           return branch;
      //         }
      //       })
      //       .filter((id) => id !== null)
      //   ),
      // ];
      //  this.editableData.cat_branch_ids = newBranchIds;

      const putData = {
        uuid: this.editableData.uuid,
        companyName: this.editableData.razonSocial,
        nameAlliance: this.editableData.nameAlliance,
        name: this.editableData.nombre,
        lastname: this.editableData.apellidoPaterno,
        secondLastname: this.editableData.apellidoMaterno,
        birthdate: this.editableData.fechaNacimiento,
        phone: this.editableData.telefono,
        cellphone: this.editableData.celular,
        taxId: this.editableData.rfc,
        cat_channel_id: this.editableData.cat_channel_id,
        cat_state_id: this.editableData.cat_state_id,
        cat_gender_id: this.editableData.cat_gender_id,
        cat_regime_id: this.editableData.cat_regime_id,
        cat_branch_ids: this.editableData.cat_branch_ids,
        email: this.editableData.correo,
      };

      if (this.editable) {
        this.uploadingData = true;
        try {
          mainAxios
            .put(`/v1/alliance`, JSON.stringify(putData), config)
            .then(() => {
              this.uploadingData = false;
            });
        } catch (error) {
          console.log("error aquí", error);
          this.uploadingData = false;
          this.cat_branch_ids = [];
        } finally {
          this.uploadingData = false;
          this.success = true;
        }
      }
      // this.listas();
    },

    edit() {
      this.editable = !this.editable;
    },
    changeComponent() {
      this.$emit("changeComponent", 2);
      this.edit();
    },
    getGenero(value) {
      return value == "1" ? "Masculino" : "Femenino";
    },
    getStateName(id) {
      return (
        this.stateList.find((e) => e.cEstado == id).dEstado ?? "No disponible"
      );
    },
    formatDate(value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    getRol(value) {
      return this.listings.roles.find((e) => e.id == value)
        ? this.listings.roles.find((e) => e.id == value).nombre
        : "No disponible";
    },
    getStatus(value) {
      return (
        this.listings.status.find((e) => e.id == value).name ?? "No disponible"
      );
    },
  },
};
</script>
