<template>
  <div>
    <!-- Las areas de negocio de operaciones de interify son 10 y 9  -->
    <SolicitudesInterify v-if="areaNegocioId == 10 || areaNegocioId == 9" />
    <!-- Las areas de negocio de emails es 3  -->
    <SolicitudesLlamadas v-if="areaNegocioId == 3"/>
    <!-- Las areas de negocio de llamadas inbound es 6  -->
    <SolicitudesEmails v-if="areaNegocioId == 6"/>

  </div>
</template>

<script>
import SolicitudesInterify from "./FlujoInterify/SolicitudesInterify.vue";
import SolicitudesEmails from "./FlujoEmails/SolicitudesEmails.vue"
import SolicitudesLlamadas from "./FlujoLlamadasInbound/SolicitudesLlamadas.vue"
import { getSolicitudById } from "@/services/solicitudes/Solicitudes.js";

export default {
  components: {
    SolicitudesInterify,
    SolicitudesEmails,
    SolicitudesLlamadas
  },
  data() {
    return {
      solicitud: {},
      areaDeNegocio: null,
      areaNegocioId: null,
    };
  },
  methods: {
    async getSolicitud() {
      this.solicitud = await getSolicitudById(this.$route.params.id);
      this.body = JSON.parse(this.solicitud.body);
      //Las areas de negocio corresponse segun el origen de la solicitud puede que que no tengan asignado una area de negocio porque fueron registros anteriores a este cambio
      this.areaNegocioId = this.solicitud.areaNegocioId
        ? this.solicitud.areaNegocioId
        : this.body.areaNegocioId
        ? this.body.areaNegocioId
        : 10;
    },
  },
  mounted() {
    this.getSolicitud();
  },
};
</script>
