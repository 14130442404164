<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Datos de Póliza
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-select
                label="Estatus de la Póliza"
                v-model="polizaData.status"
                :items="statusList"
                item-text="name"
                item-value="id"
                outlined
                color="#00a7e4"
                tipo_poliza="text"
                :disabled="rol != 'ADMIN'"
                readonly
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                label="No. Póliza"
                outlined
                v-model="polizaData.poliza"
                color="#00a7e4"
                tipo_poliza="text"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Tipo de Póliza"
                v-model="polizaData.tipo_poliza"
                :items="tipo_polizaPolizaList"
                outlined
                color="#00a7e4"
                tipo_poliza="text"
                placeholder="Requerido"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
              <v-autocomplete
                label="Agente"
                :items="agentesConRamos"
                item-text="nombre"
                item-value="id"
                v-model="polizaData.agente"
                placeholder="Requerido"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3" v-if="polizaData.agente != null">
              <v-autocomplete
                label="Ramo"
                :items="currentRamos"
                v-model="polizaData.categoriaSelected"
                placeholder="Requerido"
                item-text="etiqueta"
                item-value="ramo"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.categoriaSelected != null"
            >
              <v-autocomplete
                label="Productos"
                v-model="polizaData.producto_id"
                :items="currentproducto_id"
                item-text="nombre"
                item-value="id"
                placeholder="Requerido"
                :rules="requiredRule"
                outlined
                color="#00a7e4"
              ></v-autocomplete>
            </v-col>

            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.categoriaSelected != null"
            >
              <v-autocomplete
                label="Campaña"
                v-model="polizaData.campania_id"
                :items="currentCampanias"
                item-text="producto"
                item-value="id"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3" v-if="false">
              <v-text-field
                label="No. Cotización / Lead ID"
                v-model="polizaData.cotizacion"
                color="#00a7e4"
                outlined
                tipo_poliza="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="firstDataComplete">
            <v-col m="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="polizaData.inicio"
                type="date"
                label="Fecha inicio de vigencia"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="6" xl="6">
              <v-text-field
                v-model="polizaData.fin"
                type="date"
                label="Fecha fin de vigencia"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Periodicidad"
                v-model="polizaData.pago_id"
                :items="pago_idList"
                item-text="name"
                item-value="id"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.pago_id != null"
            >
              <v-autocomplete
                label="Forma de Pago"
                v-model="polizaData.forma_pago"
                :items="forma_pagoList"
                item-text="name"
                item-value="id"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>

            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.pago_id != null"
            >
              <v-text-field
                label="Años"
                v-model="polizaData.anios"
                tipo_poliza="number"
                :min="1"
                outlined
                :rules="requiredRule"
                color="#00a7e4"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.pago_id != null"
            >
              <v-text-field
                label="Número de cuotas"
                v-model="polizaData.numeroCuotas"
                tipo_poliza="number"
                readonly
                outlined
                :rules="requiredRule"
                :min="1"
                color="#00a7e4"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="
                polizaData.pago_id != null &&
                  polizaData.categoriaSelected != 'AHORRO'
              "
            >
              <v-text-field
                label="Prima neta"
                v-model="polizaData.prima_neta"
                :items="forma_pagoList"
                tipo_poliza="number"
                outlined
                :min="1"
                :rules="requiredRule"
                color="#00a7e4"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="
                polizaData.pago_id != null &&
                  polizaData.categoriaSelected != 'AHORRO'
              "
            >
              <v-text-field
                label="Prima total"
                v-model="polizaData.prima_total"
                :items="forma_pagoList"
                tipo_poliza="number"
                outlined
                :min="1"
                :rules="requiredRule"
                color="#00a7e4"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="
                polizaData.pago_id != null &&
                  polizaData.categoriaSelected != 'AHORRO'
              "
            >
              <v-text-field
                label="Monto de Primer Pago"
                v-model="polizaData.primer_pago"
                tipo_poliza="number"
                outlined
                :min="1"
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="
                (polizaData.categoriaSelected != 'AHORRO' &&
                  polizaData.pago_id != null &&
                  polizaData.pago_id != 1) ||
                  (polizaData.categoriaSelected != 'AHORRO' &&
                    polizaData.pago_id != null &&
                    polizaData.pago_id == 1 &&
                    polizaData.anios > 1)
              "
            >
              <v-text-field
                label="Monto de Pago Subsecuente"
                v-model="polizaData.subsecuente_pago"
                tipo_poliza="number"
                outlined
                :min="1"
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                v-if="polizaData.pago_id != null"
                v-model="polizaData.fechaPrimerPago"
                type="date"
                label="Fecha primer pago"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="firstDataComplete">
            <v-col m="12" md="12" lg="12" xl="12">
              <v-textarea
                label="Notas de la Póliza"
                outlined
                v-model="polizaData.notas"
                primary
                color="#00a7e4"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn rounded dark class="common-botton" @click="validateStep">
                <span style="padding-right: 11%;">
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";

export default {
  components: {
    CommonExpansionPanel,
  },
  props: {
    currentStep: Number,
  },
  data() {
    return {
      polizaData: {
        agente: null,
        anios: 1,
        aseguradora_id: null,
        campania_id: null,
        categoriaSelected: null,
        fechaPrimerPago: null,
        fin: null,
        forma_pago: null,
        inicio: null,
        leadId: null,
        montoPrimerPago: null,
        montoSubsecuente: null,
        notas: null,
        numeroCuotas: null,
        periodicidad: null,
        poliza: null,
        primaNeta: null,
        primaTotal: null,
        productos: null,
        status: 0,
        type: null,
        producto_id: null,
        cotizacion: 1,
        operador_id: parseInt(localStorage.id),
        alianzaId:null
      },
      tipo_polizaPolizaList: ["Nueva", "Renovación"],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      statusList: [
        { id: 0, name: "Nueva" },
        { id: 1, name: "Emitida Pendente de Pago" },
        { id: 2, name: "Pagada Parcial" },
        { id: 3, name: "Pagada Total" },
        { id: 4, name: "Renovada" },
        { id: 5, name: "Finalizada" },
        { id: 6, name: "Cancelada" },
      ],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      campanias: [],
      agentes: [],
      ramos: [],
      producto_id: [],
      productoOrigal: [],
      pago_idList: [
        { id: 1, name: "Anual" },
        { id: 2, name: "Semestral" },
        { id: 3, name: "Trimestral" },
        { id: 4, name: "Mensual" },
      ],
      forma_pagoList: [
        { id: 1, name: "Tarjeta de Crédito" },
        { id: 2, name: "Tarjeta de Débito" },
        { id: 3, name: "Depósito en banco" },
        { id: 4, name: "Transferencia" },
        { id: 5, name: "Cheque" },
        { id: 6, name: "Efectivo" },
        { id: 7, name: "Domiciliación" },
        { id: 8, name: "Cargo único" },
      ],
      currentPanel: 0,
      readonly: false,
      ramosIdsUsuario:[]
    };
  },
  watch: {
    "polizaData.agente"() {
      this.polizaData.campania_id = null;
      this.polizaData.categoriaSelected = null;
      this.polizaData.producto_id = null;
      this.polizaData.alianzaId = null;
      this.getCurrentAlianzaId();
      this.getCurrentCampaña();
    },
    "polizaData.categoriaSelected"() {
      this.polizaData.campania_id = null;
      this.polizaData.producto_id = null;
      this.polizaData.alianzaId = null;
      this.getCurrentAlianzaId();
      this.getCurrentCampaña();
    },
    "polizaData.inicio"(val) {
      var date = moment(val).format("YYYY-MM-DD");
      if (date != "Invalid date")
        this.polizaData.fin = moment(date)
          .add(parseInt(this.polizaData.anios), "year")
          .format("YYYY-MM-DD");
    },
    "polizaData.pago_id"() {
      this.calculateCuotas();
    },
    "polizaData.anios"(val) {
      if(val){
        this.calculateCuotas();
        this.calculateFinVigencia();
        if (val == 1) this.polizaData.subsecuente_pago = null;
      }
    },
    currentStep(val) {
      if (val == 1) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
    "polizaData.producto_id"(id) {
      let producto = this.producto_id.find((element) => element.id === id);
      this.polizaData.aseguradora_id = producto ? producto.aseguradoraId : null;
    },
    "polizaData.campania_id"(id) {
      let campania = this.campanias.find((element) => element.id === id);
      this.polizaData.campania = campania ? campania.producto : null;
    },
  },
  computed: {
    currentRamos() {
      if (this.polizaData.agente != null)
        return this.agentes.find((e) => e.id == this.polizaData.agente)
          .ramosList;
      else return [];
    },
    currentproducto_id() {
      if (this.polizaData.categoriaSelected != null)
        return this.producto_id.filter(
          (e) => e.ramo == this.polizaData.categoriaSelected
        );
      else return [];
    },
    currentCampanias() {
      if (this.polizaData.categoriaSelected != null)
        return this.campanias.filter(
          (e) => e.ramo == this.polizaData.categoriaSelected
        );
      else return [];
    },
    firstDataComplete() {
      return (
        this.polizaData.poliza != null &&
        this.polizaData.tipo_poliza != null &&
        this.polizaData.status != null &&
        this.polizaData.agente != null &&
        this.polizaData.categoriaSelected != null &&
        this.polizaData.campania_id != null &&
        this.polizaData.producto_id != null
      );
    },
    isAgente() {
      return this.rol == "AGENT";
    },
    agentesConRamos() {
      return this.agentes.filter(agente => this.tieneRamos(agente));
    },
  },
  methods: {
    getCampanias() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get("/v1/campania/lead-segurify/list", config)
        .then((response) => {
          this.campanias = response.data;
        });
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList ? element.canalesList : [],
            ramosList: element.ramosList ? element.ramosList : [],
            alianzaId: element.alianzaId ? element.alianzaId : null
          });
        });
      });
    },
    getRamos() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/ramo/list`, config).then((response) => {
        this.ramos = response.data;
      });
    },
    getproducto_id() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/producto/list", config).then((response) => {
        response.data.map((element) => {
          this.producto_id.push({
            nombre:
              `${element.ramo}` +
              " / " +
              `${element.nombre}` +
              " / " +
              `${element.nombreAseguradora}`,
            id: element.id,
            canal: element.canal ? element.canal : "Todos",
            ramo: element.ramo ? element.ramo : "No disponible",
            aseguradoraId: element.aseguradoraId
              ? element.aseguradoraId
              : "No disponible",
            tecnologia: element.tecnologia ? element.tecnologia : false,
          });
        });
        this.productoOrigal = this.producto_id;
      });
    },
    calculateDates() {
      this.polizaData.inicio = moment().format("YYYY-MM-DD");
      this.polizaData.fechaPrimerPago = moment().format("YYYY-MM-DD");
      this.polizaData.fin = moment()
        .add(1, "year")
        .format("YYYY-MM-DD");
    },
    calculateCuotas() {
      switch (this.polizaData.pago_id) {
        case 1:
          this.polizaData.numeroCuotas = 1 * this.polizaData.anios;
          break;
        case 2:
          this.polizaData.numeroCuotas = 2 * this.polizaData.anios;
          break;
        case 3:
          this.polizaData.numeroCuotas = 4 * this.polizaData.anios;
          break;
        case 4:
          this.polizaData.numeroCuotas = 12 * this.polizaData.anios;
          break;
        default:
          break;
      }
    },
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("ChangeOnlyStep", 2, this.polizaData);
      }
    },
    getCurrentCampaña() {
      if (this.polizaData.agente != null && this.polizaData.categoriaSelected) {
        var currentAgente = this.agentes.find(
          (e) => e.id == this.polizaData.agente
        );
        var currentRamo = this.currentRamos.find(
          (e) => e.ramo == this.polizaData.categoriaSelected
        );
        var currentCanalList = currentAgente.canalList;
        var currentCampania = this.campanias.find((e) => {
          return (
            e.canal == currentCanalList[0].canal && e.ramo == currentRamo.ramo
          );
        });
        this.polizaData.campania_id = currentCampania ? currentCampania.id : "";
      }
    },
    calculateFinVigencia() {
      this.polizaData.fin = moment().add(parseInt(this.polizaData.anios), "y").format("YYYY-MM-DD")
    },
    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get(`/v1/agente/uuid/${localStorage.agenteUuid}`,config).then((response)=>{
       this.ramosIdsUsuario = response.data.ramos
      });
    },
    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map(ramo => ramo.id);
      return this.ramosIdsUsuario.some(id => ramosAgente.includes(id));
    },
    getCurrentAlianzaId(){
      var currentAgente = this.agentes.find(
          (e) => e.id == this.polizaData.agente
        );
        this.polizaData.alianzaId = currentAgente ? currentAgente.alianzaId : null
    },
  },
  mounted() {
    this.getCampanias();
    this.getAgentes();
    this.getRamos();
    this.getproducto_id();
    this.calculateDates();
    this.getUsuarios()
    if (this.rol == "AGENT") this.polizaData.agente = localStorage.id;
  },
};
</script>
