<template>
  <div>
    <v-form ref="beneficiario">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            label="Nombre"
            v-model="form.nombre"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Apellido paterno"
            v-model="form.apellido_paterno"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Apellido materno"
            v-model="form.apellido_materno"
            primary
            color="#00a7e4"
            type="text"
            outlined
            :readonly="readOnly"
            :rules="required"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Fecha Nacimiento"
            v-model="form.fecha_nacimiento"
            primary
            color="#00a7e4"
            type="date"
            outlined
            :readonly="readOnly"
            :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Genero"
            :items="generoList"
            item-text="name"
            item-value="id"
            v-model="form.genero"
            primary
            color="#00a7e4"
            type="text"
            :readonly="readOnly"
            outlined
            :rules="required"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Estado Civil"
            placeholder="Requerido"
            :items="['Soltero(a)', 'Casado(a)', 'Unión Libre']"
            color="#00a7e4"
            type="text"
            v-model="form.estado_civil"
            primary
            outlined
            :readonly="readOnly"
            :rules="required"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="RFC"
            v-model="form.rfc"
            placeholder="Requerido"
            v-mask="'XXXX######XXX'"
            color="#00a7e4"
            type="text"
            :readonly="readOnly"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <CommonEmailAutocomplete
            :emailEdit="form.correo"
            :readOnly="readOnly"
            @emailUpdate="(e) => (form.correo = e)"
          >
          </CommonEmailAutocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Teléfono"
            placeholder="##########"
            v-mask="'##########'"
            :rules="required"
            v-model="form.telefono"
            outlined
            primary
            :readonly="readOnly"
            color="#00a7e4"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            label="Parentesco"
            :rules="required"
            :items="parentescoList"
            item-text="valor"
            item-value="valorSegurify"
            v-model="form.parentesco"
            outlined
            :readonly="readOnly"
            primary
            color="#00a7e4"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            label="Porcentaje"
            :rules="[
              required,
              (v) =>
                v <= maxPorcentajePosible ||
                'El valor no puede ser mayor a ' + maxPorcentajePosible,
            ]"
            v-model="form.porcentaje"
            type="number"
            outlined
            primary
            :readonly="readOnly"
            color="#00a7e4"
            :hint="'Puedes ingresar hasta el ' + maxPorcentajePosible + '%'"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import RfcFacil from "rfc-facil";
import moment from "moment";
import { getParentescoList } from "@/services/beneficiarios/benefiario.service.js";

export default {
  components: {
    CommonEmailAutocomplete,
  },
  data: () => {
    return {
      required: [(value) => !!value || "Campo Requerido"],
      dialog: false,
      diferencia_porcentaje: 100,
      form: {
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: null,
        genero: null,
        estado_civil: null,
        rfc: null,
        correo: null,
        telefono: null,
        parentesco: null,
        porcentaje: null,
      },
      generoList: [
        {
          id: "M",
          name: "Masculino",
        },
        {
          id: "F",
          name: "Femenino",
        },
      ],
      parentescoList: [],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC no debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^([A-ZÑ&]{3,4})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es válido, recordar escribir en mayúsculas",
      ],
    };
  },
  props: {
    toSave: {
      type: Boolean,
      default: false,
    },
    currentPorcentaje: {
      type: Number,
      default: 100,
    },
    beneficiarioId: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    beneficioEdicion: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    "form.fecha_nacimiento"() {
      this.actualizarRfc();
    },
    "form.nombre"() {
      this.actualizarRfc();
    },
    "form.apellido_paterno"() {
      this.actualizarRfc();
    },
    "form.apellido_materno"() {
      this.actualizarRfc();
    },
    toSave(val) {
      if (val) {
        this.$refs.beneficiario.validate();
        this.$emit("goTosave", this.$refs.beneficiario.validate(), this.form);
      }
    },
    beneficioEdicion() {
      this.form = { ...this.beneficioEdicion };
    },
  },
  computed: {
    maxPorcentajePosible() {
      if (!this.beneficiarioId) return 100 - this.currentPorcentaje;
      else {
        let restante = 100 - this.currentPorcentaje;
        return Number(this.beneficioEdicion.porcentaje) + Number(restante);
      }
    },
  },
  methods: {
    actualizarRfc() {
      if (
        !this.form.nombre ||
        !this.form.apellido_paterno ||
        !this.form.apellido_materno ||
        !this.form.fecha_nacimiento
      )
        return;
      const arregloNacimiento = this.form.fecha_nacimiento
        .split("-")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.form.nombre,
        firstLastName: this.form.apellido_paterno,
        secondLastName: this.form.apellido_materno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      let edad = this.validateAge();
      if (Number(edad) > 18) this.form.rfc = rfc;
      else this.form.rfc = rfc.substring(0, rfc.length - 3);
    },
    validateAge() {
      const birthDate = moment(this.form.fecha_nacimiento);
      const currentDate = moment();
      const age = currentDate.diff(birthDate, "years");

      return age;
    },
    async getParentescoList() {
      let response = await getParentescoList();
      this.parentescoList = response.data.parentescos
    },
  },
  mounted() {
    this.getParentescoList();
  },
};
</script>
