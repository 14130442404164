var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"background-color":"rgba(247, 248, 250, 1)","padding":"24px"},attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","loading":_vm.loading,"loading-text":"Cargando...","footer-props":{
      'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Elementos por página',
    },"no-data-text":"No hay documentos para mostrar"},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var item = ref.item;
return [_c('v-data-table-col',{key:header.value,attrs:{"value":header.value,"label":header.text}},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}}}),{key:"item.uuid",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.view(item.urlBucket, item.nombre)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.downloadDoc(item.urlBucket)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1)]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","transition":"scale-transition"},model:{value:(_vm.abrirDialog),callback:function ($$v) {_vm.abrirDialog=$$v},expression:"abrirDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.documentName))]),(_vm.isPdf)?_c('v-card-text',{staticClass:"justify-center align-center"},[_c('iframe',{staticStyle:{"height":"70vh"},attrs:{"src":("https://docs.google.com/gview?url=" + (encodeURIComponent(_vm.documentURL)) + "&embedded=true"),"width":"100%"}})]):_vm._e(),(!_vm.isPdf)?_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('img',{attrs:{"src":_vm.documentURL,"max-width":"70%","alt":_vm.documentName}})])],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.cerrarDialog()}}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }