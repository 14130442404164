<template>
  <div>
    <commond-form
      :request="formData"
      :route="`/v1/solicitud/update/${datosSolicitud.uuid}`"
      :run="run"
      :action="'PUT'"
      @cancel="run = false"
      @success="$emit('getData')"
      @afterError="$emit('changeComponent')"
    >
      <template v-slot:data>
        <v-row>
          <v-col m="12" md="6" lg="4" xl="4">
            <v-autocomplete
              label="Tipo de Persona (Régimen)"
              outlined
              v-model="formData.regimen_persona"
              color="#00a7e4"
              type="text"
              :items="regimentList"
              :rules="requiredRule"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="4"
            xl="4"
            v-if="formData.regimen_persona == 'Moral'"
          >
            <v-text-field
              label="Razón Social"
              outlined
              v-model="formData.razon_social"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.nombres"
              label="Nombre(s)"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRuleCaracteres"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.apellidoPaterno"
              label="Apellido Paterno"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRuleCaracteres"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.apellidoMaterno"
              label="Apellido Materno"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="requiredRuleCaracteres"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.fechaNacimiento"
              type="date"
              label="Fecha de Nacimiento"
              outlined
              color="#00a7e4"
              :rules="requiredRuleDate"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-text-field
              v-model="formData.edad"
              type="text"
              label="Edad"
              outlined
              color="#00a7e4"
              :rules="requiredRule"
              readonly
            ></v-text-field>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="3"
            xl="3"
            v-if="formData.regimen_persona != null"
          >
            <v-text-field
              v-model="formData.rfc"
              label="RFC"
              placeholder="Requerido"
              color="#00a7e4"
              type="text"
              outlined
              :rules="rfcRules"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
              @input="onInputToUpper"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Género"
              outlined
              color="#00a7e4"
              v-model="formData.genero"
              :items="generoList"
              :rules="requiredRule"
              :disabled="!canEdit"
              :readonly="deshabilitarInputs()"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" lg="3" xl="3">
            <v-autocomplete
              label="Estado civil"
              outlined
              color="#00a7e4"
              v-model="formData.estadoCivilSelected"
              :items="['Soltero(a)', 'Casado(a)']"
              :rules="requiredRule"
              :disabled="!canEdit"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            style="text-align: end;"
          >
            <v-btn
              rounded
              dark
              color="#00a7e4"
              outlined
              @click="$emit('changeComponent')"
              style="margin-right: 20px;"
            >
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
            <v-btn rounded dark class="common-botton" @click="run = true">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </commond-form>
  </div>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import moment from "moment";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommondForm,
  },

  data() {
    return {
      switch_data: 1,
      solicitudes: [],
      filtros: [],
      loading: false,
      run: false,
      regimentList: ["Física", "Moral"],
      generoList: ["Masculino", "Femenino"],
      formData: {
        nombres: null,
        apellidoPaterno: null,
        apellidoMaterno: null,
        rfc: null,
        genero: null,
        fechaNacimiento: null,
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      roles: JSON.parse(localStorage.getItem("roles")),
      rfcRules: [
        (v) =>
          v.length === 10 ||
          v.length === 12 ||
          v.length === 13 ||
          "RFC debe tener 10, 12 o 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          /^[A-Za-z\d]{10,13}$/.test(v) ||
          "RFC no es válido, asegúrate de tener la longitud correcta y utiliza caracteres alfanuméricos",
      ],
      requiredRuleCaracteres: [
        (v) => !!v || "Este campo es requerido", // Verifica que el campo no esté vacío
        (v) => (v && v.length >= 3) || "Mínimo 3 caracteres", // Mínimo de 3 caracteres
        (v) =>
          /^[a-zA-Z\s]+$/.test(v) || "No se permiten caracteres especiales", // Solo letras y espacios
      ],
      requiredRuleDate: [
        (v) => !!v || "La fecha es requerida.",
        (v) =>
          this.isPastDate(v) ||
          "La fecha no puede ser hoy ni una en el futuro.",
        (v) =>
          this.isWithinLast100Years(v) ||
          "La fecha no puede ser mayor a 100 años.",
      ],
    };
  },
  props: {
    datosSolicitud: Object,
  },
  computed: {
    canEdit() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = [
        "ADMIN",
        "ADMINCC",
        "AGENTCC",
        "DIRECTOR",
        "FINANZAS",
        "MANAGERCC",
        "MESADECONTROL",
        "OPERACIONES",
        "OPERADOR",
        "OPERADORINBOUND",
        "OPERACIONESINBOUND",
        "MESACONTROLINBOUND",
      ];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
  },
  watch: {
    polizaInfo() {
      this.fillData();
    },
    "formData.fechaNacimiento"(val) {
      var today = moment().format("YYYY-MM-DD");
      var birthday = moment(val, "YYYY-MM-DD");
      this.formData.edad = moment(today, "YYYY-MM-DD").diff(
        moment(birthday, "YYYY-MM-DD"),
        "years"
      );
      this.actualizarRfc();
    },
    "formData.nombres"() {
      this.actualizarRfc();
    },
    "formData.apellidoPaterno"() {
      this.actualizarRfc();
    },
    "formData.apellidoMaterno"() {
      this.actualizarRfc();
    },
  },
  methods: {
    fillData() {
      this.formData.nombres = this.datosSolicitud.nombre ?? null;
      this.formData.apellidoPaterno =
        this.datosSolicitud.apellidoPaterno ?? null;
      this.formData.apellidoMaterno =
        this.datosSolicitud.apellidoMaterno ?? null;
      this.formData.genero = this.datosSolicitud.sexo ?? null;
      this.formData.rfc = this.datosSolicitud.rfc ?? null;
      this.formData.fechaNacimiento =
        this.datosSolicitud.fechaNacimiento ?? null;
      this.formData.estadoCivilSelected =
        this.datosSolicitud.estadoCivil ?? null;

      var jsonData = JSON.parse(this.datosSolicitud.body);
      this.formData.regimen_persona = jsonData.regimen_persona
        ? jsonData.regimen_persona
        : null;
      this.formData.razon_social = jsonData.razon_social
        ? jsonData.razon_social
        : null;
      this.formData.edad = jsonData.edad ? jsonData.edad : null;
    },
    deshabilitarInputs() {
      if (
        (this.datosSolicitud.status == "En tramite" &&
          this.datosSolicitud.procesoCotizacion == "Emision") ||
        this.datosSolicitud.status == "Cerrada" ||
        this.datosSolicitud.status == "Cancelada"
      ) {
        return true;
      } else return false;
    },
    actualizarRfc() {
      if (
        !this.formData.nombres ||
        !this.formData.apellidoPaterno ||
        !this.formData.apellidoMaterno ||
        !this.formData.fechaNacimiento
      )
        return;
      const arregloNacimiento = moment(this.formData.fechaNacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.formData.nombres,
        firstLastName: this.formData.apellidoPaterno,
        secondLastName: this.formData.apellidoMaterno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.formData.rfc = rfc;
    },
    onInputToUpper() {
      this.formData.rfc = this.formData.rfc.toUpperCase();
    },
    isPastDate(value) {
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignorar el tiempo para la comparación
      return selectedDate < today;
    },
    isWithinLast100Years(value) {
      const selectedDate = new Date(value);
      const date100YearsAgo = new Date();
      date100YearsAgo.setFullYear(date100YearsAgo.getFullYear() - 100);
      return selectedDate >= date100YearsAgo;
    },
  },
  mounted() {
    this.fillData();
    this.deshabilitarInputs();
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
}
</style>
