<template>
  <div class="pt-5">
    <CommonExpansionPanel>
      <template v-slot:title-panel>
        Datos de Generales
      </template>
      <ViewPolizaDataCard v-if="tabReplace == 1" :polizaInfo="polizaInfo" :ramoData="ramoData"
        @changeTab="tabReplace = 2" />
      <EditPolizaDataCard v-else @getData="$emit('getData'), tabReplace = 1" :polizaInfo="polizaInfo"
        :ramoData="ramoData" @changeTab="tabReplace = 1" />
    </CommonExpansionPanel>
  </div>
</template>

<script>
//import moment from "moment";
// import { mainAxios } from "@/mainAxios.js";
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import ViewPolizaDataCard from "./ViewPolizaDataCard.vue";
import EditPolizaDataCard from "./EditPolizaDataCard.vue";

export default {
  components: {
    CommonExpansionPanel,
    ViewPolizaDataCard,
    EditPolizaDataCard,
  },
  props: {
    polizaInfo: {
      type: Object,
      default: () => { },
    },
    ramoData: {
      type: Object,
      default: () => { },
    },
    productos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      polizaId: this.$route.params.id,
      tabReplace: 1,
    };
  },
  methods: {
    tabReplaceSelect(val) {
      this.tabReplace = val;
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}

.v-expansion-panels {
  z-index: auto !important;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #81cfe61a;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-item-group .theme--light .v-expansion-panels {
  padding: 30px;
  border-radius: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
