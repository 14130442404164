import { render, staticRenderFns } from "./SolicitudesDetalles.vue?vue&type=template&id=b3efd7d4&"
import script from "./SolicitudesDetalles.vue?vue&type=script&lang=js&"
export * from "./SolicitudesDetalles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VExpansionPanelContent,VImg})
