<template>
  <common-card>
    <v-container>
      <v-dialog
        v-model="openEditBannerModal"
        max-width="900px"
        persistent
        attach="body"
      >
        <ModalEditarMarcaAlianza
          :idAliance="alianzaIdFromRoute"
          :nameAlliance="nombreAlianza"
          v-model="openEditBannerModal"
        />
      </v-dialog>
      <v-col cols="12">
        <h2>{{ nombreAlianza }}</h2>
      </v-col>
      <v-row>
        <v-col cols="12" style="position: relative;">
          <v-img
            :src="
              coverImage
                ? coverImage
                : 'https://via.placeholder.com/820x312.png?text='
            "
            class="cover-image"
          ></v-img>
        </v-col>
        <v-row class="align-items-end">
          <v-col cols="auto" style="margin-top: -32px; position: relative;">
            <v-avatar
              size="100"
              class="ml-6"
              :color="color ? color : '#00A5DF'"
            >
              <v-img
                :src="logo ? logo : 'https://via.placeholder.com/.png?text'"
                alt="Logo"
              ></v-img>
            </v-avatar>
          </v-col>
          <v-col class="">
            <div>
              <p>ID {{ this.idParamOrStorage() }}</p>
            </div>
          </v-col>
          <v-col
            cols="auto"
            style="text-align: right;"
            v-if="isAdminAgent || rol == 'AGENTCORP'"
          >
            <v-btn
              class="common-botton-alianza"
              style="right: 30px;"
              dark
              @click="openEditBannerModal = true"
            >
              <span>Editar Perfil</span>
              <v-icon class="responsive-icon ml-2">mdi-pencil</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Asesores"
            :total="countAgente"
            :color="viewScreen === 1 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(1)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Total de prima neta"
            :total="countPrimaNeta"
            :color="viewScreen === 2 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="true"
            @view="changeView(2)"
            :arrow="false"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Cotizaciones"
            :total="countCotizaciones"
            :color="viewScreen === 3 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(3)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Solicitudes"
            :total="countSolicitudes"
            :color="viewScreen === 4 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(4)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Pólizas"
            :total="countEmisiones"
            :color="viewScreen === 5 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(5)"
          />
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <card-dashboard
            title="Clientes"
            :total="countCliente"
            :color="viewScreen === 6 ? '#00A5DF' : '#0070C0'"
            :isTotalPrimaNeta="false"
            @view="changeView(6)"
          />
        </v-col>
      </v-row>

      <AgregarAsesor
        :idAliance="alianzaIdFromRoute"
        @reloadTable="loadTableData"
      />

      <br />

      <busqueda-detalle @update:filter="updateFilter" v-if="viewScreen === 1" />
      <v-row v-if="viewScreen === 1">
        <v-col cols="12">
          <tabla-alianza
            :loading="loading"
            :alianzas="alianza"
            @reloadTable="loadTableData"
          />
        </v-col>
      </v-row>

      <DataAlianzaPanel
        v-if="viewScreen === 1 && isAdminAgent"
        :alianzaData="alianzaData"
      />
      <DocumentosAlianza
        v-if="viewScreen === 1 && isAdminAgent"
        :alianzaData="alianzaData"
      />
      <div></div>

      <v-col>
        <CotizacionesIndex v-if="viewScreen === 3" />
      </v-col>
      <v-col>
        <SolicitudesIndex v-if="viewScreen === 4" />
      </v-col>
      <v-col>
        <PolizasIndex v-if="viewScreen === 5" />
      </v-col>
      <v-col>
        <ClientesIndex v-if="viewScreen === 6" />
      </v-col>
    </v-container>
  </common-card>
</template>
<script>
import { mainAxios } from "@/mainAxios.js";
import CommonCard from "../../../../../components/commonComponents/CommonCard.vue";
import CardDashboard from "./components/CardDashboard.vue";
import BusquedaDetalle from "./components/BusquedaDetalle.vue";
import TablaAlianza from "./components/TablaAlianza.vue";
import CotizacionesIndex from "../../../hub/cotizaciones/Dasboard/CotizacionesIndex.vue";
import SolicitudesIndex from "../../../hub/solicitudes/Dasboard/SolicitudesIndex.vue";
import PolizasIndex from "../../../hub/polizas/Dashboard/PolizasIndex.vue";
import ClientesIndex from "../../../hub/clientes/Dashboard/ClientesIndex.vue";
import AgregarAsesor from "../AgregarAsesor.vue";
import DataAlianzaPanel from "./components/DataAlianzaPanel.vue";
import DocumentosAlianza from "./components/DocumentosAlianza.vue";
import ModalEditarMarcaAlianza from "../ModalEditarMarcaAlianza.vue";

export default {
  components: {
    CommonCard,
    CardDashboard,
    BusquedaDetalle,
    TablaAlianza,
    CotizacionesIndex,
    SolicitudesIndex,
    PolizasIndex,
    ClientesIndex,
    AgregarAsesor,
    DataAlianzaPanel,
    DocumentosAlianza,
    ModalEditarMarcaAlianza,
  },
  name: "PerfilAlianza",
  data() {
    return {
      openEditBannerModal: false,
      openEditLogoModal: false,
      openEditConfigModal: false,
      isAdminAgent: false,
      roles: JSON.parse(localStorage.getItem("roles")),
      loading: false,
      filter: "",
      alianza: [],
      countCotizaciones: "",
      countSolicitudes: "",
      countEmisiones: "",
      countAgente: "",
      countPrimaNeta: "",
      countCliente: "",
      nombreAlianza: "",
      viewScreen: 1,
      coverImage: "",
      logo: "",
      color: "",
      alianzaIdFromRoute: null,
      alianzaData: {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  methods: {
    adminagent() {
      // if a role is this.adminagen, is true
      this.roles.forEach((element) => {
        if (element.rol == "ADMINAGENT") {
          this.isAdminAgent = true;
        }
      });
    },
    loadTableData() {
      this.getListByFilter();
    },
    changeView(view) {
      this.viewScreen = view;
    },
    async getListByFilter() {
      const config = {
        headers: {
          Authorization: "Bearer" + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      try {
        const response = await mainAxios.post(
          `/v1/agente/list-filtro/${this.idParamOrStorage()}`,
          {
            filtroBusqueda: this.filter,
          },
          config
        );
        this.alianza = response.data;
      } catch (error) {
        console.error("error", error);
      } finally {
        this.loading = false;
      }
    },
    updateFilter(value) {
      this.filter = value;
      this.getListByFilter();
    },
    async getCountSolicitudes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/solicitud/count/${this.idParamOrStorage()}`,
          config
        );
        this.countSolicitudes = data.totalSolicitudes;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getCountEmisiones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/emision/count/${this.idParamOrStorage()}`,
          config
        );
        this.countEmisiones = data.totalEmisiones;
      } catch (error) {
        console.error("error", error);
      }
    },
    async getCountCotizaciones() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/cotizacion/count/${this.idParamOrStorage()}`,
          config
        );
        this.countCotizaciones = data.totalCotizaciones;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountAgente() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/agente/count/${this.idParamOrStorage()}`,
          config
        );
        this.countAgente = data.totalAgentes;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountPrimaNeta() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/emision/sum-primas/${this.idParamOrStorage()}`,
          config
        );
        this.countPrimaNeta = data.sumPrimas;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getCountClientes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      try {
        const { data } = await mainAxios.get(
          `/v1/cliente/count/${this.idParamOrStorage()}`,
          config
        );
        this.countCliente = data.totalClientes;
      } catch (error) {
        console.error("error", error);
      }
    },

    async getTenant() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const response = await mainAxios.get(
        `/v1/alianza/id/${this.idParamOrStorage()}`,
        config
      );
      this.coverImage = response.data.coverImage;
      this.logo = response.data.logoImage;
      this.color = response.data.mainColor;
      this.nombreAlianza = response.data.nameAlliance;
      this.alianzaData = response.data;
      localStorage.setItem("mainColorAlianza", this.color);
      localStorage.setItem("allianceLogo", this.logo);
      localStorage.setItem("allianceCover", this.coverImage);
    },

    idParamOrStorage() {
      return this.alianzaIdFromRoute || localStorage.alianzaId;
    },
  },

  async mounted() {
    this.alianzaIdFromRoute = this.$route.params.alianza_id || localStorage.alianzaId;
    // this.isAdminAgent = this.roles === "ADMINAGENT";
    this.adminagent();
    await this.getTenant();
    await this.getCountSolicitudes();
    await this.getCountEmisiones();
    await this.getCountCotizaciones();
    await this.getCountAgente();
    await this.getCountPrimaNeta();
    await this.getCountClientes();
    await this.getListByFilter();
  },
};
</script>

<style scoped>
.cover-image {
  height: 200px;
  background-color: #e0e0e0;
}

.v-avatar {
  border: 4px solid white;
}

h2 {
  font-size: 27px;
}

.responsive-icon {
  font-size: 20px;
}

@media (max-width: 768px) {
  .responsive-icon {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .responsive-icon {
    font-size: 14px;
  }
}
</style>
