var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-5"},[_c('v-data-table',{attrs:{"items":_vm.alianzas,"headers":_vm.headers,"loading":_vm.loading,"no-data-text":"Sin asesores para mostrar","no-results-text":"Sin resultados para mostrar","loading-text":"Cargando...","footer-props":{
      'items-per-page-all-text': 'Todos',
      'items-per-page-text': 'Elementos por página',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticStyle:{"padding":"15px"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h2',[_vm._v("Resultados de la búsqueda")])]),_c('v-col',{staticStyle:{"text-align":"end"},attrs:{"cols":"12","md":"4"}},[_c('download-csv',{attrs:{"data":_vm.columnsReport,"name":"polizas_tabla.csv"}},[_c('v-btn',{attrs:{"color":"transparent","elevation":"0","small":""}},[_c('span',{staticStyle:{"color":"#0070c0"}},[_vm._v("Descargar")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","right":"","color":"#0070C0"}},[_vm._v("mdi-file-pdf-box")])],1)],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.id ? item.id : "Sin ID")+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[(item.status == -1)?_c('div',[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v("Baja")])],1):_vm._e(),(item.status == 0)?_c('div',[_c('v-chip',{attrs:{"color":"blue","dark":""}},[_vm._v("Nuevo")])],1):_vm._e(),(item.status == 1)?_c('div',[_c('v-chip',{attrs:{"color":"#25A249","dark":""}},[_vm._v("Activo")])],1):_vm._e(),(item.status == 2)?_c('div',[_c('v-chip',{attrs:{"color":"#82898E","dark":""}},[_vm._v("Inactivo")])],1):_vm._e()])]}},{key:"item.nombre",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.nombreCompleto ? item.nombreCompleto : "Sin Nombre"))])]}},{key:"item.celular",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.celular ? item.celular : item.telefono))])]}},{key:"item.correoElectronico",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.correo ? item.correo : "Sin Correo"))])]}},{key:"item.fechaRegistro",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.fechaRegistro ? item.fechaRegistro.split(" ")[0] : "Sin Fecha"))])]}},{key:"item.uuid",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"100%"}},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.editItem(item.uuid)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}},{key:"footer.page-text",fn:function(ref){
    var pageStart = ref.pageStart;
    var pageStop = ref.pageStop;
    var itemsLength = ref.itemsLength;
return [(itemsLength)?_c('div',[_vm._v(" Mostrando "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" solicitudes ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }