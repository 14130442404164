import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getProductoList = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

  const serverResponse = await mainAxios.get(
    "/v1/producto/list",
    config
  );
  return serverResponse.data

};