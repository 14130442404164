<template lang="">
  <v-container class="p-4">
    <CommondForm
      :request="contratanteDto"
      :onlyValidations="true"
      :run="run"
      @cancel="run = false"
      @afterValidation="cotizar"
    >
      <template v-slot:data>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="Nombre*"
              v-model.trim="contratanteDto.nombre"
              :rules="nombreRules"
              required
              outlined
              autofocus
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Apellido Paterno*"
              v-model.trim="contratanteDto.aPaterno"
              :rules="apellidoPaternoRules"
              required
              outlined
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Apellido Materno"
              v-model.trim="contratanteDto.aMaterno"
              required
              outlined
              
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <common-email-autocomplete
               
              :emailEdit="contratanteDto.correo"
              @emailUpdate="(e) => (contratanteDto.correo = e)"
            ></common-email-autocomplete>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Código postal*"
              v-model.trim="contratanteDto.cp"
              :rules="cpRules"
              required
              outlined
              maxlength="5"
              type="number"
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Fecha de nacimiento*"
              v-model="contratanteDto.fechanacimiento"
              :rules="requiredRuleDate"
              type="date"
              required
              outlined
              
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              label="Teléfono*"
              v-model.trim="contratanteDto.telefono"
              :rules="telefonoRules"
              required
              outlined
              maxlength="10"
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-label>Género*</v-label>
            <v-radio-group
              v-model="contratanteDto.genero"
              :rules="generoRules"
              
              row
            >
              <v-radio
                label="Masculino"
                value="Masculino"
                color="primary"
              ></v-radio>
              <v-radio
                label="Femenino"
                value="Femenino"
                color="primary"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-label>Selecciona tu tipo de seguro*</v-label>
            <v-radio-group
              v-model="contratanteDto.tipoContratante"
              row
              
              @input="selectTipoContratacion()"
            >
              <v-radio
                label="Individual"
                value="individual"
                color="primary"
              ></v-radio>
              <v-radio
                label="Colectivo"
                value="colectivo"
                color="primary"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :items="items"
              label="Seleciona tu tipo de plan*"
              :rules="tipoDePlanRules"
              outlined
              v-model="tipoSeguroX"
              @change="updateAseguradoras"
              
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="3" xl="3" v-if="!isAgente">
            <v-autocomplete
              label="Agente"
              :items="agentesConRamos"
              item-text="nombre"
              item-value="id"
              v-model="contratanteDto.agente"
              placeholder="Requerido"
              outlined
              color="#00a7e4"
              required
              :rules="AgenteRules"
              
            ></v-autocomplete>
          </v-col>
        </v-row>
        <transition name="slide-fade">
          <aditional-data
            :aseguradosParent="contratanteDto.asegurados"
            :parentescosParent="parentescos"
            :generosParent="generos"
            :contratanteId="contratanteDto.id"
            :isAdmin="isAdmin"
            v-show="contratanteDto.tipoContratante === 'colectivo'"
            @updateAsegurados="updateAsegurados"
            @loading="loading"

          ></aditional-data>
        </transition>
      </template>
    </CommondForm>

    <v-row>
      <v-col col="12">
        <v-row justify="end">
          <v-btn depressed color="#00A7E4" @click="toCotizar" rounded dark 
            >Cotizar</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AditionalData from "./AditionalData.vue";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import { mainAxios } from "@/mainAxios.js";
import CommondForm from "@/components/commonComponents/CommondForm.vue";

export default {
  name: "SegurosMedicosForm",
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    contratante: {
      type: Object,
      required: true,
    },
    tipoSeguro: {
      type: String,
      required: true,
    },
    parentescosParent: {
      type: Array,
      required: true,
    },
    generosParent: {
      type: Array,
      required: true,
    },
  },
  components: {
    AditionalData,
    CommonEmailAutocomplete,
    CommondForm,
  },
  data() {
    return {
      generos: this.generosParent,
      parentescos: this.parentescosParent,
      contratanteDto: { ...this.contratante },
      tipoSeguroX: this.tipoSeguro,
      valid: true,
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      cp: "",
      genero: null,
      tipoDeSeguro: null,
      tipoDePlan: null,
      items: ["Esencial", "Protegido", "Blindado"],
      fechaDeNacimiento: null,
      nombreRules: [(v) => !!v || "Nombre es requerido"],
      apellidoPaternoRules: [(v) => !!v || "Apellido paterno es requerido"],
      generoRules: [(v) => !!v || "Genero es requerido"],
      fechaNacimientoRules: [
        (v) => !!v || "La fecha de nacimiento es requerida",
      ],
      cpRules: [(v) => !!v || "Código postal es requerido"],
      emailRules: [(v) => !!v || "Correo electrónico requerido"],
      tipoDePlanRules: [(v) => !!v || "Tipo de plan es requerido"],
      telefonoRules: [(v) => !!v || "Teléfono requerido"],
      AgenteRules: [(v) => !!v || "Agente es requerido"],
      formularioValido: false,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      agentes: [],
      ramosIdsUsuario: [],
      run: false,
      agente_id: "",
      requiredRuleDate: [
        v => !!v || 'La fecha es requerida.',
        v => this.isPastDate(v) || 'La fecha no puede ser hoy ni una en el futuro.',
        v => this.isWithinLast100Years(v) || 'La fecha no puede ser mayor a 100 años.',
      ],
    };
  },
  methods: {
    selectTipoContratacion() {},
    onSelectedDate(date) {
      this.fechaDeNacimiento = date;
    },
    onAfterValidation(val) {
      console.log(val);
    },
    cotizar(val) {
      this.run = false;
      this.agente_id = this.contratanteDto.agente;
      if (val) this.$emit("cotizar", this.contratanteDto);
    },
    updateAsegurados(aseguradosX) {
      this.contratanteDto.asegurados = aseguradosX;
    },
    updateAseguradoras() {
      this.run = true;
      // this.$emit("planSeleccionado", this.tipoSeguroX, this.contratanteDto);
    },
    loading(show) {
      this.$emit("loading", show);
    },
    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
        .then((response) => {
          this.ramosIdsUsuario = response.data.ramos;
        });
    },
    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map((ramo) => ramo.id);
      return this.ramosIdsUsuario.some((id) => ramosAgente.includes(id));
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.map((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList ? element.canalesList : [],
            ramosList: element.ramosList ? element.ramosList : [],
            alianzaId: element.alianzaId ?? null,
          });
        });
      });
    },
    toCotizar() {
      this.run = true;
    },
    getCurrentAlianzaId() {
      if (this.alianzaId) {
        var currentAgente = this.agentes.find(
          (e) => e.id == this.contratanteDto.agente
        );
        this.contratanteDto.alianzaId = currentAgente
          ? currentAgente.alianzaId
          : null;
      }
    },
    isPastDate(value) {
      const selectedDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignorar el tiempo para la comparación
      return selectedDate < today;
    },
    isWithinLast100Years(value) {
      const selectedDate = new Date(value);
      const date100YearsAgo = new Date();
      date100YearsAgo.setFullYear(date100YearsAgo.getFullYear() - 100);
      return selectedDate >= date100YearsAgo;
    },
  },
  created() {
    this.contratanteDto = { ...this.contratante };
    this.tipoSeguroX = this.tipoSeguro;
    this.generos = this.generosParent;
    this.parentescos = this.parentescosParent;
  },
  computed: {
    validarFormulario() {
      const camposAValidar = [
        this.contratanteDto.nombre,
        this.contratanteDto.aPaterno,
        this.contratanteDto.correo,
        this.contratanteDto.cp,
        this.contratanteDto.fechanacimiento,
        this.contratanteDto.telefono,
        this.contratanteDto.genero,
        this.contratanteDto.agente,
      ];
      let validAseguradoIncompleto = true;
      if (this.contratanteDto.asegurados.length > 0) {
        for (let i = 0; i < this.contratanteDto.asegurados.length; i++) {
          const aseguradoValid = [
            this.contratanteDto.asegurados[i].nombre,
            this.contratanteDto.asegurados[i].cp,
            this.contratanteDto.asegurados[i].fechanacimiento,
            this.contratanteDto.asegurados[i].parentesco,
            this.contratanteDto.asegurados[i].genero,
            this.contratanteDto.asegurados[i].agente,
          ];
          validAseguradoIncompleto = aseguradoValid.every((campo) => !!campo);
          if (!validAseguradoIncompleto) {
            break;
          }
        }
      }

      return (
        validAseguradoIncompleto && camposAValidar.every((campo) => !!campo)
      );
    },
    agentesConRamos() {
      return this.agentes.filter((agente) => this.tieneRamos(agente));
    },
    isAgente() {
      return this.rol == "AGENT";
    },
  },
  watch: {
    "contratanteDto.tipoContratante": async function(nuevoValor) {
      if (nuevoValor === "colectivo") {
        if (
          this.contratanteDto.id.length === 0 &&
          this.contratanteDto.asegurados.length === 0
        ) {
          this.contratanteDto.asegurados = [];
          this.contratanteDto.asegurados.push({
            cp: this.contratanteDto.cp,
            fechanacimiento: this.contratanteDto.fechanacimiento,
            nombre:
              this.contratanteDto.nombre +
              " " +
              this.contratanteDto.aPaterno +
              " " +
              (this.contratanteDto.aMaterno !== ""
                ? this.contratanteDto.aMaterno
                : ""),
            genero: this.contratanteDto.genero,
            indice: 0,
            parentesco: "Titular",
            titular: true,
            edit: true,
          });
        }
      } else {
        if (
          this.contratanteDto.id === undefined &&
          this.contratanteDto.id.length === 0
        ) {
          this.contratanteDto.asegurados = [];
        }
      }
    },
    parentescosParent: function(nuevoValor) {
      this.parentescos = nuevoValor;
    },
    generosParent: function(nuevoValor) {
      this.generos = nuevoValor;
    },
    contratante: function(nuevoObjeto) {
      this.contratanteDto = nuevoObjeto;
      if (this.agente_id) this.contratanteDto.agente = this.agente_id;
    },
    "contratanteDto.agente"() {
      this.getCurrentAlianzaId();
    },
  },
  mounted() {
    this.getAgentes();
    this.getUsuarios();
    if (this.rol == "AGENT") {
      this.contratanteDto.agente = localStorage.id;
      this.contratanteDto.alianzaId = localStorage.alianzaId;
    }
  },
};
</script>
<style scoped>
.v-btn {
  padding: 20px 70px 20px 70px !important;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active en <2.1.8 */ {
  opacity: 0;
  transform: translateY(
    -20px
  ); /* Ajusta la distancia de deslizamiento hacia abajo */
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s;
}
.slide-down-enter, .slide-down-leave-to /* .slide-down-leave-active en <2.1.8 */ {
  transform: translateY(-100%); /* Inicialmente fuera de la pantalla */
}
</style>
