<template>
  <div>
    <div style="padding-top: 60px;">
      <ClienteSolcitudInfo
        :cliente_id="cliente_id"
        :datosSolicitud="datosSolicitud"
        @getData="$emit('getData')"
      />
    </div>
    <div class="pt-5">
      <ContactClienteSolcitudInfo
        :datosSolicitud="datosSolicitud"
        @getData="$emit('getData')"
      />
    </div>
    <div class="pt-5">
      <DomicilioSolcitudInfo
        :datosSolicitud="datosSolicitud"
        @getData="$emit('getData')"
      />
    </div>
    <div>
      <EmbeddedCliente
        v-if="
          rol == 'MESACONTROLINBOUND' ||
            rol == 'OPERADORINBOUND' ||
            rol == 'OPERACIONESINBOUND' ||
            rol == 'ADMIN'
        "
        :telefonos="telefonos"
        :cliente_id="cliente_id"
        :is_solicitud="true"
      />
    </div>
  </div>
</template>
 
<script>
import ClienteSolcitudInfo from "@/views/pages/hub/solicitudes/Details/ClienteSolicitudInfo/ClienteSolcitudInfo.vue";
import ContactClienteSolcitudInfo from "@/views/pages/hub/solicitudes/Details/ContactClienteSolicitudInfo/ContactClienteSolcitudInfo.vue";
import DomicilioSolcitudInfo from "@/views/pages/hub/solicitudes/Details/ClienteDomicilioInfo/DomicilioSolcitudInfo.vue";
import { getClienteById } from "@/services/cliente/Clientes.js";
import { putUpdateSolicitudesByuuid } from "@/services/solicitudes/Solicitudes.js";
import moment from "moment";
export default {
  components: {
    ClienteSolcitudInfo,
    ContactClienteSolcitudInfo,
    DomicilioSolcitudInfo,
    EmbeddedCliente: () =>
      import("@/components/commonComponents//EmbeddedCliente.vue"),
  },
  data() {
    return {
      clienteInfo: {},
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },
  props: {
    telefonos: {
      type: Array,
      value: () => [],
    },
    datosSolicitud: {
      type: Object,
      value: () => ({}),
    },
  },
  watch: {
    "datosSolicitud.clienteId": {
      handler(newVal) {
        if (newVal) {
          this.getClienteById();
        } else if (
          newVal == 0 ||
          newVal == null ||
          typeof newVal != "undefined"
        ) {
          console.log("si es putisimo null");
        }
      },
      immediate: true,
    },
  },
  computed: {
    cliente_id() {
      if (this.datosSolicitud) return this.datosSolicitud.clienteId;
      else return null;
    },
  },
  methods: {
    async getClienteById() {
      if (this.datosSolicitud.clienteId) {
        let response = await getClienteById(this.datosSolicitud.clienteId);
        this.getFormatCliente(response);
        console.log({ response });
      }
    },
    getFormatCliente(clienteInfo) {
      this.datosSolicitud.nombre =
        this.datosSolicitud.nombre ?? clienteInfo.nombre ?? null;
      this.datosSolicitud.apellidoPaterno =
        this.datosSolicitud.apellidoPaterno ??
        clienteInfo.apellidoPaterno ?? null;
      this.datosSolicitud.apellidoMaterno =
        this.datosSolicitud.apellidoMaterno ??
        clienteInfo.apellidoMaterno ?? null;
      this.datosSolicitud.sexo =
        this.datosSolicitud.sexo ??
        (clienteInfo.genero
          ? clienteInfo.genero == "M"
            ? "Masculino"
            : "Femenino"
          : null);
      this.datosSolicitud.rfc = clienteInfo.rfc ?? clienteInfo.rfc ?? null;
      this.datosSolicitud.fechaNacimiento =
        clienteInfo.fechaNacimiento ?? clienteInfo.fechaNacimiento ?? null;
      this.datosSolicitud.estadoCivil =
        clienteInfo.estadoCivil ?? clienteInfo.estadoCivil ?? null;
      this.datosSolicitud.edad =
        this.datosSolicitud.edad ??
        this.getEdad(clienteInfo.fechaNacimiento) ??
        null;
      this.datosSolicitud.regimen_persona =
        this.datosSolicitud.regimen_persona ?? clienteInfo.regimen ?? null;
      this.datosSolicitud.celular =
        this.datosSolicitud.celular ?? clienteInfo.telefonoMovil ?? null;
    
        console.log(this.body)
      let body = JSON.parse(this.datosSolicitud.body)
      if(body && !body.clienteWasUpdated)
        this.updateClienteOnSolicitud(clienteInfo)
    },
    getEdad(date) {
      console.log(date);
      const birthdayDate = moment(date);
      const today = moment();
      const years = today.diff(birthdayDate, "years");
      return years;
    },
    async updateClienteOnSolicitud(clienteInfo){
      let request = {
        nombres: clienteInfo.nombre ?? null,
        apellidoPaterno: clienteInfo.apellidoPaterno ?? null,
        apellidoMaterno: clienteInfo.apellidoMaterno ?? null,
        sexo:(clienteInfo.genero ? (clienteInfo.genero == "M" ? "Masculino" : "Femenino") : null),
        rfc:clienteInfo.rfc ?? null,
        fechaNacimiento:clienteInfo.fechaNacimiento ?? null,
        estadoCivil:clienteInfo.estadoCivil ?? null,
        edad: this.getEdad(clienteInfo.fechaNacimiento) ?? null,
        regimen_persona: clienteInfo.regimen ?? null,
        celular: clienteInfo.telefonoMovil ?? null,
        clienteWasUpdated: true
      }
      await putUpdateSolicitudesByuuid(this.datosSolicitud.uuid, request)
      this.$toast("Informacion del Cliente recuperada...")
    },  
    // async createUsuario() {
    //   let request = {
    //     agenteId: 700, //Valor por defecto para QA y PROD
    //     apellidoMaterno: null,
    //     apellidoPaterno: null,
    //     celular: null,
    //     correoElectronico: null,
    //     fechaNacimiento: null, //yyyy-mm-dd
    //     nombres: null,
    //     genero: null, // Femenino o Masculino
    //     regimen: null, //Moral o Físico
    //     telefonoOficina: null, // puede ser el mismo que el celular? Si si puede ser el mismo
    //     estadoCivil: null, //'Soltero(a)', 'Casado(a)', 'Unión Libre' Este es el catalogo actual
    //     rfc: null, //Debe de ser formato valido a 10 o 13 digitos        
    //   };
    //   request.map((e) => {
    //     this.
    //   })
    // },
  },
};
</script>
