<template>
  <CommonExpansionPanel :currentPanel="currentPanel">
    <template v-slot:title-panel>
      Datos generales
    </template>
    <v-expansion-panel-content>
      <v-form ref="firstStepForm" :lazy-validation="true">
        <v-row class="justify-end">
          <v-col cols="10"> </v-col>
          <v-col
            cols="2"
            class="p-6"
            v-if="data.producto != 'Llamada colgada/incompleta'"
          >
            <v-switch
              v-model="llamadaSeguimiento"
              label="Mostrar todas tipificaciones"
              hide-details
              inset
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" m="12" md="6" xl="3">
            <v-text-field
              label="Medio de contacto"
              outlined
              v-model="data.tipo_llamada"
              primary
              disabled
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col m="12" md="6" xl="3">
            <v-autocomplete
              label="Producto"
              outlined
              v-model="data.producto"
              item-value="campania"
              item-text="campania"
              :items="currentProducts"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col m="12" md="6" xl="3" v-if="data.producto == 'Seguimiento'">
            <v-autocomplete
              v-if="currentSolicitudes > 0"
              label="Solicitud"
              outlined
              v-model="data.solicitud_id"
              item-value="id"
              item-text="id"
              :items="solicitudes"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-row>
                    <v-col cols="8">
                      <v-list-item-content>
                        <v-list-item-title>
                          Solicitud #{{ item.id }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-row>
                            <v-col cols="8">
                              <span>Estatus: {{ item.status }}</span>
                            </v-col>
                          </v-row>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-col>
                    <v-col cols="4" class="d-flex align-center justify-center">
                      <v-btn icon small @click="openSolicitudView(item.id)">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item>
              </template>
              <template v-slot:append>
                <v-btn
                  icon
                  small
                  @click="openSolicitudView(data.solicitud_id)"
                  v-if="data.solicitud_id != ''"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-autocomplete>
            <v-text-field
              v-else
              label="Solicitud"
              v-model="data.solicitud_id"
              outlined
              readonly
              :rules="requiredRule"
            >
              <template v-slot:append>
                <v-btn icon small @click="dialog = true">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="data.producto == 'Llamada colgada/incompleta'"
          >
            <v-autocomplete
              label="Tipificación"
              outlined
              v-model="data.tipificacion"
              primary
              :items="[
                'Se corta llamada',
                'No se escucha',
                'Llamada equivocada',
                'Cliente Cuelga',
                'Otro',
              ]"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="
              data.producto &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-autocomplete
              label="Empresa"
              outlined
              v-model="data.empresa"
              primary
              item-value="empresa"
              item-text="empresa"
              :items="currentEmpresas"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-autocomplete
              label="Aseguradora"
              outlined
              v-model="data.aseguradora"
              primary
              item-value="id"
              item-text="name"
              :items="currentAseguradoras"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-autocomplete
              label="Tipificación"
              outlined
              v-model="data.tipificacion"
              primary
              item-value="tipificacion"
              item-text="tipificacion"
              :items="filtertTipificaciones"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-autocomplete
              label="Motivo"
              outlined
              v-model="data.disposicion"
              primary
              item-value="motivo"
              item-text="motivo"
              :items="filtertMotivos"
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col
            m="12"
            md="6"
            xl="6"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <v-text-field
              label="Póliza"
              outlined
              v-model="data.poliza"
              primary
              color="#00a7e4"
              type="text"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>

          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion &&
                data.poliza &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <span class="text-header-data whitout-padding-bottom ">
              ¿Atendio en llamada?
            </span>
            <v-radio-group
              v-model="data.atendioLlamada"
              row
              :rules="requiredRule"
            >
              <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
              <v-radio label="No" value="false" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            class="whitout-padding-bottom"
            cols="12"
            sm="6"
            md="6"
            xl="3"
            v-if="
              data.empresa &&
                data.producto &&
                data.aseguradora &&
                data.tipificacion &&
                data.disposicion &&
                data.poliza &&
                data.producto != 'Seguimiento' &&
                data.producto != 'Llamada colgada/incompleta'
            "
          >
            <span class="text-header-data whitout-padding-bottom ">
              ¿Es queja?
            </span>
            <v-radio-group v-model="data.isQueja" row :rules="requiredRule">
              <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
              <v-radio label="No" value="false" color="#00a7e4"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            m="12"
            md="6"
            lg="12"
            xl="12"
            v-if="data.producto != 'Llamada colgada/incompleta'"
          >
            <v-text-field
              label="Detalle"
              outlined
              v-model="data.detalle"
              primary
              color="#00a7e4"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

      <v-row style="text-align: end;">
        <v-col
          cols="12"
          v-if="
            data.producto == 'Llamada colgada/incompleta' ||
              data.producto == 'Seguimiento'
          "
        >
          <v-btn
            rounded
            dark
            class="common-botton"
            @click="goToSaveLlamadaCortada"
          >
            <span>
              Guardar
            </span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          v-if="
            data.producto != 'Llamada colgada/incompleta' &&
              data.producto != 'Seguimiento'
          "
        >
          <v-btn rounded dark class="common-botton" @click="validateStep">
            <span>
              Siguiente
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <SolcitudesFiltersModal
      @closeModal="dialog = false"
      :dialog="dialog"
      @seleccionSolicitud="seleccionSolicitud"
    />
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "../../../commonComponents/CommonExpansionPanel.vue";
import { getCampañasInbound } from "@/services/campañasInbound/CampañasInbound.js";
import { getSolicitudByClienteID } from "@/services/cliente/Clientes.js";
import SolcitudesFiltersModal from "../../../commonComponents/dialogs/SolcitudesFiltersModal.vue";

export default {
  components: {
    CommonExpansionPanel,
    SolcitudesFiltersModal,
  },
  props: {
    currentPanel: {
      type: Number,
      default: 0,
    },
    cliente: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      data: {
        tipo_llamada: "Inbound",
        producto: "",
        empresa: "",
        tipificacion: "",
        aseguradora: "",
        poliza: "",
        disposicion: "",
        atendioLlamada: "",
        detalle: "",
        servicio: "",
        isQueja: "",
        agente_id: "",
        solicitud_id: "",
      },
      requiredRule: [(v) => !!v || "El campo es requerido"],
      campañas: [],
      currentEmpresas: [],
      filtertAseguradoras: [],
      filtertTipificaciones: [],
      filtertMotivos: [],
      aseguradorasList: [
        { id: 1, name: "Afirme" },
        { id: 2, name: "ANA" },
        { id: 3, name: "GNP" },
        { id: 4, name: "Primero" },
        { id: 5, name: "Qualitas" },
        { id: 6, name: "Multiva" },
        { id: 7, name: "Sura" },
        { id: 8, name: "AIG" },
        { id: 9, name: "HDI" },
        { id: 10, name: "Zurich" },
        { id: 11, name: "AXA" },
        { id: 12, name: "Banorte" },
      ],
      llamadaSeguimiento: false,
      solicitudes: [],
      dialog: false,
    };
  },
  computed: {
    currentAseguradoras() {
      let aseguradoras = [];
      this.filtertAseguradoras.forEach((a) => {
        const filteredAseguradoras = this.aseguradorasList.filter(
          (e) => e.id == a.aseguradoraId
        );
        aseguradoras = aseguradoras.concat(filteredAseguradoras);
      });
      return aseguradoras;
    },
    currentProducts() {
      var uniqueProducts = [];
      var filteredCampaigns = this.campañas;
      if (this.currentService && !this.llamadaSeguimiento) {
        console.log("Entro aqui", this.campañas);
        filteredCampaigns = [];
        filteredCampaigns = this.campañas.filter(
          (e) => e.servicioIvr == this.currentService
        );
      }
      uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.campania))
      ).map((campania) => {
        return filteredCampaigns.find((a) => a.campania === campania);
      });

      uniqueProducts.push({
        campania: "Seguimiento",
      });

      uniqueProducts.push({
        campania: "Llamada colgada/incompleta",
      });

      return uniqueProducts;
    },
    currentService() {
      var service = JSON.parse(localStorage.getItem("jsonLlamada"));
      return service ? service.service : "";
    },
    currentSolicitudes() {
      return this.solicitudes.length;
    },
  },
  watch: {
    "data.producto"(val) {
      this.calculateEmpresas();
      if (val == "Seguimiento" || val == "Llamada colgada/incompleta") {
        this.data.disposicion = val;
        this.$emit("noNeedShowClienteData");
      } else this.$emit("needShowClienteData");
    },
    "data.empresa"() {
      this.calculateAseguradora();
    },
    "data.aseguradora"() {
      this.calculateTipificaciones();
    },
    "data.tipificacion"() {
      this.calculateMotivos();
    },
    "data.disposicion"() {
      this.calculateCurrentCampania();
    },
    llamadaSeguimiento() {
      Object.assign(this.data, {
        tipo_llamada: "Inbound",
        producto: "",
        empresa: "",
        tipificacion: "",
        aseguradora: "",
        poliza: "",
        disposicion: "",
        atendioLlamada: "",
        detalle: "",
        servicio: "",
        isQueja: "",
        categoriaSelected:""
      });
    },
    cliente(val) {
      if (typeof val.id != "undefined") {
        this.getSolicitudesCliente();
      }
    },
  },
  methods: {
    async getCampañas() {
      this.campañas = await getCampañasInbound();
    },
    calculateEmpresas() {
      const jsonLlamada = JSON.parse(localStorage.getItem("jsonLlamada"));
      let filteredCampaigns = !this.llamadaSeguimiento
        ? this.campañas.filter(
            (e) =>
              e.campania == this.data.producto &&
              e.servicioIvr == jsonLlamada.service
          )
        : this.campañas.filter((e) => e.campania == this.data.producto);
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.empresa))
      ).map((empresa) => {
        return filteredCampaigns.find((a) => a.empresa === empresa);
      });

      this.currentEmpresas = uniqueProducts;
    },
    calculateAseguradora() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto && e.empresa == this.data.empresa
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.aseguradoraId))
      ).map((aseguradoraId) => {
        return filteredCampaigns.find((a) => a.aseguradoraId === aseguradoraId);
      });
      this.filtertAseguradoras = uniqueProducts;
    },
    calculateTipificaciones() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto &&
          e.empresa == this.data.empresa &&
          e.aseguradoraId == this.data.aseguradora
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.tipificacion))
      ).map((tipificacion) => {
        return filteredCampaigns.find((a) => a.tipificacion === tipificacion);
      });
      this.filtertTipificaciones = uniqueProducts;
    },
    calculateMotivos() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto &&
          e.empresa == this.data.empresa &&
          e.aseguradoraId == this.data.aseguradora &&
          e.tipificacion == this.data.tipificacion
      );
      let uniqueProducts = Array.from(
        new Set(filteredCampaigns.map((a) => a.motivo))
      ).map((motivo) => {
        return filteredCampaigns.find((a) => a.motivo === motivo);
      });
      this.filtertMotivos = uniqueProducts;
    },
    calculateCurrentCampania() {
      let filteredCampaigns = this.campañas.filter(
        (e) =>
          e.campania == this.data.producto &&
          e.empresa == this.data.empresa &&
          e.aseguradoraId == this.data.aseguradora &&
          e.tipificacion == this.data.tipificacion &&
          e.motivo == this.data.disposicion
      );
      this.data.agente_id = filteredCampaigns[0]
        ? filteredCampaigns[0].agenteId ?? ""
        : "";
      this.data.categoriaSelected = filteredCampaigns[0]
        ? filteredCampaigns[0].ramo ?? ""
        : "";
    },
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("nextStep", this.data);
      }
    },
    goToSaveLlamadaCortada() {
      if (this.$refs.firstStepForm.validate()) {
        this.$emit("goToSaveLlamadaCortada", this.data);
      }
    },
    async getSolicitudesCliente() {
      if (typeof this.cliente.id != "undefined")
        this.solicitudes = await getSolicitudByClienteID(this.cliente.id);
    },
    openSolicitudView(id) {
      const url = `/detalles-solicitud/${id}`;
      window.open(url);
    },
    seleccionSolicitud(val) {
      this.data.solicitud_id = val;
      this.dialog = false
    },
  },
  async mounted() {
    const jsonLlamada = JSON.parse(localStorage.getItem("jsonLlamada"));
    this.data.servicio = jsonLlamada.service;
    await this.getCampañas();
    await this.getSolicitudesCliente();
  },
};
</script>
