<template>
  <v-container>
    <v-row>
      <v-col class="col-12" md="6">
        <span class="gray mb-32">
          ¿Qué tipo de cuatripatitas tienes en casa?
        </span>
        <v-radio-group v-model="initCotizacion.tipoMascota" row :rules="requiredRule" :disabled="isAdmin">
          <v-radio v-for="(opc, index) in species" :key="index + '-specie'" :label="opc.txt_desc_especie"
            :value="opc"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="col-12 mb-32" md="6">
        <v-text-field v-model.trim="initCotizacion.nombreMascota" label="Nombre de tu mascota*" outlined autofocus
          :rules="requiredRule" :disabled="isAdmin"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field v-model.trim="initCotizacion.nombreDuexo" label="Nombre*" outlined :rules="requiredRule"
          :disabled="isAdmin"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field v-model.trim="initCotizacion.apellidoPaterno" label="Apellido Paterno*" outlined
          :rules="requiredRule" :disabled="isAdmin"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <v-text-field v-model.trim="initCotizacion.apellidoMaterno" label="Apellido Materno" outlined
          :disabled="isAdmin"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" class="mb-32">
        <common-email-autocomplete :emailEdit="initCotizacion.correo" @emailUpdate="(e) => (initCotizacion.correo = e)"
          :disabled="isAdmin"></common-email-autocomplete>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field v-model.trim="initCotizacion.telefono" label="Número telefónico*" type="tel" outlined
          maxlength="10" :rules="requiredRule" :disabled="isAdmin"></v-text-field>
      </v-col>
      <v-col cols="12" md="4" v-if="!isAgente">
        <v-autocomplete label="Agente" :items="agentesConRamos" item-text="nombre" item-value="id"
          v-model="initCotizacion.agenteId" placeholder="Requerido" outlined color="#00a7e4" :rules="requiredRule"
          :disabled="isAdmin"></v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";

export default {
  name: "FormularioMascota",
  props: {
    initCotizacion: Object,
    speciesParent: Array,
  },

  components: {
    CommonEmailAutocomplete,
  },
  data() {
    return {
      species: this.speciesParent,
      requiredRule: [(v) => !!v || "El campo es requerido"],
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      agentes: [],
      ramosIdsUsuario: [],
    };
  },
  methods: {
    async initLoginInter() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      const response = await mainAxios.get("/v1/mascota/init", config);
      sessionStorage.interToken = response.data.data.accessToken;
      return true;
    },
    getUsuarios() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios
        .get(`/v1/agente/uuid/${localStorage.agenteUuid}`, config)
        .then((response) => {
          this.ramosIdsUsuario = response.data.ramos;
        });
    },
    tieneRamos(agente) {
      const ramosAgente = agente.ramosList.map((ramo) => ramo.id);
      return this.ramosIdsUsuario.some((id) => ramosAgente.includes(id));
    },
    getAgentes() {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      mainAxios.get("/v1/solicitud/agente/list", config).then((response) => {
        response.data.forEach((element) => {
          this.agentes.push({
            nombre: element.nombre,
            id: element.id,
            uuid: element.uuid,
            canalList: element.canalesList || [],
            ramosList: element.ramosList || [],
            alianzaId: element.alianzaId || null,
          });
        });
      });
    },
    getCurrentAlianzaId() {
      if (!this.initCotizacion.alianzaId) {
        const currentAgente = this.agentes.find(
          (e) => e.id === this.initCotizacion.agenteId
        );
        this.initCotizacion.alianzaId = currentAgente
          ? currentAgente.alianzaId
          : null;
      }
    },
  },
  created() {
    this.species = this.speciesParent;
  },
  watch: {
    speciesParent(newSpecies) {
      this.species = newSpecies;
    },
    "initCotizacion.agenteId"() {
      this.getCurrentAlianzaId();
    },
  },
  computed: {
    agentesConRamos() {
      return this.agentes.filter((agente) => this.tieneRamos(agente));
    },
    isAgente() {
      return this.rol === "AGENT";
    },
    //Regresa true si el usuario es un agente
    isAdmin() {
      return this.rol === "ADMINAGENT" || this.rol === "AGENTCORP";
    },
  },
  mounted() {
    this.getAgentes();
    this.getUsuarios();
    if (this.rol === "AGENT") {
      this.initCotizacion.agenteId = localStorage.id;
      this.initCotizacion.alianzaId = localStorage.alianzaId;
    }
  },
};
</script>

<style scoped></style>
