<template>
  <v-dialog
    v-model="dialog"
    v-if="dialog"
    persistent
    max-width="1500"
    :scrollable="true"
  >
    <v-card class="pa-0" style="height: 100%;">
      <v-card-title>
        <span class="text-h5">
          {{ readOnly ? "Ver " : beneficiarioId ? "Editar " : "Crear " }}
          Beneficiario</span
        >
      </v-card-title>
      <v-card-text style="padding: 20px;">
        <CreateBeneficiarioForm
          :toSave="toSave"
          :beneficioEdicion="beneficioEdicion"
          :beneficiarioId="beneficiarioId"
          :readOnly="readOnly"
          @goTosave="goTosave"
          :currentPorcentaje="currentPorcentaje"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded color="#00a7e4" @click="$emit('closeModal')">
          <h4>
            Cerrar
          </h4>
        </v-btn>
        <v-btn
          color="#00a7e4"
          class="common-botton"
          @click="toSave = true"
          v-if="!readOnly"
        >
          <h4 style="color: white;">
            Guardar
          </h4>
        </v-btn>
      </v-card-actions>
      <CommonActionModal
        :dialog="dialogEleccion"
        title="Creación de Beneficiario"
        subtitle="¿Deseas crear el beneficiario?"
        bottonText="Si, crealo"
        @userChoice="postPutBeneficiario"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CreateBeneficiarioForm from "@/views/pages/hub/beneficiarios/Create/CreateBeneficiarioForm.vue";
import CommonActionModal from "@/components/commonComponents/CommonActionModal.vue";
import {
  postCrearBeneficiario,
  getBeneficiarioById,
  putEditarBeneficiario,
} from "@/services/beneficiarios/benefiario.service.js";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    emision_id: {
      type: String,
      default: null,
    },
    beneficiarioId: {
      type: Number,
      default: null,
    },
    currentPorcentaje: {
      type: Number,
      default: 100,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    solicitud_id: {
      type: String,
      default: null,
    },
  },
  components: {
    CreateBeneficiarioForm,
    CommonActionModal,
  },
  data() {
    return {
      toSave: false,
      dialogEleccion: false,
      form: {},
      beneficioEdicion: {},
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        return this.getBeneficiarioById();
      }
    },
  },
  methods: {
    goTosave(result, form) {
      if (result) this.dialogEleccion = true;
      this.form = form;
      this.toSave = false;
    },
    async getBeneficiarioById() {
      if (this.beneficiarioId) {
        const beneficiario = await getBeneficiarioById(this.beneficiarioId);
        this.beneficioEdicion = {
          nombre: beneficiario.data.beneficiario.nombre,
          apellido_paterno: beneficiario.data.beneficiario.apellidoPaterno,
          apellido_materno: beneficiario.data.beneficiario.apellidoMaterno,
          fecha_nacimiento: beneficiario.data.beneficiario.fechaNacimiento,
          genero: beneficiario.data.beneficiario.genero,
          estado_civil: beneficiario.data.beneficiario.estadoCivil,
          rfc: beneficiario.data.beneficiario.rfc,
          correo: beneficiario.data.beneficiario.correo,
          telefono: beneficiario.data.beneficiario.telefono,
          parentesco: String(beneficiario.data.beneficiario.parentesco),
          porcentaje: beneficiario.data.beneficiario.porcentaje,        };
      }
    },
    postPutBeneficiario(val) {
      this.dialogEleccion = false;
      if (val) {
        if (!this.beneficiarioId) this.postCrearBeneficiario();
        else this.putEditarBeneficiario();
      }
    },
    async postCrearBeneficiario() {
      const finalform = {
        ...this.form,
        ...(this.emision_id && this.emision_id != null && this.emision_id != '' &&  { emision_id: Number(this.emision_id) }),
        ...(this.solicitud_id  && this.solicitud_id != null && this.solicitud_id != '' && { solicitud_id: Number(this.solicitud_id) }),
        porcentaje: Number(this.form.porcentaje),
        parentesco: Number(this.form.parentesco),
      };
      
      try {
        const response = await postCrearBeneficiario(finalform);
        if (response.success) {
          this.$toast.success("Beneficiario creado con éxito");
          this.$emit("closeModal");
        } else {
          this.$toast.error(
            response.message || "Ocurrió un error al crear el beneficiario"
          );
          this.dialogEleccion = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
        this.dialogEleccion = false;
      }
    },
    async putEditarBeneficiario() {
      const finalform = {
        ...this.form,
        ...(this.emision_id && this.emision_id != null && this.emision_id != '' &&  { emision_id: Number(this.emision_id) }),
        ...(this.solicitud_id  && this.solicitud_id != null && this.solicitud_id != '' && { solicitud_id: Number(this.solicitud_id) }),
        porcentaje: Number(this.form.porcentaje),
        parentesco: Number(this.form.parentesco),
      };
      try {
        const response = await putEditarBeneficiario(
          this.beneficiarioId,
          finalform
        );
        if (response.success) {
          this.$toast.success("Beneficiario editado con éxito");
          this.$emit("getBeneficiarios");
        } else {
          this.$toast.error(
            response.message || "Ocurrió un error al crear el beneficiario"
          );
          this.dialogEleccion = false;
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
        this.dialogEleccion = false;
      }
    },
  },
};
</script>
