import { mainAxios } from "../../mainAxios";
import { getDefaultHeaders, getErrorMessage } from "../mainService";

export const getCampañasInbound = async () => {
  try {
    const response = await mainAxios.post(
      `v1/campaniasInboundEci/list`,
      {},
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
 

export const createLlamadaInbound = async (request) => {
  try {
    const response = await mainAxios.post(
      `v1/accion/cliente/crear`,
      request,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

// Posibles módulos al momento ( solicitud, emision, cliente)
export const getLlamadasInbound = async (module, module_id) => {
  try {
    const response = await mainAxios.post(
      `v1/acciones/${module}/list/${module_id}`,
      {},
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const getLlamadaDetalles = async (accion_id) => {
  try {
    const response = await mainAxios.get(
      `v1/accion/cliente/id/${accion_id}`,
      getDefaultHeaders()
    );

    return response.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};