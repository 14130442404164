<template>
  <div class="pt-5">
    <CommonExpansionPanel :currentPanel="currentPanel" :readonly="readonly">
      <template v-slot:title-panel>
        Datos del cliente
      </template>
      <v-expansion-panel-content>
        <v-form ref="firstStepForm" :lazy-validation="true">
          <v-row>
            <v-col m="12" md="6" lg="4" xl="4">
              <v-autocomplete
                label="Tipo de Persona (Régimen)"
                outlined
                v-model="polizaData.tipo_persona"
                color="#00a7e4"
                type="text"
                :items="regimentList"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="4"
              xl="4"
              v-if="polizaData.tipo_persona == 'Moral'"
            >
              <v-text-field
                label="Razón Social"
                outlined
                v-model="polizaData.razon_social"
                color="#00a7e4"
                type="text"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="polizaData.tipo_persona != null">
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.nombre"
                label="Nombre(s)"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.apellido_paterno"
                label="Apellido Paterno"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.apellido_materno"
                label="Apellido Materno"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.fecha_nacimiento"
                type="date"
                label="Fecha de Nacimiento"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.edad"
                type="text"
                label="Edad"
                outlined
                color="#00a7e4"
                :rules="requiredRule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.tipo_persona != null"
            >
              <v-text-field
                v-model="polizaData.rfc"
                label="RFC"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="rfcRules"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Género"
                outlined
                color="#00a7e4"
                v-model="polizaData.genero"
                :items="generoList"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-autocomplete
                label="Estado civil"
                outlined
                color="#00a7e4"
                v-model="polizaData.statusCivil"
                :items="['Soltero(a)', 'Casado(a)']"
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                label="Teléfono"
                outlined
                color="#00a7e4"
                v-mask="'##########'"
                v-model="polizaData.telefono"
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col m="12" md="6" lg="3" xl="3">
              <common-email-autocomplete
                @emailUpdate="(e) => (polizaData.correo_electronico = e)"
              >
              </common-email-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="polizaData.tipo_persona != null">
            <v-col m="12" md="6" lg="3" xl="3">
              <v-text-field
                v-model="polizaData.codigo_postal"
                label="Código Postal"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-text-field
                v-model="polizaData.calle"
                label="Calle"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-text-field
                v-model="polizaData.num_exterior"
                label="Numero Exterior"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-text-field
                v-model="polizaData.num_interior"
                label="Numero interior"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-autocomplete
                label="Colonia"
                v-model="polizaData.colonia"
                :items="colonias"
                item-text="nombreColonia"
                item-value="nombreColonia"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-text-field
                label="Ciudad"
                v-model="polizaData.ciudad"
                placeholder="Requerido"
                color="#00a7e4"
                type="text"
                outlined
                :rules="requiredRule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              m="12"
              md="6"
              lg="3"
              xl="3"
              v-if="polizaData.codigo_postal != null"
            >
              <v-autocomplete
                label="Estado de la República"
                v-model="polizaData.estado_id"
                placeholder="Requerido"
                :items="estados"
                item-text="name"
                item-value="id"
                color="#00a7e4"
                type="text"
                readonly
                outlined
                :rules="requiredRule"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="text-align: end;"
            >
              <v-btn
                rounded
                dark
                outlined
                color="#00a7e4"
                @click="beforeStep"
                style="margin-right: 11px;"
              >
                <span>
                  Anterior
                </span>
              </v-btn>
              <v-btn rounded dark class="common-botton" @click="validateStep">
                <span>
                  Siguiente
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-expansion-panel-content>
    </CommonExpansionPanel>
  </div>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import CommonEmailAutocomplete from "@/components/commonComponents/CommonEmailAutocomplete.vue";
import { mainAxios } from "@/mainAxios.js";
import moment from "moment";
import RfcFacil from "rfc-facil";

export default {
  components: {
    CommonExpansionPanel,
    CommonEmailAutocomplete,
  },
  props: {
    currentStep: Number,
    lead: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      polizaData: {
        tipo_persona: null,
        razon_social: null,
        rfc: null,
        nombre: null,
        fecha_nacimiento: null,
        edad: null,
        apellido_paterno: null,
        apellido_materno: null,
        genero: null,
        statusCivil: null,
        telefono: null,
        codigo_postal: null,
        estado_id: null,
        calle: null,
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      regimentList: ["Física", "Moral"],
      rfcRules: [
        (v) =>
          (v || "").length <= 13 || "RFC No debe ser mayor de 13 caracteres",
        (v) => !!v || "Campo Requerido",
        (v) =>
          v.length === 10 ||
          v.length === 13 ||
          "El RFC debe tener 10 o 13 caracteres",
        (v) =>
          /^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/.test(
            v
          ) || "RFC no es valido recordar escribir en mayusculas",
        (v) => !/(XXX)$/i.test(v) || "El RFC no debe terminar en 'XXX'",
        (v) => !(v == "XAXX010101000") || "El RFC no puede ser 'XAXX010101000'",
        (v) => !(v == "XAXX010101") || "El RFC no  puede ser 'XAXX010101'",
      ],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      generoList: ["Masculino", "Femenino"],
      emailRules: [
        (v) => !!v || "E-mail es requerido",
        (v) => /.+@.+\..+/.test(v) || "E-mail debe de ser valido",
      ],
      colonias: [],
      estados: [
        { id: 1, name: "Aguascalientes" },
        { id: 2, name: "Baja California" },
        { id: 3, name: "Baja California Sur" },
        { id: 4, name: "Campeche" },
        { id: 5, name: "Coahuila de Zaragoza" },
        { id: 6, name: "Colima" },
        { id: 7, name: "Chiapas" },
        { id: 8, name: "Chihuahua" },
        { id: 9, name: "Ciudad de México" },
        { id: 10, name: "Durango" },
        { id: 11, name: "Guanajuato" },
        { id: 12, name: "Guerrero" },
        { id: 13, name: "Hidalgo" },
        { id: 14, name: "Jalisco" },
        { id: 15, name: "Estado de México" },
        { id: 16, name: "Michoacán de Ocampo" },
        { id: 17, name: "Morelos" },
        { id: 18, name: "Nayarit" },
        { id: 19, name: "Nuevo León" },
        { id: 20, name: "Oaxaca" },
        { id: 21, name: "Puebla" },
        { id: 22, name: "Querétaro" },
        { id: 23, name: "Quintana Roo" },
        { id: 24, name: "San Luis Potosí" },
        { id: 25, name: "Sinaloa" },
        { id: 26, name: "Sonora" },
        { id: 27, name: "Tabasco" },
        { id: 28, name: "Tamaulipas" },
        { id: 29, name: "Tlaxcala" },
        { id: 30, name: "Veracruz de Ignacio de la Llave" },
        { id: 31, name: "Yucatán" },
        { id: 32, name: "Zacatecas" },
      ],
      currentPanel: null,
      readonly: true,
    };
  },

  watch: {
    "polizaData.fecha_nacimiento"(val) {
      var today = moment().format("YYYY-MM-DD");
      var birthday = moment(val, "YYYY-MM-DD");
      this.polizaData.edad = moment(today, "YYYY-MM-DD").diff(
        moment(birthday, "YYYY-MM-DD"),
        "years"
      );
      this.actualizarRfc();
    },
    "polizaData.codigo_postal"(val) {
      if (typeof val != "undefined") {
        if (val.length == 5) {
          const config = {
            headers: {
              Authorization: "Bearer " + localStorage.agenteAccessToken,
            },
          };
          mainAxios
            .get("/v1/catalogo/direccion?cp=" + val, config)
            .then((response) => {
              if (response.data.listaColonias.length >= 1) {
                this.colonias = response.data.listaColonias;
                this.polizaData.estado_id = Number(response.data.nEstado);
                this.polizaData.ciudad = response.data.nombreMunicipio;
              } else if (response.error == "") {
                alert("No existe ese codigo postal");
              }
            });
        }
      }
    },
    "polizaData.nombre"() {
      this.actualizarRfc();
    },
    "polizaData.apellido_paterno"() {
      this.actualizarRfc();
    },
    "polizaData.apellido_materno"() {
      this.actualizarRfc();
    },
    currentStep(val) {
      if (val == 2) {
        this.currentPanel = 0;
        this.readonly = false;
      } else {
        this.currentPanel = 1;
        this.readonly = true;
      }
    },
  },
  methods: {
    validateStep() {
      if (this.$refs.firstStepForm.validate()) {
        (this.polizaData.nombre_cliente = `${this.polizaData.nombre} 
        ${this.polizaData.apellido_paterno} 
        ${this.polizaData.apellido_materno}`),
          this.$emit("ChangeOnlyStep", 3, this.polizaData);
      }
    },
    beforeStep() {
      this.$emit("ChangeOnlyStep", 1, null);
    },
    actualizarRfc() {
      if (
        !this.polizaData.nombre ||
        !this.polizaData.apellido_paterno ||
        !this.polizaData.apellido_materno ||
        !this.polizaData.fecha_nacimiento
      )
        return;
      const arregloNacimiento = moment(this.polizaData.fecha_nacimiento)
        .format("DD/MM/YYYY")
        .split("/")
        .map((e) => Number(e));
      if (arregloNacimiento.length < 3) return;
      const rfc = RfcFacil.forNaturalPerson({
        name: this.polizaData.nombre,
        firstLastName: this.polizaData.apellido_paterno,
        secondLastName: this.polizaData.apellido_materno,
        day: arregloNacimiento[0],
        month: arregloNacimiento[1],
        year: arregloNacimiento[2],
      });
      this.polizaData.rfc = rfc;
    },
  },
  mounted() {
    if (this.lead) {
      this.polizaData.tipo_persona = this.lead.regimen;
      this.polizaData.razon_social = this.lead.apellidoMaterno;
      this.polizaData.rfc = this.lead.rfc;
      this.polizaData.nombre = this.lead.nombre;
      this.polizaData.fecha_nacimiento = this.lead.fechaNacimiento;
      this.polizaData.edad = this.lead.edad;
      this.polizaData.apellido_paterno = this.lead.apellidoMaterno;
      this.polizaData.apellido_materno = this.lead.apellidoMaterno;
      this.polizaData.genero = this.lead.sexo;
      this.polizaData.telefono = this.lead.telefono;
      this.polizaData.correo = this.lead.correo;
      this.polizaData.codigo_postal = this.lead.codigoPostal;
      this.polizaData.colonia = this.lead.colonia;
      this.polizaData.estado = this.lead.estado;
      this.polizaData.calle = this.lead.calle;
      this.polizaData.num_exterior = this.lead.num_exterior;
      this.polizaData.num_interior = this.lead.num_interior;

      // this.polizaData.statusCivil = this.lead.apellidoMaterno,
      // this.polizaData.telefono = this.lead.apellidoMaterno,
      // this.polizaData.estado_id = this.lead.apellidoMaterno,
    }
  },
};
</script>
