var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-border"},[_c('v-row',{staticClass:"pb-5"},[(_vm.globalConfig.showQuotesDashboard)?_c('v-col',{class:_vm.$route.params.vista_id == 4 ? 'text-center active-color' : 'text-center inactive-color',attrs:{"cols":"6","md":_vm.globalConfig.showQuotesDashboard ? (_vm.noShowPolices ?  3 :  4) : _vm.noShowPolices ? 4: 6},on:{"click":function($event){return _vm.$router.push('/main-hub/4')}}},[_c('span',{class:_vm.$route.params.vista_id == 4
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Mis Cotizaciones")])]):_vm._e(),_c('v-col',{class:_vm.$route.params.vista_id == 1 ? 'text-center active-color' : 'text-center inactive-color',attrs:{"cols":"6","md":_vm.globalConfig.showQuotesDashboard ? (_vm.noShowPolices ?  3 :  4) : _vm.noShowPolices ? 4: 6},on:{"click":function($event){return _vm.$router.push('/main-hub/1')}}},[_c('span',{class:_vm.$route.params.vista_id == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Mis solicitudes")])]),(_vm.noShowPolices)?_c('v-col',{class:_vm.$route.params.vista_id == 2 ? 'text-center active-color' : 'text-center inactive-color',attrs:{"cols":"6","md":_vm.globalConfig.showQuotesDashboard ? (_vm.noShowPolices ?  3 :  4) : _vm.noShowPolices ? 4: 6},on:{"click":function($event){return _vm.$router.push('/main-hub/2')}}},[_c('span',{class:_vm.$route.params.vista_id == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Mis Pólizas")])]):_vm._e(),_c('v-col',{class:_vm.$route.params.vista_id == 3 ? 'text-center active-color' : 'text-center inactive-color',attrs:{"cols":"6","md":_vm.globalConfig.showQuotesDashboard ? 3 : 4},on:{"click":function($event){return _vm.$router.push('/main-hub/3')}}},[_c('span',{class:_vm.$route.params.vista_id == 3
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v("Mis Clientes")])])],1),_c('div',[(_vm.isAlianza ? _vm.tab == 1 : _vm.$route.params.vista_id == 1)?_c('div',[_c('solicitudes-index')],1):_vm._e(),(_vm.isAlianza ? _vm.tab == 2 : _vm.$route.params.vista_id == 2)?_c('div',[_c('polizas-index')],1):_vm._e(),(_vm.isAlianza ? _vm.tab == 3 : _vm.$route.params.vista_id == 3)?_c('div',[_c('clientes-index')],1):_vm._e(),(_vm.isAlianza ? _vm.tab == 4 : _vm.$route.params.vista_id == 4)?_c('div',[_c('cotizaciones-index')],1):_vm._e(),(_vm.isAlianza ? _vm.tab == 5 : _vm.$route.params.vista_id == 5)?_c('div',[_c('TablaUsuarios',{attrs:{"modulo":"AGENTE","alianza_id":_vm.alianza_id}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }