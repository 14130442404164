<template>
  <v-dialog v-model="OpenDialogCreateDocument" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">
            <v-row>
              <v-col cols="2">
                <v-img :src="docsImg" max-width="25" he alt=""> </v-img>
              </v-col>
              <v-col cols="10">
                <span>
                  Documentos
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="2"
            style="text-align: end; cursor: pointer;"
            @click="closeModal"
          >
            <v-icon left color="#858C94">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          <commond-form
            :request="formData"
            :route="routesConfigDocsCard.route"
            :run="run"
            :action="routesConfigDocsCard.action"
            @cancel="run = false"
            @success="closeComponent"
            @afterError="closeComponent"
          >
            <template v-slot:data>
              <v-row class="pt-5">
                <v-col cols="12">
                  <v-text-field
                    label="Titulo"
                    outlined
                    v-model="data.nombre"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Tipo de Documento"
                    :items="tipoDocumentoItems"
                    v-model="data.tipo_documento"
                    outlined
                    required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="data.files"
                    color="#00a7e4"
                    counter
                    label="Archivo"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    :rules="requiredRule"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="#00a7e4" dark label small>
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ data.files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </template>
          </commond-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" style="text-align: end;">
            <v-btn rounded @click="closeComponent" outlined color="#00a7e4">
              <span style="padding-right: 11%;">
                Cancelar
              </span>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn rounded dark class="common-botton" @click="afterSend">
              <span style="padding-right: 11%;">
                Guardar
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommondForm from "@/components/commonComponents/CommondForm.vue";
import imagenDocs from "@/assets/docs.svg";

export default {
  components: {
    CommondForm,
  },
  props: {
    OpenDialogCreateDocument: {
      type: Boolean,
      default: false,
    },
    routesConfigDocsCard: Object,
  },

  data() {
    return {
      requiredRule: [(v) => !!v || "El campo es requerido"],
      data: {
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Documento",
        tipo_documento: null,
        files: [],
      },
      run: false,
      formData: null,
      tipoDocumentoItems: [
        "Acta constitutiva",
        "Aviso de privacidad firmado",
        "Caratula de afiliación",
        "Comprobante de Domicilio",
        "Comprobante Médico",
        "Constancia de situación fiscal",
        "Caratula de estado de cuenta",
        "Cotización",
        "Cédula del Agente",
        "Identificación oficial",
        "Documento de afiliación",
        "Poder Notarial",
        "Póliza",
        "RC de Agente",
        "Tarjeta de Circulación",
        "Otro",
      ],
      docsImg: imagenDocs,
    };
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    afterSend() {
      this.formData = new FormData();
      if (this.data.files.length > 0) {
        let file = this.data.files[0];
        this.formData.append("nota", this.data.nota ? this.data.nota : null);
        this.formData.append("tipo", this.data.tipo ? this.data.tipo : null);
        this.formData.append(
          "nombre",
          this.data.nombre ? this.data.nombre : null
        );
        this.formData.append("files", file);
        this.formData.append(
          "tipo_documento",
          this.data.tipo_documento ? this.data.tipo_documento : null
        );
        this.run = true;
      }
    },
    closeComponent() {
      this.data = Object.assign({
        nombre: null,
        enviarPorCorreo: false,
        nota: null,
        tipo: "Documento",
        tipo_documento: null,
      });
      this.$emit("success");
    },
  },
};
</script>
