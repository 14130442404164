<template>
  <common-card class="mt-3">
    <v-row>
      <v-col>
        <v-row style="padding: 15px 20px 0px 20px;">
          <v-col cols="12" md="6">
            <h1>Administrador de Plantillas</h1>
          </v-col>
          <v-col cols="12" md="6" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              :loading="loading"
              @click="$router.push({ name: 'crearPlantilla' })"
              v-if="canViewCreate"
            >
              <v-icon class="mr-2">mdi-plus</v-icon>
              <span>Crear</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-expansion-panels
      elevation="0"
      style="padding: 30px; border-radius: 20px"
    >
      <v-expansion-panel>
        <v-expansion-panel-header style="padding: 35px;">
          <h3 style="color:#42352E;">
            Búsqueda detallada
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="filtros.nombre"
                label="Nombre"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.status"
                label="Estatus"
                :items="status"
                item-text="label"
                item-value="value"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.ramos"
                multiple
                label="Ramos"
                :items="ramos"
                item-text="etiqueta"
                item-value="id"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.canales"
                multiple
                label="Canales"
                :items="canales"
                item-text="etiqueta"
                item-value="id"
                outlined
                dense
                color="primary"
                background-color="white"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="filtros.areas"
                multiple
                label="Areas"
                :items="areas"
                :item-text="
                  (item) =>
                    item.areas + '-' + item.departamento + '-' + item.proceso
                "
                item-value="id"
                outlined
                dense
                color="primary"
                background-color="white"
              >
                <template #item="{item}">
                  {{
                    item.areas + "-" + item.departamento + "-" + item.proceso
                  }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row style="padding: 0px 40px 0px 40px;" class="d-flex justify-end">
            <v-col cols="12" md="2">
              <v-btn
              dark
              block
              outlined
              color="#00a7e4"
              rounded
              @click="clearFilters()"
              >
              Limpiar
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" style="text-align: end;">
            <v-btn
              class="common-botton"
              dark
              block
              :loading="loading"
              @click="getTemplates"
            >
              <span>Filtrar</span>
            </v-btn>
          </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <v-row>
      <v-col cols="12" class="mb-10" style="padding: 40px">
        <v-data-table
          style=""
          :headers="plantillasTable"
          @pagination="pagination"
          :server-items-length="totalItems"
          :items="templates"
          item-key="id"
          class="elevation-0"
          :loading="loading"
          loading-text="Cargando..."
          no-data-text="Para ver elementos, primero realiza una búsqueda"
        >
          <template v-slot:top>
            <v-row style="padding: 15px;">
              <v-col cols="8">
                <h2>Resultados de la búsqueda</h2>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.area="{ item }">
            <span>{{
              transformIdtoItem(item.area, areas).areas +
                "-" +
                transformIdtoItem(item.area, areas).departamento +
                "-" +
                transformIdtoItem(item.area, areas).proceso
            }}</span>
          </template>
          <template v-slot:item.canal="{ item }">
            <span>{{ transformIdtoItem(item.canal, canales).etiqueta }}</span>
          </template>
          <template v-slot:item.ramo="{ item }">
            <span>{{ transformIdtoItem(item.ramo, ramos).etiqueta }}</span>
          </template>
          <template #item.fechaCreacion="{item}">
            <span>{{ moment(item.fechaRegistro).format("DD/MM/YYYY") }}</span>
          </template>

          <template v-slot:item.status="{ item }">
            <div>
              <v-chip
                :color="
                  item.statusId == 1
                    ? 'green'
                    : item.statusId == 2
                    ? 'amber darken-2'
                    : 'red'
                "
                dark
                >{{ item.status }}</v-chip
              >
            </div>
          </template>
          <template v-slot:item.uuid="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-if="item.statusId != 3"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </common-card>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import moment from "moment";
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { getCanalList } from "@/services/canal/canal.service.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";
import { getAreaList } from "@/services/area/area.service.js";

export default {
  components: {
    CommonCard,
  },
  data() {
    return {
      moment,
      templates: [],
      totalItems: 0,
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      roles: JSON.parse(localStorage.getItem("roles")),
      areas: [],
      ramos: [],
      canales: [],
      status: [
        { label: "Activo", value: 1 },
        { label: "Inactivo", value: 2 },
        { label: "Baja", value: 3 },
      ],
      loading: false,
      search: "",
      filtros: {
        nombre: "",
        status: "",
        ramos: [],
        canales: [],
        areas: [],
        pagina: 1,
        elementosPagina: 10,
      },
      plantillasTable: [
        {
          text: "Nombre Plantilla",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Area", value: "area" },
        { text: "Canal", value: "canal" },
        { text: "Ramo", value: "ramo" },
        { text: "Fecha Creación", value: "fechaCreacion" },
        { text: "Creado por", value: "creadoPor" },
        { text: "Status", value: "status" },
        { text: "Ver", value: "uuid" },
      ],
      report: [],
    };
  },

  watch: {
    $route() {
      this.getTemplates();
    },
  },

  computed: {
    canViewCreate() {
      let canView = false;
      let roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "OPERADORINBOUND"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
  },

  methods: {
    clearFilters() {
      this.filtros = {
        nombre: "",
        status: "",
        ramos: [],
        canales: [],
        areas: [],
        pagina: 1,
        elementosPagina: 10,
      };
      this.getTemplates();
    },
    async getCatalogs() {
      this.loading = true;
      this.areas = await getAreaList();
      this.ramos = await getRamoList();
      this.canales = await getCanalList();
      this.loading = false;
    },
    pagination(event) {
      this.filtros.pagina = event.page;
      this.filtros.elementosPagina = event.itemsPerPage;
      this.getTemplates();
    },
    getTemplates() {
      this.loading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      let filters = this.transformToParamsString(this.filtros);
      mainAxios
        .get(`/v1/plantillas-eci/${filters}`, config)
        .then((response) => {
          this.totalItems = response.data.data.count;
          this.templates = response.data.data.plantillas;
        });
      this.loading = false;
    },
    editItem(item) {
      this.$router.push(`/administacion/plantillas/editar/${item.id}`);
    },
    transformToParamsString(params) {
      let paramsString = "?";
      Object.keys(params).map((key) => {
        if (Array.isArray(params[key])) {
          paramsString +=
            params[key].length > 0 ? `${key}=[${params[key]}]&` : "";
        } else {
          paramsString +=
            params[key] !== null &&
            params[key] !== undefined &&
            params[key] !== ""
              ? `${key}=${params[key]}&`
              : "";
        }
      });
      paramsString = paramsString.slice(0, -1);
      paramsString = encodeURI(paramsString);
      paramsString = paramsString.replace(/#/g, "%23");
      paramsString = paramsString.replace(/\[/g, "%5B");
      paramsString = paramsString.replace(/\]/g, "%5D");
      return paramsString;
    },
    transformIdtoItem(id, array) {
      let selected = array.find((element) => element.id == id);
      return selected ?? { etiqueta: "-" };
    },
  },
  mounted() {
    this.getCatalogs();
    this.getTemplates();
  },
};
</script>
<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f6f5f9;
  color: rgba(0, 0, 0, 0.87);
}
</style>
