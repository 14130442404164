<template>
  <!-- Panel de expansión común que contiene el formulario de búsqueda y la tabla de cotizaciones -->
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <!-- Componente de filtros de cotizaciones -->
      <FiltersCotizaciones @searchUpdated="getCotizaciones" :currentFilters="filtros" :loading="loading">
      </FiltersCotizaciones>
      <hr />
      <!-- Componente de tabla de cotizaciones -->
      <TableCotizaciones :loading="loading" :solicitudes="solicitudes"></TableCotizaciones>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersCotizaciones from "./FiltersCotizaciones.vue";
import TableCotizaciones from "./TableCotizaciones.vue";
import { mainAxios } from "@/mainAxios.js";

export default {
  components: {
    CommonExpansionPanel,
    FiltersCotizaciones,
    TableCotizaciones,
  },

  data() {
    return {
      switch_data: 1, // Estado de ejemplo
      solicitudes: [], // Lista de solicitudes obtenidas
      filtros: [], // Filtros aplicados
      loading: false, // Indicador de carga
      groupedAlliance: true, // Indicador de agrupación por alianza
    };
  },
  props: {
    contactData: Object, // Datos de contacto
    correosDataFormat: Object, // Formato de correos
    routesContactData: Object, // Datos de rutas de contacto
  },
  methods: {
    // Método para obtener cotizaciones basado en filtros
    getCotizaciones(body) {
      this.solicitudes = [];
      this.loading = true;
      if (String(window.location.pathname).includes("/alianza/perfil/")) {
        body.alianza_id = this.$route.params.alianza_id;
      }
      body.alianza_id = this.$route.params.alianza_id || localStorage.alianzaId != 'null' ? localStorage.alianzaId : null;
      if(!body.alianza_id) delete body.alianza_id

      if(typeof body.alianza_id == 'undefined') delete body.alianza_id

      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };
      this.loading = true;
      mainAxios
        .post(`/v1/cotizacion/filtros`, body, requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length && !data[0].error) {
            this.solicitudes = data;
          } else {
            this.solicitudes = [];
            this.$toast.info('Sin resultados en la búsqueda...');
          }
          this.filtros = body; // Guarda los filtros aplicados
        });
      this.loading = false;
    },

    // Método para obtener todas las solicitudes
    getTodasLasSolicitudes() {
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .get(`/v1/solicitud/info/status/1`, requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data; // Asigna todas las solicitudes obtenidas
        });
    },
  },
  mounted() {
    // Verifica si la ruta incluye "/alianza/perfil/" y obtiene cotizaciones
    if (String(window.location.pathname).includes("/alianza/perfil/")) {
      this.getCotizaciones({});
      this.groupedAlliance = false;
    }
  },
};
</script>