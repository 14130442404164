<template>
  <v-container class="pa-10">
    <v-col
      ><h2>
        {{
          isFisica
            ? "Carga de archivos solicitados Persona Física"
            : "Carga de archivos solicitados Persona Moral"
        }}
      </h2></v-col
    >
    <v-row justify="center">
      <v-col class="" cols="12" sm="6">
        <v-row
          v-for="document in filteredDocuments"
          :key="document.id"
          @click="selectDocument(document.id)"
          :class="{ 'active-document': activeDocument.includes(document.id) }"
          class="document-row"
        >
          <v-col class="document-icon" cols="2">
            <img style="width: 24px" :src="bullet" alt="" />
          </v-col>
          <v-col class="document-name" cols="8">
            {{ document.name }}
          </v-col>
          <v-col class="document-icon" cols="2">
            <v-icon
              v-if="
                isDocumentUploaded(document.id) &&
                  (selectedFiles[document.id].type == 'application/pdf' ||
                    selectedFiles[document.id].type == 'image/png' ||
                    selectedFiles[document.id].type == 'image/jpeg') &&
                  selectedFiles[document.id].size <= 5 * 1024 * 1024
              "
              color="green"
              small
            >
              mdi-check-circle
            </v-icon>
          </v-col>
        </v-row>
      </v-col>

      <!-- Área de carga de archivos -->
      <v-col
        cols="12"
        sm="6"
        style="display: flex; justify-content: center; align-items: center"
      >
        <v-row
          class="file-upload-card"
          @dragover.prevent="onDragOver"
          @dragleave.prevent="onDragLeave"
          @drop.prevent="onFileDrop"
          :class="{ 'drag-active': isDragOver }"
        >
          <v-col
            v-if="currentDocument.title"
            style="display: flex; justify-content: center; align-items: center"
            cols="2"
            class="pa-0"
          >
            <v-img :src="uploadDocumentIcon" class="upload-icon"></v-img>
          </v-col>
          <v-col v-else justify="center">
            <p class="seleciona-archivo">Selecciona un documento</p>
          </v-col>

          <v-col v-if="currentDocument.title" cols="10" class="pr-6 pl-0 pt-7">
            <h3 class="documentTitle">
              {{ currentDocument.title }}
              <br />
              {{ currentDocument.description }}
              <a
                v-if="currentDocument.link"
                :href="currentDocument.link"
                target="_blank"
                class="link-blue"
                >{{ currentDocument.message }}</a
              >
            </h3>
            <v-card-text style="padding: 0px" v-if="currentDocument.title">
              <v-file-input
                v-model="selectedFiles[activeDocumentId]"
                accept=".pdf,.png,.jpg"
                label="Da click o arrastra el documento aquí"
                show-size
                prepend-icon=""
                @change="onFileSelected"
                :error-messages="fileErrorMessage"
                style="padding: 0px; cursor: pointer"
                v-slot:append-outer
              >
              </v-file-input>
            </v-card-text>
            <p>PDF, JPG y PNG Tamaño máx 5 MB</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--
      This button triggers the uploadFiles function passed as a prop when clicked.
      The button has a primary color and a top margin of 24px.
      @prop {Function} uploadFiles - Function to handle file uploads.
    -->
    <v-row
      style="display: flex; width: 100%; justify-content: end; align-items: center;"
    >
      <v-progress-circular
        v-if="uploading"
        indeterminate
        color="primary"
        class="mr-4"
      />
      <v-icon v-if="uploaded" color="green" style="margin-right: 8px;">
        mdi-check-circle
      </v-icon>
      <v-btn
        class="common-botton m-0"
        dark
        @click="uploadFiles"
        :disabled="
          disabledBtn ||
            errorTypeinFiles ||
            !allDocumentsUploaded ||
            uploading ||
            errorSizeinFiles ||
            selectedFiles.length < 11
        "
        >Subir Archivos</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import bullet from "@/assets/bulletDocumentsAlianzas.svg";
import Logomark from "../../../../assets/Logomark.svg";
import uploadDocumentIcon from "../../../../assets/uploadDocumentIcon.svg";

export default {
  props: {
    uploading: {
      type: Boolean,
      required: true,
    },
    uploadFiles: {
      type: Function,
      required: true,
    },
    regimen: {
      type: Number,
      required: true,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
    uploaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      bullet: bullet,
      Logomark: Logomark,
      uploadDocumentIcon: uploadDocumentIcon,
      personType: "Física",
      isDragOver: false,
      documentsFisica: [
        {
          id: 1,
          name: "ID vigente de Prospecto",
          description: "(Vigente y por ambos lados)",
          linkDownload: "",
        },
        { id: 2, name: "CURP del representante legal" },
        {
          id: 3,
          name: "Constancia de Situación Fiscal",
          description: "(No mayor a 3 meses)",
        },
        {
          id: 4,
          name: "Comprobante de Domicilio",
          description: "(No mayor a 3 meses)",
        },
        {
          id: 5,
          name: "Caratula de Cuenta Bancaria",
          description: "(Con CLABE y a nombre de la persona física)",
        },
        {
          id: 6,
          name: "Opinión de cumplimiento fiscal",
          description: "(No mayor a 30 días)",
        },
        {
          id: 7,
          name: "Cuestionario de partes relacionadas",
          description: "",
          linkDownload:
            "https://docs.google.com/spreadsheets/d/1_pK9yitxK3T0skWAtQLbEKcD3Mp-uKoG/edit?usp=drive_link&ouid=107698637169938753967&rtpof=true&sd=true",
        },
        {
          id: 8,
          name: "Notificación resguardo",
          description: "",
          linkDownload:
            "https://drive.google.com/file/d/1cBspM2fdYnRuj-9I-fY5iUrYA1eAQbQm/view?usp=drive_link",
        },
        {
          id: 9,
          name: "Aviso de Privacidad",
          description: "",
          linkDownload:
            "https://drive.google.com/file/d/1ftX9NaQoBa1IKjugAajmEd7isNpiqMI2/view?usp=drive_link",
        },
        { id: 10, name: "Opinión de IMSS e INFONAVIT" },
        { id: 11, name: "Contrato de la Alianza" },
      ],
      documentsMoral: [
        { id: 1, name: "Acta Constitutiva" },
        { id: 2, name: "Constancia de Situación Fiscal", description: "" },
        {
          id: 3,
          name: "ID vigente del Representante Legal",
          description: "(Ambos lados)",
        },
        {
          id: 4,
          name: "Poder del Representante Legal",
          description: "(En caso de ser diferente al Acta Constitutiva)",
        },
        {
          id: 5,
          name: "Comprobante de Domicilio",
          description: "(No mayor a 3 meses)",
        },
        {
          id: 6,
          name: "Caratula de Cuenta Bancaria",
          description:
            "(Con CABLE, a nombre de la persona moral y no mayor a 3 meses)",
        },
        {
          id: 7,
          name: "Cuestionario de partes relacionadas",
          description: "",
          linkDownload:
            "https://docs.google.com/spreadsheets/d/1_pK9yitxK3T0skWAtQLbEKcD3Mp-uKoG/edit?usp=drive_link&ouid=107698637169938753967&rtpof=true&sd=true",
        },
        {
          id: 8,
          name: "Notificación de Resguardo",
          description: "",
          linkDownload:
            "https://drive.google.com/file/d/1cBspM2fdYnRuj-9I-fY5iUrYA1eAQbQm/view?usp=drive_link",
        },
        {
          id: 9,
          name: "Aviso de Privacidad",
          description: "",
          linkDownload:
            "https://drive.google.com/file/d/1ftX9NaQoBa1IKjugAajmEd7isNpiqMI2/view?usp=drive_link",
        },
        {
          id: 10,
          name: "Opinión de IMSS e INFONATIV",
          description:
            "(Aplica si su constancia de situación fiscal cuenta con entero de retenciones de sueldos y salarios)",
        },
        { id: 11, name: "Contrato" },

        // Añadir más documentos según sea necesario
      ],
      activeDocument: [],
      selectedFiles: {},
      fileErrorMessage: "",
    };
  },
  computed: {
    errorTypeinFiles() {
      return !Object.values(this.selectedFiles).every((file) =>
        ["application/pdf", "image/png", "image/jpeg"].includes(file.type)
      );
    },
    errorSizeinFiles() {
      return !Object.values(this.selectedFiles).every(
        (file) => file.size <= 5 * 1024 * 1024
      );
    },
    allDocumentsUploaded() {
      return this.filteredDocuments.every((doc) => this.selectedFiles[doc.id]);
    },
    isFisica() {
      return this.regimen === 2;
    },
    filteredDocuments() {
      return this.isFisica ? this.documentsFisica : this.documentsMoral;
    },
    currentDocument() {
      const selectedDoc = this.filteredDocuments.find((doc) =>
        this.activeDocument.includes(doc.id)
      );
      return selectedDoc
        ? {
            title: selectedDoc.name,
            description: selectedDoc.description,
            link: selectedDoc.linkDownload,
            message: "Descarga formato y Carga",
          }
        : "";
    },
    activeDocumentId() {
      return this.activeDocument[0] || null;
    },
  },

  methods: {
    removeFile(documentId) {
      this.$delete(this.selectedFiles, documentId);
      console.log("Archivo eliminado para el documento ID:", documentId);
    },
    selectDocument(documentId) {
      this.activeDocument = [documentId];
      this.fileErrorMessage = "";
    },
    onFileSelected(file) {
      if (!file) {
        this.removeFile(this.activeDocumentId);
      }
      console.log("changing", file);
      const maxFileSize = 5 * 1024 * 1024;
      const allowedExtensions = ["pdf", "png", "jpg"];
      const fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        this.fileErrorMessage =
          "Tipo de archivo no permitido. Seleccione un archivo válido.";
        return;
      }

      if (file.size > maxFileSize) {
        this.fileErrorMessage = "El archivo supera el tamaño máximo de 5 MB.";

        return;
      }

      this.fileErrorMessage = "";

      if (this.activeDocumentId) {
        const document = this.filteredDocuments.find(
          (doc) => doc.id === this.activeDocumentId
        );
        const newFileName = `${document.name.replace(
          /\s+/g,
          "_"
        )}.${fileExtension}`;

        const renamedFile = new File([file], newFileName, { type: file.type });

        // Actualiza el archivo seleccionado
        this.$set(this.selectedFiles, this.activeDocumentId, renamedFile);

        this.$emit("file-selected", {
          documentId: this.activeDocumentId,
          file: renamedFile,
        });
      }
      this.$emit("update-upload-status", this.allDocumentsUploaded);
    },

    isDocumentUploaded(documentId) {
      return !!this.selectedFiles[documentId];
    },

    onDragOver() {
      this.isDragOver = true;
    },

    // Método para manejar cuando el archivo deja el área de arrastre
    onDragLeave() {
      this.isDragOver = false;
    },

    // Método que maneja el archivo cuando es soltado en el área
    onFileDrop(event) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;

      if (droppedFiles.length > 0) {
        const file = droppedFiles[0];
        this.onFileSelected(file);

        // Actualizamos `selectedFiles` con el archivo arrastrado
        if (this.activeDocumentId) {
          this.$set(this.selectedFiles, this.activeDocumentId, file);
        }
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 40px;
}

.documentTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 20px;
}

.img-download-docs {
  max-width: 32px;
  max-height: 32px;
}

.download-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

h3 {
  font-family: "Source Sans 3";
  font-size: 16px;
  font-weight: 600;
}
p {
  font-family: "Source Sans 3";
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.document-row {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-document {
  background-color: #f0f0f0;
  border-left: 4px solid #00a5df;
  padding-left: 16px;
  transition: background-color 0.3s;
  /* width: 95%; */
}

.document-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-name {
  font-family: "Source Sans 3";
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0;
}

.file-upload-card {
  padding-left: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px dashed #00000040;
}

.link-blue {
  color: #00a5df;
  cursor: pointer;
  text-decoration: none;
}
.error-message {
  color: red;
  font-size: 14px;
}
.custom-file-input .v-input__prepend-inner .v-icon {
  font-size: 50px;
}
.upload-icon {
  max-width: 46px;
  max-height: 46px;
}
.seleciona-archivo {
  font-family: "Source Sans 3";
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #00000040;
  margin: 0;
}
.file-upload-card.drag-active {
  border: 2px dashed #1976d2;
  background-color: #e3f2fd;
}
</style>
