var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"end","display":"flex","gap":"30px","align-items":"center","justify-content":"flex-end"},attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[(_vm.uploadingData)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.editable)?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.editable = false}}},[_vm._v(" Cancelar ")]):_vm._e(),(_vm.canEdit)?_c('v-btn',{staticClass:"common-botton",style:(_vm.editable
            ? 'min-width: 64px; padding: 10px;'
            : 'border-radius: 50%; width: 36px; height: 36px; min-width: 0px;'),attrs:{"rounded":"","dark":""},on:{"click":_vm.changeComponent}},[(_vm.editable)?_c('span',{on:{"click":_vm.saveData}},[_vm._v(" "+_vm._s(_vm.uploadingData ? "Guardando..." : null)+" Guardar ")]):_vm._e(),(!_vm.editable && _vm.isAdminAgent)?_c('v-icon',{staticStyle:{"margin":"4px"},attrs:{"small":""}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.editable)?_c('v-icon',{staticStyle:{"margin":"4px"},attrs:{"small":""}},[_vm._v(" mdi-content-save ")]):_vm._e()],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Nombre de la alianza")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.nameAlliance ? '' : 'font-style: italic;'),attrs:{"name":"nombreAlianza","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.nameAlliance
            ? _vm.editableData.nameAlliance
            : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.nameAlliance = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}}),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Régimen ")]),_c('br'),_c('v-autocomplete',{staticStyle:{"max-height":"40px"},attrs:{"item-text":"label","item-value":"id","value":_vm.editableData.cat_regime_id
            ? _vm.editableData.cat_regime_id
            : 'sin información',"items":_vm.regimen,"placeholder":"Seleccionar Género","outlined":"","primary":"","color":"#00a7e4","disabled":!_vm.editable,"dense":""},on:{"change":function($event){_vm.editableData.cat_regime_id = $event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Nombre ")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.nombre ? '' : 'font-style: italic;'),attrs:{"name":"nombre","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.nombre ? _vm.editableData.nombre : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.nombre = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Apellido Paterno ")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.apellidoPaterno ? '' : 'font-style: italic;'),attrs:{"name":"apellidoPaterno","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.apellidoPaterno
            ? _vm.editableData.apellidoPaterno
            : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.apellidoPaterno = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Apellido Materno ")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.apellidoMaterno ? '' : 'font-style: italic;'),attrs:{"name":"apellidoMaterno","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.apellidoMaterno
            ? _vm.editableData.apellidoMaterno
            : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.apellidoMaterno = $event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Género ")]),_c('br'),_c('v-autocomplete',{staticStyle:{"max-height":"40px"},attrs:{"item-text":"sexo","item-value":"id","value":_vm.editableData.cat_gender_id
            ? _vm.editableData.cat_gender_id
            : 'sin información',"items":_vm.sexoList,"placeholder":"Seleccionar Género","outlined":"","primary":"","color":"#00a7e4","disabled":!_vm.editable,"dense":""},on:{"change":function($event){_vm.editableData.cat_gender_id = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Fecha de nacimiento ")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.fechaNacimiento ? '' : 'font-style: italic;'),attrs:{"name":"fechaNacimiento","outlined":"","primary":"","dense":"","color":"#00a7e4","type":"date","value":_vm.editableData.fechaNacimiento
            ? _vm.editableData.fechaNacimiento
            : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.fechaNacimiento = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v(" Estado de nacimiento")]),_c('br'),_c('v-autocomplete',{staticStyle:{"max-height":"40px"},attrs:{"item-text":"estadoRepublica","item-value":"id","value":_vm.editableData.cat_state_id
            ? _vm.editableData.cat_state_id
            : 'sin información',"items":_vm.estados,"placeholder":"Seleccionar Estado de nacimiento","outlined":"","primary":"","color":"#00a7e4","disabled":!_vm.editable,"dense":""},on:{"change":function($event){_vm.editableData.cat_state_id = $event}}})],1)],1),_c('v-row',{staticClass:"d-flex",staticStyle:{"gap":"16px","margin-top":"16px"}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v("Correo electrónico")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.correo ? '' : 'font-style: italic;'),attrs:{"name":"correo","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.correo ? _vm.editableData.correo : 'sin información',"disabled":!_vm.editable},on:{"change":function($event){_vm.editableData.correo = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v("RFC")]),_c('br'),_c('v-text-field',{style:(_vm.editableData.rfc ? '' : 'font-style: italic;'),attrs:{"name":"rfc","outlined":"","primary":"","dense":"","color":"#00a7e4","value":_vm.editableData.rfc ? _vm.editableData.rfc : 'sin información',"disabled":!_vm.editable,"rules":_vm.rulesRfc},on:{"change":function($event){_vm.editableData.rfc = $event}}})],1),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"},on:{"click":_vm.openMenuMethod}},[_c('span',{staticClass:"text-header-data"},[_vm._v("Ramo")]),_c('br'),_c('div',{staticStyle:{"position":"relative"}},[_c('v-menu',{attrs:{"min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"visibility":"hidden","position":"absolute"}},on),[_vm._v("Open Menu")])]}}]),model:{value:(_vm.openMenu),callback:function ($$v) {_vm.openMenu=$$v},expression:"openMenu"}},[_c('v-list',_vm._l((_vm.ramoList),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"value":item.branchId,"input-value":_vm.editableData.cat_branch_ids.includes(item.branchId),"disabled":!_vm.editable},model:{value:(_vm.editableData.cat_branch_ids),callback:function ($$v) {_vm.$set(_vm.editableData, "cat_branch_ids", $$v)},expression:"editableData.cat_branch_ids"}})],1)],1)}),1)],1),_c('v-autocomplete',{attrs:{"items":_vm.ramoList,"item-text":"label","item-value":"branchId","label":"Ramo","multiple":"","outlined":"","primary":"","color":"#00a7e4","dense":"","readonly":!_vm.editable},on:{"change":_vm.handleBranchSelectionChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
            var index = ref.index;
return [(index < 3)?_c('v-chip',{staticStyle:{"padding":"1px","border-radius":"5px","height":"20px"},attrs:{"color":"primary"}},[_c('span',{staticStyle:{"padding":"3px","margin":"0"}},[_vm._v(_vm._s(_vm.ramoList[index].label))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.editableData.cat_branch_ids.length - 3)+" más) ")]):_vm._e()]}}]),model:{value:(_vm.editableData.cat_branch_ids),callback:function ($$v) {_vm.$set(_vm.editableData, "cat_branch_ids", $$v)},expression:"editableData.cat_branch_ids"}})],1)]),_c('v-col',{attrs:{"cols":"6","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('span',{staticClass:"text-header-data"},[_vm._v("Canal")]),_c('br'),(!_vm.editable)?_c('div',[_vm._v(" "+_vm._s(_vm.editableData.cat_channel_id ? _vm.getChannelName(_vm.editableData.cat_channel_id) : "sin información")+" ")]):_c('v-autocomplete',{staticStyle:{"max-height":"40px"},attrs:{"item-text":"label","item-value":"channelId","value":_vm.editableData.cat_channel_id
            ? _vm.editableData.cat_channel_id
            : 'sin información',"items":_vm.canalList,"placeholder":"Seleccionar Canal","outlined":"","primary":"","color":"#00a7e4","disabled":!_vm.editable,"dense":""},on:{"change":function($event){_vm.editableData.cat_channel_id = $event}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("La alianza se modificó con éxito")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.success = false}}},[_vm._v("Continuar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }