<template>
  <v-container>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h4>Perfil Alianza</h4>
          <span>Actualice aquí la foto y los datos de su empresa.</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img :src="coverImage
              ? coverImage
              : 'https://via.placeholder.com/820x312.png?text='
            " class="cover-image"></v-img>
        </v-col>
        <v-row class="align-items-end">
          <v-col cols="auto" style="margin-top: -32px">
            <v-avatar size="100" class="ml-6" color="#00A5DF">
              <v-img :src="logo ? logo : 'https://via.placeholder.com/.png?text'" alt="Logo"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="align-self-end">
            <div>
              <h3>{{ nameAlliance }}</h3>
              <p>ID {{ idAliance }}</p>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-card-text>
      <v-container>
        <InputFile text="Logo compañia" typeFile="logoCompania" @file-loaded-base64="fileLoaded"
          @file-loaded="fileLoadedFile" inputId="logo-file-input" :size="'PNG, JPG (max. 170x170px)'" />
        <InputFile text="Imagen de fondo" typeFile="imagenCover" inputId="cover-file-input"
          @file-loaded-base64="fileLoaded" @file-loaded="fileLoadedFile" :size="'PNG, JPG (max. 820x312px)'" />
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <span class="text-subtitle-2 font-weight-bold" style="color: #4d5358">Nombre de alianza
              </span>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field label="Alianza" v-model="alianzaName" outlined dense primary color="#00a7e4"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <span class="text-subtitle-2 font-weight-bold" style="color: #4d5358">Elegir color
            </span>
          </v-col>

          <v-col cols="auto">
            <div :style="{ backgroundColor: alianzaColor }" class="color-box"></div>
          </v-col>

          <v-col cols="auto">
            <div @click="colorDialog = true" outlined>
              <h3 style="color: #00a5df; padding-bottom: 3px; cursor: pointer">
                Elegir color
                <v-icon right>mdi-pencil-outline</v-icon>
              </h3>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="colorDialog" persistent max-width="290px">
          <v-card>
            <v-card-title class="headline">Elija el color</v-card-title>
            <v-card-text>
              <v-color-picker v-model="alianzaColor" flat></v-color-picker>
            </v-card-text>

            <v-card-actions>
              <v-btn text color="primary" @click="colorDialog = false">
                Listo
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialog" max-width="290px">
          <v-card>
            <v-col align="center">
              <v-icon style="font-size: 70px; margin-bottom: 0" color="blue">
                mdi-alert-circle
              </v-icon>
            </v-col>
            <v-card-title class="headline message">{{ message }}</v-card-title>
            <v-card-actions>
              <v-col align="center">
                <v-btn rounded color="primary" @click="messageDialog = false">
                  Entendido
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="messageDialogSuccess" max-width="fit-content">
          <v-card class="card-dialog">
            <div class="title-container">
              <v-card-title class="headline">¡La alianza fue creada con éxito!</v-card-title>
            </div>
            <v-card-text>
              <v-col class="pa-0" cols="12" align="center">
                <v-img class="loader small-icon" :src="loader"></v-img>
              </v-col>
              <p class="text-center">{{ message }}</p>
            </v-card-text>
            <v-card-actions class="actions">
              <v-btn rounded color="primary" @click="goMainView">
                Entendido
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-card-text>
    <v-divider class="my-2 mx-3"></v-divider>
    <v-col cols="12" style="text-align: end">
      <v-btn @click="saveAlianza" class="common-botton mr-4" dark width="200" :disabled="blockSave">
        Guardar
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import { mainAxios } from "../../../../mainAxios";
import InputFile from "../../../components/InputFile.vue";
import loader from "../../../../assets/Loaderverify.gif";

export default {
  props: {
    idAliance: Number, // Propiedad para recibir el ID de la alianza.
    nameAlliance: String, // Propiedad para recibir el nombre de la alianza.
  },
  name: "ConfiguracionMarcaAltaAlianza", // Nombre del componente.
  components: {
    InputFile, // Componente personalizado para cargar archivos.
  },
  data() {
    return {
      blockSave: false, // Controla si el botón "Guardar" está bloqueado.
      step: 1, // Puede representar el paso actual en un flujo (aunque no se usa explícitamente aquí).
      logo: null, // Base64 del logo cargado.
      logoFile: null, // Archivo del logo cargado.
      coverImageFile: null, // Archivo de la imagen de portada cargada.
      coverImage: null, // Base64 de la imagen de portada cargada.
      alianzaName: this.nameAlliance, // Nombre de la alianza (inicializado con la propiedad).
      alianzaColor: "#D9D9D9", // Color principal de la alianza (valor inicial).
      colorDialog: false, // Controla la visibilidad del diálogo para elegir el color.
      messageDialog: false, // Controla la visibilidad del mensaje de error.
      message: "", // Mensaje a mostrar en el diálogo.
      messageDialogSuccess: false, // Controla la visibilidad del mensaje de éxito.
      loader, // Imagen del loader de carga.
    };
  },
  methods: {
    // Método para guardar la configuración de la alianza.
    async saveAlianza() {
      // Verifica que ambos archivos (logo y portada) hayan sido cargados.
      if (!this.logoFile || !this.coverImageFile) {
        this.messageDialog = true; // Muestra un mensaje de error.
        this.message = "Debes cargar el logo y la imagen de portada.";
        return;
      }

      // Crea un FormData para enviar los datos como multipart/form-data.
      const formData = new FormData();
      formData.append("allianceId", this.idAliance); // ID de la alianza.
      formData.append("mainColor", this.alianzaColor); // Color principal.
      formData.append("imageLogo", this.logoFile); // Archivo del logo.
      formData.append("imageCover", this.coverImageFile); // Archivo de portada.

      // Configuración de los encabezados para la solicitud HTTP.
      const config = {
        headers: {
          "Content-Type": "multipart/form-data", // Indica el tipo de contenido.
          Authorization: "Bearer " + localStorage.agenteAccessToken, // Token de autenticación.
        },
      };

      this.blockSave = true; // Bloquea el botón "Guardar" mientras se procesa la solicitud.
      try {
        const response = await mainAxios.post(
          "v1/alliance/brand-config", // URL del endpoint.
          formData,
          config
        );

        // Si la solicitud es exitosa.
        if (response.status === 200) {
          localStorage.setItem("allianceLogo", this.logo); // Guarda el logo en localStorage.
          localStorage.setItem("allianceCover", this.coverImage); // Guarda la portada en localStorage.

          this.messageDialogSuccess = true; // Muestra el mensaje de éxito.
          this.message = "¡La alianza fue creada con éxito!.";
          this.sendMailWelcome(); // Envía un correo de bienvenida.

        } else {
          this.messageDialog = true; // Muestra un mensaje de error.
          this.message = "Hubo un error al crear la alianza.";
        }
        // Limpia datos relacionados en localStorage.
        localStorage.removeItem("idAliance");
        localStorage.removeItem("currentStep");
      } catch (error) {
        // Manejo de errores.
        console.error("Error al crear la alianza:", error);
        this.messageDialog = true; // Muestra un mensaje de error.
        this.message = "Hubo un error al crear la alianza.";
        this.blockSave = false; // Desbloquea el botón "Guardar".
      }
    },

    // Método para manejar archivos cargados en formato base64.
    fileLoaded(base64, type) {
      switch (type) {
        case "logoCompania":
          this.logo = base64; // Asigna el logo cargado.
          break;
        case "imagenCover":
          this.coverImage = base64; // Asigna la portada cargada.
          break;
      }
    },

    // Método para manejar archivos cargados como objetos File.
    fileLoadedFile(file, type) {
      switch (type) {
        case "logoCompania":
          this.logoFile = file; // Asigna el archivo del logo.
          break;
        case "imagenCover":
          this.coverImageFile = file; // Asigna el archivo de portada.
          break;
      }
    },

    // Método para redirigir a la vista principal después de un éxito.
    goMainView() {
      this.messageDialogSuccess = false; // Oculta el mensaje de éxito.
      this.$router.push({ name: "tablaAlianzasCorporativas" }); // Navega a otra vista.
    },

    // Método para enviar un correo de bienvenida.
    async sendMailWelcome() {
      // Configuración de encabezados para la solicitud HTTP.
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken, // Token de autenticación.
        },
      };

      // Realiza una solicitud para obtener detalles de la alianza.
      await mainAxios.get(`/v1/alliance/${this.idAliance}`, config);
    },
  },
};
</script>

<style scoped>
.tall-file-input {
  height: 150px !important;
}

.color-box {
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
}

.cover-image {
  height: 200px;
  background-color: #e0e0e0;
}

.v-avatar {
  border: 4px solid white;
}

span {
  color: #78909c;
  font-size: 14px;
}

.message {
  text-align: center;
}

.card-dialog {
  position: relative;
  border-radius: 16px;
  padding-bottom: 16px;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.headline {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding-top: 19px;
  padding-bottom: 0px;
  text-align: center;
}

.loader {
  max-width: 91px;
}

.loader.small-icon {
  max-width: 100px;
}

.actions {
  display: flex;
  justify-content: center;
  padding: 16px;
}
</style>
