<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card v-if="step === 0" style="overflow: hidden"  >
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-linear
            :value="progress"
            height="30"
            width="80%"
            color="blue"
            class="mt-10"
          >
            {{ progress }}%
          </v-progress-linear>
          <p>Procesando Archivo</p>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="step === 1" class="card-dialog">
      <v-card-text
        justify="center"
        align="center"
        style="padding: 30px"
      >
        <!--  <v-card-text justify="center" align="center" style="padding: 30px">-->
        <h2 class="text-center">
          Reporte de hoy: {{ new Date().toLocaleDateString() }}
        </h2>
        <div
          style="
            width: fit-content;
            width: fit-content;
            display: flex;
            flex-direction: column;
          "
        >
          <div class="container-datos">
            <h3>Registros Ejecutados:</h3>
            <p>{{ reporte.ejecutados }}</p>
          </div>
          <div class="container-datos">
            <h3>Registros Correctos:</h3>
            <p>{{ reporte.correctos }}</p>
          </div>
          <div class="container-datos">
            <h3>Registros Rechazados:</h3>
            <p>{{ reporte.rechazados }}</p>
          </div>
          <div class="container-datos">
            <h3>Registros Duplicados:</h3>
            <p>{{ reporte.duplicados }}</p>
          </div>
        </div>
        <div class="file-container">
          <v-icon style="font-size: 60px; margin: 0" color="white"
            >mdi-file-document-outline</v-icon
          >
          <p class="text-file">
            Descarga <br />
            Resultado
          </p>
          <v-icon
            style="
              font-size: 20px;
              margin: 0;
              margin-right: 10px;
              background-color: #292c30;
              border-radius: 100px;
              padding: 10px;
            "
            color="#2F66AE"
            @click="descargarExcel"
            >mdi-tray-arrow-down</v-icon
          >
        </div>
      </v-card-text>

      <v-card-actions class="actions">
        <v-btn
          v-if="step === 1"
          style="width: 270px; margin-bottom: 20px"
          rounded
          color="primary"
          @click="cancelarRegistro"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from "../../../../assets/Loaderverify.gif";
export default {
  props: {
    dialog: Boolean,
    reporte: Object,
    step: Number,
    progress: Number,
    showDetails: Boolean,
  },
  data() {
    return {
      loader,
      internalDialog: this.dialog,
      catalogRamos: [
        { id: 1, label: "Autos" },
        { id: 2, label: "Salud" },
        { id: 3, label: "Daños" },
        { id: 4, label: "Fianza" },
        { id: 5, label: "Vida" },
        { id: 6, label: "Ahorro" },
      ],
    };
  },
  watch: {
    dialog(val) {
      this.internalDialog = val;
    },
    internalDialog(val) {
      this.$emit("update:dialog", val);
    },
    progress(val) {
      if (val === 100) {
        this.$emit("progresoCompleto");
      }
    },
  },
  methods: {
    descargarExcel() {
      this.$emit("descargarExcel");
    },

    cancelarRegistro() {
      this.$emit("update:dialog", false);
      this.$emit("returnStep");
    },
    obtenerLabelsPorIds(ids, catalogo) {
      if (!ids || !catalogo) return [];
      return ids.map((id) => {
        const ramoEncontrado = catalogo.find((ramo) => ramo.id === id);
        return ramoEncontrado ? ramoEncontrado.label : "Desconocido";
      });
    },
  },
};
</script>
<style scoped>
.headline {
  font-weight: bold;
  margin-bottom: 0;
}

.text-center {
  font-family: "Montserrat";
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-dialog {
  border-radius: 16px;
}
.cancel-btn {
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 300;
}
.loader {
  max-width: 91px;
}
h3 {
  width: fit-content;
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 18px;
  margin-bottom: 10px;
}
p {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
}
.container-datos {
  display: flex;
  gap: 10px;
  width: fit-content;
}
.file-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-top: 20px;
  background-color: #000000;
  padding: 20px;
  border-radius: 10px;
  width: fit-content;
}
.text-file {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 300;
  color: white;
  margin: 0;
}
</style>
