<template>
  <CommonExpansionPanel :currentPanel="0">
    <template v-slot:title-panel>
      Buscar
    </template>
    <v-expansion-panel-content>
      <FiltersClientes
        @searchUpdated="getClientes"
        :currentFilters="filtros"
        :loading="loading"
      ></FiltersClientes>
      <hr />
      <TableClientes
        :loading="loading"
        :clientes="clientes"
      ></TableClientes>
    </v-expansion-panel-content>
  </CommonExpansionPanel>
</template>

<script>
import CommonExpansionPanel from "@/components/commonComponents/CommonExpansionPanel.vue";
import FiltersClientes from "./FiltersClientes.vue";
import TableClientes from "./TableClientes.vue";
import { mainAxios } from "@/mainAxios.js";


export default {
  components: {
    CommonExpansionPanel,
    FiltersClientes,
    TableClientes,
  },

  data() {
    return {
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      clientes: [],
      todosLosClientes: {},
      filtros: {},
      clienteStatus: [
        {
          id: 0,
          title: "Nuevo",
        },
        {
          id: 1,
          title: "Activo",
        },
        {
          id: 2,
          title: "Inactivo",
        },
        {
          id: -1,
          title: "Baja",
        },
      ],
    };
  },
  props: {
    contactData: Object,
    correosDataFormat: Object,
    routesContactData: Object,
  },
  methods: {
    getClientes(body) {
      if(String(window.location.pathname).includes('/alianza/perfil/')){
        body.alianza_id = this.$route.params.alianza_id;
      }
      
      body.alianza_id = this.$route.params.alianza_id || localStorage.alianzaId != 'null' ? localStorage.alianzaId : null;
      if(!body.alianza_id) delete body.alianza_id

      this.loading = true;
      mainAxios
        .post(`/v1/cliente/list/filtros`, body, this.requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length)
            this.clientes = data.map((cliente) => {
              const result = {
                ...cliente,
              };
              result.status = this.clienteStatus.find(
                (e) => e.id === result.status
              ).title;

              return result;
            });
          else this.clientes = [];
          this.filtros = body;
        });
    },

    getTodasLasSolicitudes() {
      mainAxios
        .get(`/v1/solicitud/info/status/1`, this.requestConfig)
        .then(({ data }) => {
          this.todasLasSolicitudes = data;
        });
    },
  },

  mounted(){
    if(String(window.location.pathname).includes('/alianza/perfil/')){
        this.getClientes({})
      }
  }

};
</script>
