<template>
  <v-row class="file-upload-card" @dragover.prevent="onDragOver" @dragleave.prevent="onDragLeave"
    @drop.prevent="onFileDrop" :class="{ 'drag-active': isDragOver }">
    <v-col cols="2" style="display: flex; justify-content: center; align-items: center">
      <v-img :src="uploadIcon" class="upload-icon" />
    </v-col>
    <v-col cols="10">
      <h3 class="documentTitle">
        {{ text }}
        <br />
      </h3>
      <v-card-text style="padding: 0">
        <v-file-input v-model="selectedFile" accept="image/png, image/jpeg"
          label="Da click o arrastra el documento aquí" show-size prepend-icon="" @change="handleFileChange"
          :error-messages="fileErrorMessage" style="padding: 0; cursor: pointer"></v-file-input>
      </v-card-text>
      <p class="seleciona-archivo">JPG y PNG (max. 820x312px)</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InputFile",
  props: {
    text: String,
  typeFile: {
    required: true,
  },
},
  data() {
    return {
      selectedFile: null,
      isDragOver: false,
      uploadIcon: require("../../assets/uploadDocumentIcon.svg"),
      fileErrorMessage: "",
    };
  },
  methods: {
    handleFileChange(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.$emit("file-loaded-base64", reader.result, this.typeFile); 
        };
        this.$emit("file-loaded", file, this.typeFile);
        this.selectedFile = file;
      }
    },
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onFileDrop(event) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        this.handleFileChange(droppedFiles[0]);
      }
    },
  },
};
</script>

<style scoped>
.file-upload-card {
  padding-left: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px dashed #00000040;
}

.file-upload-card.drag-active {
  border: 2px dashed #1976d2;
  background-color: #e3f2fd;
}

.upload-icon {
  max-width: 46px;
  max-height: 46px;
}

.seleciona-archivo {
  font-family: "Source Sans 3";
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
}

.documentTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
  text-align: left;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
}
</style>