<template>
  <div>
    <v-row>
      <!-- <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
        <v-btn
          rounded
          dark
          class="common-botton"
          @click="$emit('changeComponent')"
        >
          <span style="padding-right: 11%;">Editar</span>
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
    <v-row>
        <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Estatus</span>
        <br />
        <span class="text-data-details">
          {{ formData.status ? formData.status : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Medio de contacto</span>
        <br />
        <span class="text-data-details">
          {{ formData.tipo_llamada ? formData.tipo_llamada : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Producto</span>
        <br />
        <span class="text-data-details">
          {{ formData.producto ? formData.producto : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Llamada colgada/incompleta</span>
        <br />
        <span class="text-data-details">
          {{ formData.tipificacion ? formData.tipificacion : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Empresa</span>
        <br />
        <span class="text-data-details">
          {{ formData.empresa ? formData.empresa : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Aseguradora</span>
        <br />
        <span class="text-data-details">
          {{ formData.aseguradora ? formData.aseguradora : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Tipificación</span>
        <br />
        <span class="text-data-details">
          {{ formData.tipificacion ? formData.tipificacion : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Motivo</span>
        <br />
        <span class="text-data-details">
          {{ formData.disposicion ? formData.disposicion : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data">Póliza</span>
        <br />
        <span class="text-data-details">
          {{ formData.poliza ? formData.poliza : "Sin llenar" }}
        </span>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data whitout-padding-bottom ">
          ¿Atendio en llamada?
        </span>
        <v-radio-group
          v-model="formData.atendioLlamada"
          row
          disabled="true"
        >
          <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
          <v-radio label="No" value="false" color="#00a7e4"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="4" xl="4">
        <span class="text-header-data whitout-padding-bottom ">
          ¿Es queja?
        </span>
        <v-radio-group v-model="formData.isQueja" row  disabled="true">
          <v-radio label="Si" value="true" color="#00a7e4"></v-radio>
          <v-radio label="No" value="false" color="#00a7e4"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <span class="text-header-data">Detalle</span>
        <br />
        <span class="text-data-details">
          {{ formData.detalle ? formData.detalle : "Sin llenar" }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    datosSolicitud: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        status: "",
        tipo_llamada: "Inbound",
        producto: "",
        empresa: "",
        tipificacion: "",
        aseguradora: "",
        poliza: "",
        disposicion: "",
        atendioLlamada: "",
        detalle: "",
        servicio: "",
        isQueja: "",
        agente_id: "",
        solicitud_id: "",
      },
    };
  },
  methods: {
    getFormatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "Sin llenar";
    },
    fillData() {
      const jsonData = this.datosSolicitud.solicitudBody || "{}";
      Object.keys(this.formData).forEach((key) => {
        if (jsonData[key] !== undefined) {
          this.formData[key] = jsonData[key];
        }
      });
      this.formData.status = this.datosSolicitud.status ?? null
    },
  },
  mounted() {
    this.fillData();
  },
};
</script>
