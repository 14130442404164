import { mainAxios } from "@/mainAxios.js";
import { getAgenteAccessToken } from "../tokens.service";

export const getBeneficiariosByEmisionId = async (emissionID) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/beneficiarios/emision/"+emissionID,
      config
    );
    
    return serverResponse.data
};

export const getBeneficiarioById = async (beneficiarioId) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/beneficiarios/beneficiario/"+beneficiarioId,
      config
    );
    
    return serverResponse.data
};

export const postCrearBeneficiario = async (request) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.post(
      "/v1/beneficiarios/insert",
      request,
      config
    );
    
    return serverResponse.data
};

export const putEditarBeneficiario = async (beneficiarioId,request) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.put(
      "/v1/beneficiarios/beneficiario/"+beneficiarioId,
      request,
      config
    );
    
    return serverResponse.data
};

export const getParentescoList = async () => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/catalogo/parentesco",
      config
    );
    
    return serverResponse.data
};


export const getBeneficiariosBySolicitudId = async (solicitud_id) => {
  const config = {
    headers: {
      Authorization: "Bearer " + getAgenteAccessToken(),
    },
  };

    const serverResponse = await mainAxios.get(
      "/v1/beneficiarios/solicitud/"+solicitud_id,
      config
    );
    
    return serverResponse.data
};
