<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-0"
      :loading="loading"
      loading-text="Cargando..."
      :footer-props="{
        'items-per-page-all-text': 'Todos',
        'items-per-page-text': 'Elementos por página',
      }"
      no-data-text="No hay documentos para mostrar"
      style="background-color: rgba(247, 248, 250, 1); padding: 24px"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <v-data-table-col
          :value="header.value"
          :label="header.text"
          :key="header.value"
        >
          {{ item[header.value] }}
        </v-data-table-col>
      </template>
      <template v-slot:item.uuid="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <v-btn  icon x-small @click="view(item.urlBucket, item.nombre)" :disabled="disabled" >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div
          style="display: flex; justify-content: center; align-items: center; width: 100%"
        >
          <v-btn icon x-small @click="downloadDoc(item.urlBucket)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        <div v-if="itemsLength">
          Mostrando {{ pageStart }} - {{ pageStop }} de
          {{ itemsLength }} solicitudes
        </div>
      </template> </v-data-table
    ><v-dialog v-model="abrirDialog" scrollable transition="scale-transition">
      <v-card>
        <v-card-title class="headline">{{ documentName }}</v-card-title>
        <v-card-text v-if="isPdf" class="justify-center align-center">
          <iframe
              :src="`https://docs.google.com/gview?url=${encodeURIComponent(documentURL)}&embedded=true`"
              width="100%"
              style="height: 70vh;"
            ></iframe>
        </v-card-text>

        <v-card-text v-if="!isPdf">
            <v-row>
              <v-col class="d-flex align-center justify-center">
                <img
                  :src="documentURL"
                  max-width="70%"
                  :alt="documentName"
                >
              </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cerrarDialog()"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name:'TablaDocumentosAlianza',
  methods: {
    view(url, docname) {
      this.disabled = true;
      this.abrirDialog = true;
      this.documentURL = url;
      this.isPdf = url.includes('.pdf?');
      this.documentName = docname;
      
    },
    cerrarDialog(){
      this.disabled = false;
      this.abrirDialog = false;
      this.isPdf = false;
      this.imageURL = "";
      this.viewerUrl = "";
    },
    downloadDoc(url) {
      window.open(url, "_blank");
    },
  },
  data() {
    return {
      abrirDialog: false,
      imageURL: "",
      isPdf: false,
      viewerUrl: "",
      documentName: "",
      documentURL: "",
      headers: [
        {
          text: "Lista",
          value: "nombre",
        },
        {
          text: "Descargar",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Visualizar",
          align: "center",
          value: "uuid",
          sortable: false,
        },
        // {
        //   text: "Estatus",
        //   align: "center",
        //   value: "estatus",
        // },
      ],
      size: 10,
      page: 1,
      items: this.alianzaData.documentos[0].documentos,
      disabled: false,
    };
  },

  props: {
    alianzaData: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    download: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #f2fafc;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}
</style>
